import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Dropdown,
  Href,
  Img,
} from '@cg/module-frontend/src/components';
import { useAuth } from '@cg/module-frontend';
import UserAvatar from '@cg/module-frontend/src/components/avatar/UserAvatar';
import {
  DashboardIcon,
  LogOutIcon,
  ProfileIcon,
  TicketIcon,
  WhatsAppIcon,
} from '@cg/module-frontend/src/icons';
import { sizeToPx } from '@cg/module-frontend/src/constant';
import { boxConfig } from '@cg/module-frontend/src/config';
import { useNavigate } from 'react-router';

function Header() {
  const navigate = useNavigate();
  const { login, isAuthenticated, self, logout, isHost } = useAuth();

  return (
    <div className="sticky top-0 bg-white z-20">
      <header className="w-mobile lg:w-desktop mx-auto flex h-20 items-center justify-between">
        <Link to="/" className="flex items-center">
          <Img
            src="https://assets.joincommonground.com/branding/logoword-purple.svg"
            alt="logo"
            className="h-8"
          />
        </Link>
        <div className="flex items-center gap-10">
          <div className="lg:hidden items-center">
            {!isHost && (
              <Button
                color="none"
                onClick={() => navigate('/become-a-host')}
                className="text-black text-base font-semibold"
              >
                Become a Host
              </Button>
            )}
            {isHost && (
              <Button
                color="none"
                onClick={() =>
                  window.location.assign(boxConfig.baseUrls.playGround)
                }
                className="text-black text-base font-semibold"
              >
                Go to Playground
              </Button>
            )}
          </div>
          <div className="hidden lg:flex items-center gap-2">
            {!isHost && (
              <Button
                color="none"
                onClick={() => navigate('/become-a-host')}
                className="text-black text-base font-semibold"
              >
                Become a Host
              </Button>
            )}
            {!isAuthenticated && (
              <Href
                to="https://bit.ly/45hLWrN"
                target="_blank"
                className="text-black"
              >
                <WhatsAppIcon className="text-black size-8" />
              </Href>
            )}
            {!isAuthenticated && (
              <Button onClick={() => login()} color="primary" className="ml-4">
                Sign Up / Login
              </Button>
            )}
            {isAuthenticated && (
              <Dropdown
                inline
                arrowIcon={false}
                label={
                  <UserAvatar user={self?.user} iconSize="md" imageSize="md" />
                }
                placement="bottom-end"
                className="z-30 ml-4"
              >
                <Dropdown.Header className="z-30">
                  <span className="block text-sm">
                    {self?.user?.firstName} {self?.user?.lastName}
                  </span>
                  <span className="block truncate text-sm font-medium">
                    {self?.user?.email}
                  </span>
                </Dropdown.Header>
                <Dropdown.Item as={Link} to="/profile/experiences">
                  <TicketIcon
                    className="pr-2 size-7"
                    aria-hidden="true"
                    size={sizeToPx.sm}
                  />
                  My Experiences
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/profile/details">
                  <ProfileIcon className="pr-2 size-7" aria-hidden="true" />
                  My Profile
                </Dropdown.Item>
                {isHost && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={() => {
                        window.location.assign(boxConfig.baseUrls.playGround);
                      }}
                    >
                      <DashboardIcon
                        className="pr-2 size-7"
                        aria-hidden="true"
                      />
                      Playground
                    </Dropdown.Item>
                  </>
                )}
                <Dropdown.Divider />
                <Dropdown.Item
                  onClick={logout}
                  className="focus:rounded-b text-failure"
                >
                  <LogOutIcon className="pr-2 size-7" aria-hidden="true" />
                  Logout
                </Dropdown.Item>
              </Dropdown>
            )}
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
