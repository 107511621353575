import { Badge, Button, UserPill } from '@cg/module-frontend/src/components';
import { useState } from 'react';
import { ArrowRightIcon } from '@cg/module-frontend/src/icons';
import { useExperience } from '~/app/context/experience';
import { TicketResponse } from '~/generated/models/TicketResponse';
import { GrabTicket } from './GrabTicket';
import { TicketStatus } from '~/generated/models/TicketStatus';

export function PurchasedTickets({
  purchasedTickets,
}: {
  purchasedTickets: TicketResponse[];
}) {
  const [open, setOpen] = useState(false);
  const { tiers } = useExperience();

  const TicketInPosession = [
    TicketStatus.Confirmed,
    TicketStatus.Reselling,
    TicketStatus.NoShow,
  ];
  const ticketCount = purchasedTickets.filter((t) =>
    TicketInPosession.includes(t.status),
  ).length;

  const endings = ticketCount === 1 ? '' : 's';

  return (
    <div className="relative flex flex-col justify-evenly bg-primary h-full w-full">
      <div className="flex flex-row justify-evenly h-full w-full py-6">
        <div className="items-center flex flex-row gap-x-2 text-base text-white font-semibold text-left">
          You have {ticketCount} Ticket{endings}!
        </div>
        <Button
          color="secondary"
          className="hover:border-white flex items-center"
          onClick={() => setOpen(!open)}
        >
          <div className="flex items-center gap-x-2">
            <span className="text-base">
              {open ? 'Collapse Tab' : 'View Tickets'}
            </span>
            <ArrowRightIcon
              className={`${open ? 'rotate-90' : ''} text-primary stroke-primary `}
            />
          </div>
        </Button>
      </div>
      {open && (
        <div className="relative flex flex-col w-full h-full bg-white">
          <div className="mx-4 pb-10 space-y-3 my-4 max-h-80 overflow-y-scroll">
            {purchasedTickets?.map((ticket) => (
              <UserPill
                user={ticket.user}
                collapsable={false}
                key={ticket.id.getValue()}
              >
                <Badge className="bg-primary text-white">
                  {
                    tiers?.find(
                      (t) => ticket.ticketTierId.getValue() === t.id.getValue(),
                    )?.name
                  }
                </Badge>
              </UserPill>
            ))}
          </div>
          <GrabTicket />
        </div>
      )}
    </div>
  );
}
