import { FreeMode, Mousewheel, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperRef } from 'swiper/react';
import React, { forwardRef } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { PaginationOptions } from 'swiper/types';
import { useScreenSize } from '../../hooks';

type Props = {
  className?: string;
  onReachEnd?: () => void;
  usePagination?: boolean;
  useFreeMode?: boolean;
  useNavigation?: boolean;
  children: React.ReactNode;
};

const Swipper = forwardRef<SwiperRef, Props>(
  (
    {
      className,
      onReachEnd,
      children,
      usePagination,
      useFreeMode,
      useNavigation,
    }: Props,
    ref,
  ) => {
    const [isReady, setIsReady] = React.useState(false);
    const hasChildren = React.Children.count(children) > 0;

    React.useEffect(() => {
      if (hasChildren && !isReady) {
        setIsReady(true);
      }
    }, [hasChildren, isReady]);

    const { isMobile } = useScreenSize();
    const pagination: PaginationOptions | boolean = React.useMemo(
      () =>
        usePagination
          ? {
              el: '.pagination-container',
              clickable: true,
              type: 'bullets',
              modifierClass: '',
              renderBullet: (_, cName: string) => {
                return `<span class="${cName} inline-block mx-1 w-2 h-2 rounded bg-primary"></span>`;
              },
            }
          : false,
      [usePagination],
    );
    const modules = React.useMemo(() => {
      const baseModules = [Mousewheel, Navigation, FreeMode];
      if (usePagination) {
        baseModules.push(Pagination);
      }
      return baseModules;
    }, [usePagination]);

    const freeMode = React.useMemo(
      () => (useFreeMode ? { enabled: true, sticky: false } : false),
      [useFreeMode],
    );

    const navigation = React.useMemo(
      () =>
        useNavigation
          ? {
              enabled: true,
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }
          : false,
      [useNavigation],
    );

    if (!hasChildren || !isReady) {
      return null;
    }

    return (
      <div className="relative">
        {usePagination && (
          <div className="pagination-container absolute bottom-2 left-1/2 transform -translate-x-1/2 h-fit w-fit rounded bg-grey text-center px-2 py-0.5 z-10" />
        )}
        <Swiper
          ref={ref}
          enabled
          initialSlide={0}
          observer
          observeParents
          style={{
            // @ts-ignore
            '--swiper-navigation-size': '25px',
            // @ts-ignore
            '--swiper-navigation-sides-offset': '0px',
          }}
          modules={modules}
          className={className}
          slidesPerView="auto"
          onReachEnd={onReachEnd}
          navigation={navigation}
          spaceBetween={20}
          mousewheel={{
            sensitivity: 4,
          }}
          speed={500}
          effect="slide"
          freeMode={freeMode}
          direction="horizontal"
          simulateTouch={isMobile}
          keyboard={{ enabled: true }}
          scrollbar={{ draggable: true, enabled: true }}
          pagination={pagination}
          breakpoints={{
            640: {
              spaceBetween: 20,
            },
            768: {
              spaceBetween: 40,
            },
            1024: {
              spaceBetween: 24,
            },
          }}
        >
          {children}
          {useNavigation && (
            <>
              <div className="swiper-button-next !text-primary !after:text-sm" />
              <div className="swiper-button-prev !text-primary" />
            </>
          )}
        </Swiper>
      </div>
    );
  },
);

export default Swipper;
