import React, { isValidElement, ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import Tab from './Tab';

type TabsProps = {
  children: ReactNode;
  className?: string;
  bgColor: 'grey';
  forcedIndex?: number;
  onTabChanged?: (index: number) => void;
};
export default function Tabs({
  children,
  className,
  bgColor,
  forcedIndex,
  onTabChanged,
}: TabsProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  const validTabs = React.Children.toArray(children).filter(
    (child) => isValidElement(child) && child.type === Tab,
  );

  const titles = validTabs.map((child) => {
    if (isValidElement(child)) {
      return child.props.title;
    }
    return null;
  });

  useEffect(() => {
    if (forcedIndex !== undefined) {
      setActiveIndex(forcedIndex);
    }
  }, [forcedIndex]);

  return (
    <div className={classNames('rounded', className)}>
      <div className="flex flex-wrap">
        {titles.map((title, index) => (
          <span
            key={title}
            className={classNames(`bg-${bgColor} flex-grow md:flex-1`, {
              'rounded-tl rounded-tr': index === activeIndex && index === 0,
              'rounded-tr rounded-tl':
                index === activeIndex && index === titles.length - 1,
            })}
          >
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */}
            <h3
              className={classNames(
                `bg-${bgColor} cursor-pointer p-4 text-center`,
                {
                  // in active tab gets white bg
                  'bg-white': index !== activeIndex,
                  // last inactive tab gets rounded bottom right
                  'rounded-bl':
                    index !== activeIndex && index === titles.length - 1,
                  'rounded-br':
                    index !== activeIndex && index !== titles.length - 1,
                  // first active tab gets rounded top left
                  'rounded-tl rounded-tr': index === activeIndex && index === 0,
                  // last active tab gets rounded top right
                  'rounded-tr rounded-tl':
                    index === activeIndex && index === titles.length - 1,
                },
              )}
              onClick={() => {
                setActiveIndex(index);
                if (onTabChanged) {
                  onTabChanged(index);
                }
              }}
            >
              {title}
            </h3>
          </span>
        ))}
      </div>
      <div
        className={classNames('rounded-b', `bg-${bgColor}`, {
          'rounded-tl': activeIndex !== 0,
          'rounded-tr': activeIndex === 0,
        })}
      >
        {isValidElement(validTabs[activeIndex]) && validTabs[activeIndex]}
      </div>
    </div>
  );
}
