import { createContext, useContext } from 'react';
import { Host } from '~/generated/models/Host';
import { HostUserProfile } from '~/generated/models/HostUserProfile';
import { DetailedExperienceSeries } from '~/generated/models/DetailedExperienceSeries';
import { DetailedExperience } from '~/generated/models/DetailedExperience.ts';

export type HostContextType = {
  host: Host | null;
  hostUsers: HostUserProfile[];
  fetchingHost: boolean;
  fetchingSeries: boolean;
  seriesExperiences: DetailedExperience[];
  series: DetailedExperienceSeries[];
  fetchingExperiences: boolean;
  experiences: DetailedExperience[];
};

export const HostContext = createContext<HostContextType>({
  host: null,
  hostUsers: [],
  fetchingHost: false,
  fetchingSeries: false,
  seriesExperiences: [],
  series: [],
  fetchingExperiences: false,
  experiences: [],
});

export function useHost() {
  return useContext(HostContext);
}
