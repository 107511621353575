/* eslint-disable */
import { StrictBuilder } from 'builder-pattern';




/**
 * <p>The status of the user attending the event</p>
 */
export enum AttendingStatus {
	Attending = 'Attending',
	Waitlist = 'Waitlist'
}

