import { Loader } from '@cg/module-frontend/src/components';
import { useHost } from '~/app/context/host';
import HostPage from './HostPage';

export default function Host() {
  const { host, fetchingHost } = useHost();

  return (
    <Loader loading={fetchingHost}>{host && <HostPage host={host} />}</Loader>
  );
}
