import { FlowbiteButtonTheme } from 'flowbite-react';
import { tw } from '../../utils';

const button: FlowbiteButtonTheme = {
  size: {
    xxs: 'px-2',
    xs: 'space-x-2 px-4',
    sm: 'space-x-2 px-4',
    md: 'space-x-2 px-4',
    lg: 'space-x-2 px-4',
    xl: 'space-x-2 px-4',
  },
  disabled: 'cursor-not-allowed opacity-50 text-white',
  // @ts-ignore
  color: {
    primary: tw(
      'items-center bg-primary enabled:hover:bg-primary-darker !placeholder-black !enabled:placeholder-black',
      'enabled:h-10 h-10',
      'enabled:text-white',
      '!rounded !enabled:rounded',
    ),
    failure: tw(
      'items-center bg-failure enabled:hover:bg-failure-darker !placeholder-black !enabled:placeholder-black',
      'enabled:h-8 h-8',
      'enabled:text-white',
      '!rounded !enabled:rounded',
    ),
    secondary: tw(
      'items-center bg-white enabled:bg-white enabled:hover:bg-secondary hover:bg-secondary',
      'enabled:h-10 h-10 py-1',
      'enabled:text-primary text-primary enabled:hover:text-primary-darker',
      'border border-primary',
      '!rounded !enabled:rounded',
    ),
    tertiary: tw(
      'items-center bg-grey',
      'enabled:h-10 h-10 py-1',
      'enabled:text-primary text-primary hover:text-primary-darker',
      'border border-grey',
      '!rounded !enabled:rounded',
    ),
    grey: tw(
      'items-center bg-grey enabled:hover:bg-grey-darker',
      'enabled:h-10 h-10 py-1',
      'enabled:text-black text-black enabled:hover:text-black-darker',
      'border border-grey',
      '!rounded !enabled:rounded',
    ),
    action: tw('items-center !rounded !enabled:rounded bg-grey'),
  },
};

export default button;
