import React from 'react';
import classNames from 'classnames';
import { SectionProps } from './Section';
import { boxConfig } from '../../config';
import GetStarted from './GetStarted';

export type ContentProps = {
  hideGetStarted?: boolean;
  className?: string;
  children?:
    | React.ReactElement<SectionProps>
    | Array<React.ReactElement<SectionProps>>
    | React.ReactNode;
};

function Content({ children, className, hideGetStarted }: ContentProps) {
  const showGetStarted =
    window.location.host === boxConfig.hosts.commonGround && !hideGetStarted;

  return (
    <div className={classNames('w-mobile lg:w-desktop mx-auto', className)}>
      {children}
      {showGetStarted && <GetStarted />}
    </div>
  );
}

export default Content;
