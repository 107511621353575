/* eslint-disable */


import { http, HttpResult } from '@cg/module-frontend/src/core/http';

import { Self } from '../../../models/Self';
import { UserWithSharedExperience } from '../../../models/UserWithSharedExperience';
import { BasicUser } from '../../../models/BasicUser';
import { MarkLifecycleEventRequest } from '../../../models/MarkLifecycleEventRequest';
import { UpdateSelfRequest } from '../../../models/UpdateSelfRequest';
import { CreatePhotoPreSignedUrlRequest } from '../../../models/CreatePhotoPreSignedUrlRequest';
import { PreSignedUrlResponse } from '../../../models/PreSignedUrlResponse';

export type FetchSelfArgs = {
    verifiedUser?: boolean,
    filters?: {
        verify?: boolean,
    },
    cacheCallback?: (args: FetchSelfArgs) => {
        key?: string,
        
        ttl?: number,
    },
}

/**
 * <p>API endpoints for managing the logged in user</p>
 * @summary Returns the logged in user
 * @param {FetchSelfArgs} args
 */
export const fetchSelf = (args: FetchSelfArgs): Promise<HttpResult<Self>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/private/self', params);
    const cache = {
      key: 'fetchSelf',
      
      ttl: 10000,
      ...args?.cacheCallback?.(args),
    };

    return http.get(url, {
      cache,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListUserConnectionsArgs = {
    verifiedUser?: boolean,
}

/**
 * @summary Fetches all connections that user has shared an experience with
 */
export const listUserConnections = (args: ListUserConnectionsArgs): Promise<HttpResult<UserWithSharedExperience[]>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/private/self/connections', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type MarkLifecycleEventArgs = {
    verifiedUser?: boolean,
    body: MarkLifecycleEventRequest,
}

/**
 * @summary Marks a lifecycle Experience as done
 * @param {MarkLifecycleEventArgs} args
 */
export const markLifecycleEvent = (args: MarkLifecycleEventArgs): Promise<HttpResult<void>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/private/self/lifecycle', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type UpdateSelfArgs = {
    verifiedUser?: boolean,
    cacheCallback?: (args: UpdateSelfArgs) => {
        
        expireKey?: string,
        
    },
    body: UpdateSelfRequest,
}

/**
 * <p>API endpoints for managing the logged in user</p>
 * @summary Updates the logged in user
 * @param {UpdateSelfArgs} args
 */
export const updateSelf = (args: UpdateSelfArgs): Promise<HttpResult<Self>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/private/self', params);
    const cache = {
      
      expireKey: 'fetchSelf',
      
      ...args?.cacheCallback?.(args),
    };

    return http.post(url, {
        body: args.body,
      cache,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type UploadPhotoArgs = {
    verifiedUser?: boolean,
    body: CreatePhotoPreSignedUrlRequest,
}

/**
 * <p>API endpoints for creating a Pre Signed URL for uploading files after to S3</p>
 * @summary Generates a Pre Signed URL for uploading a photo
 * @param {UploadPhotoArgs} args
 */
export const uploadPhoto = (args: UploadPhotoArgs): Promise<HttpResult<PreSignedUrlResponse>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/private/self/photo', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
