import { Loader } from '@cg/module-frontend/src/components';
import { useHost } from '~/app/context/host';
import HostExperiencesPage from './HostExperiencesPage';

export default function HostExperiences() {
  const { host, fetchingHost } = useHost();

  return (
    <Loader loading={fetchingHost}>
      {host && <HostExperiencesPage host={host} />}
    </Loader>
  );
}
