/* eslint-disable */

import { http, HttpResult } from '~/core/http';


import { AddUserToHostRequest } from '../../../models/AddUserToHostRequest';
import { HostId } from '@cg/common/src/ids/HostId';
import { ChangeOwnerRequest } from '../../../models/ChangeOwnerRequest';
import { CreateHostFromBGRequest } from '../../../models/CreateHostFromBGRequest';
import { Host } from '../../../models/Host';
import { PaymentDetail } from '../../../models/PaymentDetail';
import { Page } from '@cg/common/src/paging/Page';
import { ExperienceWithTickets } from '../../../models/ExperienceWithTickets';
import { Experience } from '../../../models/Experience';
import { ExperienceStatus } from '../../../models/ExperienceStatus';
import { HostUser } from '../../../models/HostUser';
import { User } from '../../../models/User';
import { UserId } from '@cg/common/src/ids/UserId';
import { UpdateHostRequest } from '../../../models/UpdateHostRequest';
import { UpdateHostUserRequest } from '../../../models/UpdateHostUserRequest';
import { CreatePhotoPreSignedUrlRequest } from '../../../models/CreatePhotoPreSignedUrlRequest';
import { PreSignedUrlResponse } from '../../../models/PreSignedUrlResponse';

export type AddHostUserArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
    body: AddUserToHostRequest,
}

/**
 * <p>Internal API for Hosts Users CRUD</p>
 * @summary Adds a user to the Host
 * @param {AddHostUserArgs} args
 */
export const addHostUser = (args: AddHostUserArgs): Promise<HttpResult<void>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/hosts/{hostId}/users', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ChangeHostOwnerArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
    body: ChangeOwnerRequest,
}

/**
 * @summary Changes the owner. This action is not reversible and you will lose ownership.
 * @param {ChangeHostOwnerArgs} args
 */
export const changeHostOwner = (args: ChangeHostOwnerArgs): Promise<HttpResult<void>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/hosts/{hostId}/owner', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type CreateHostArgs = {
    verifiedUser?: boolean,
    body: CreateHostFromBGRequest,
}

/**
 * <p>Internal API for Hosts CRUD</p>
 * @summary Creates a new Host
 * @param {CreateHostArgs} args
 */
export const createHost = (args: CreateHostArgs): Promise<HttpResult<Host>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/hosts', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type FetchHostArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
}

/**
 * <p>Returns a Host.</p>
 * @summary Endpoint for return a Host
 * @param {FetchHostArgs} args
 */
export const fetchHost = (args: FetchHostArgs): Promise<HttpResult<Host>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/hosts/{hostId}', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type GetPaymentDetailArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
}

/**
 * <p>Returns a list of users.</p>
 * @summary Endpoint for Host users
 * @param {GetPaymentDetailArgs} args
 */
export const getPaymentDetail = (args: GetPaymentDetailArgs): Promise<HttpResult<PaymentDetail>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/hosts/{hostId}/payments', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListHostExperiencesArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
    filters?: {
        name?: string,
        status?: ExperienceStatus,
        previousOnly?: boolean,
        upcomingOnly?: boolean,
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * @summary Endpoint for returning list of Experiences
 * @param {ListHostExperiencesArgs} args
 */
export const listHostExperiences = (args: ListHostExperiencesArgs): Promise<HttpResult<Page<ExperienceWithTickets>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/hosts/{hostId}/experiences', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListHostsArgs = {
    verifiedUser?: boolean,
    filters?: {
        uniqueName?: string,
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * <p>Returns the Paginated list of all Host.</p>
 * @summary Endpoint for returning list of Host
 * @param {ListHostsArgs} args
 */
export const listHosts = (args: ListHostsArgs): Promise<HttpResult<Page<Host>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/hosts', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListHostUsersArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
    filters?: {
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * <p>Returns a list of users.</p>
 * @summary Endpoint for Host users
 * @param {ListHostUsersArgs} args
 */
export const listHostUsers = (args: ListHostUsersArgs): Promise<HttpResult<Page<HostUser>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/hosts/{hostId}/users', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListWaitlistedUsersArgs = {
    verifiedUser?: boolean,
    filters?: {
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * <p>Temp.</p>
 * @summary Temp
 */
export const listWaitlistedUsers = (args: ListWaitlistedUsersArgs): Promise<HttpResult<Page<User>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/hosts-waitlisted', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type RemoveHostUserArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
        userId: UserId,
    },
}

/**
 * <p>Internal API for Hosts Users CRUD</p>
 * @summary Removes the user from the Host
 * @param {RemoveHostUserArgs} args
 */
export const removeHostUser = (args: RemoveHostUserArgs): Promise<HttpResult<void>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/hosts/{hostId}/users/{userId}', params);

    return http.delete(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type UpdateHostSettingArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
    body: UpdateHostRequest,
}

/**
 * <p>Internal API for Hosts CRUD</p>
 * @summary Updates the Host settings.
 * @param {UpdateHostSettingArgs} args
 */
export const updateHostSetting = (args: UpdateHostSettingArgs): Promise<HttpResult<Host>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/hosts/{hostId}', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type UpdateHostUserArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
        userId: UserId,
    },
    body: UpdateHostUserRequest,
}

/**
 * <p>Internal API for Hosts Users CRUD</p>
 * @summary Updates the host user
 * @param {UpdateHostUserArgs} args
 */
export const updateHostUser = (args: UpdateHostUserArgs): Promise<HttpResult<HostUser>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/hosts/{hostId}/users/{userId}', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type UploadPhotoArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
    body: CreatePhotoPreSignedUrlRequest,
}

/**
 * <p>API endpoints for creating a Pre Signed URL for uploading files after to S3</p>
 * @summary Generates a Pre Signed URL for uploading a photo for the Host
 * @param {UploadPhotoArgs} args
 */
export const uploadPhoto = (args: UploadPhotoArgs): Promise<HttpResult<PreSignedUrlResponse>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/hosts/{hostId}/photo', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
