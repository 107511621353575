const isLocal = window.location.host.startsWith('localhost');
const host = isLocal ? 'http://localhost' : `https://${window.location.host}`;
const apiHost = isLocal
  ? 'http://localhost:3000'
  : `https://api.${window.location.host.replace('www.', '').replace('playground.', '').replace('background.', '')}`;

const domain = window.location.hostname;
const subDomain = domain.includes('stage') ? 'stage.' : '';

export const boxConfig = {
  environment: domain.includes('stage') ? 'stage' : 'prod',
  host: import.meta.env.VITE_HOST || host,
  apiHost:
    import.meta.env.VITE_USE_REALM === 'stage'
      ? 'https://api.stage.joincommonground.com'
      : apiHost,
  isLocal,
  domain,
  hosts: {
    commonGround: isLocal
      ? 'localhost:8080'
      : `${subDomain}joincommonground.com`,
    playGround: isLocal
      ? 'localhost:8090'
      : `playground.${subDomain}joincommonground.com`,
    backGround: isLocal
      ? 'localhost:8000'
      : `background.${subDomain}joincommonground.com`,
  },
  baseUrls: {
    commonGround: isLocal
      ? 'http://localhost:8080'
      : `https://${subDomain}joincommonground.com`,
    playGround: isLocal
      ? 'http://localhost:8090'
      : `https://playground.${subDomain}joincommonground.com`,
    backGround: isLocal
      ? 'http://localhost:8000'
      : `https://background.${subDomain}joincommonground.com`,
  },
};
export type BoxConfig = typeof boxConfig;

const config = {
  box: boxConfig,
};
export type Config = typeof config;
export default config;
