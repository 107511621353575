import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import MarkdownContainer from './MarkdownContainer';

export type Props = {
  children: string;
  className?: string;
};

function Markdown({ children, className }: Props) {
  return (
    <MarkdownContainer>
      <ReactMarkdown className={className} remarkPlugins={[remarkBreaks]}>
        {children}
      </ReactMarkdown>
    </MarkdownContainer>
  );
}

export default Markdown;
