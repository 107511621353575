/* eslint-disable */
import { StrictBuilder } from 'builder-pattern';




/**
 * <p>The status of a payment</p>
 */
export enum TicketStatus {
	Refunded = 'Refunded',
	Pending = 'Pending',
	Confirmed = 'Confirmed',
	Expired = 'Expired',
	Reselling = 'Reselling',
	Sold = 'Sold',
	NoShow = 'NoShow'
}

