import { FlowbiteDropdownTheme } from 'flowbite-react';

const dropdown: Partial<FlowbiteDropdownTheme> = {
  arrowIcon: 'ml-2 h-4 w-4',
  content: 'py-1 focus:outline-none',
  floating: {
    animation: 'transition-opacity',
    arrow: {
      base: 'absolute z-30 h-2 w-2 rotate-45',
      style: {
        light: 'bg-white',
        auto: 'bg-white',
        dark: '',
      },
      placement: '-4px',
    },
    base: 'z-30 w-fit rounded divide-y divide-gray-100 shadow focus:outline-none disabled:cursor-not-allowed disabled:opacity-50',
    content: 'py-1 text-sm text-gray-700',
    divider: 'my-1 h-px bg-gray-100',
    header: 'block py-2 px-4 text-sm text-gray-700',
    hidden: 'invisible opacity-0',
    item: {
      container: '',
      base: 'flex items-center justify-start py-2 px-4 text-sm text-black cursor-pointer w-full hover:bg-grey focus:bg-grey focus:outline-none',
      icon: 'mr-2 h-4 w-4',
    },
    style: {
      light: 'border border-grey bg-white text-grey',
      auto: 'border border-grey bg-white text-grey',
      dark: '',
    },
    target: 'w-fit',
  },
  inlineWrapper: 'flex items-center',
};

export default dropdown;
