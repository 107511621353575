import { Swipper } from '@cg/module-frontend/src/components';
import React from 'react';

type Props = {
  title: string;
  hideCity?: boolean;
  hasMore: boolean;
  fetchMore: () => void;
  children: React.ReactNode;
};

export default function ListSwiper({
  title,
  hideCity,
  fetchMore,
  hasMore,
  children,
}: Props) {
  return (
    <div className="w-full h-fit bg-grey rounded mt-10 py-4 px-4">
      <span className="flex justify-between items-center mb-6">
        <div>
          <h3 className="font-normal text-black-lighter">{title}</h3>
          {!hideCity && (
            <h4 className="text-primary font-normal">
              in <strong>Vancouver</strong>
            </h4>
          )}
        </div>
      </span>

      <Swipper
        useFreeMode
        useNavigation
        onReachEnd={() => {
          if (hasMore) {
            fetchMore();
          }
        }}
      >
        {children}
      </Swipper>
    </div>
  );
}
