import { createContext, useContext } from 'react';
import { TicketId } from '@cg/common/src/ids';
import { ExperienceTicketTiersResponse } from '~/generated/models/ExperienceTicketTiersResponse';
import { CheckoutRequest } from '~/generated/models/CheckoutRequest';
import { Experience } from '~/generated/models/Experience';
import { UserCheckoutRequest } from '~/generated/models/UserCheckoutRequest';

export enum Step {
  Step1 = 1,
  Step2 = 2,
  Step3 = 3,
}

export type InternalTicket = {
  fakeId: TicketId;
} & CheckoutRequest;

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AuthProvider>.');
};

export type CheckoutContextType = {
  tiers: ExperienceTicketTiersResponse[];
  tickets: InternalTicket[];
  addTicket: (tier: ExperienceTicketTiersResponse) => void;
  removeTicket: (tier: ExperienceTicketTiersResponse) => void;
  addUser: (id: TicketId, user: UserCheckoutRequest) => void;
  experience: Experience | null;
  stepValidation: (step: Step, validated: boolean) => void;
};

export const CheckoutContext = createContext<CheckoutContextType>({
  tiers: [],
  tickets: [],
  addUser: stub,
  addTicket: stub,
  removeTicket: stub,
  stepValidation: stub,
  experience: null,
});

export function useCheckout() {
  return useContext(CheckoutContext);
}
