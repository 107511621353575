import { Provider, ErrorBoundary } from '@rollbar/react';
import { Configuration } from 'rollbar';
import React from 'react';
import { boxConfig } from '../../config';

type RollbarProviderProps = {
  children: React.ReactNode;
  token: string;
  config?: Configuration;
};

const defaultConfig: Configuration = {
  environment: boxConfig.environment,
  captureUncaught: true,
  addErrorContext: true,
  transform: (data) => {
    const person = data.person || {};
    data.person = {
      ...person,
      // @ts-ignore
      // eslint-disable-next-line no-underscore-dangle
      id: window.__self.userId,
      // @ts-ignore
      // eslint-disable-next-line no-underscore-dangle
      hostId: window.__self.hostId,
    };
  },
  captureDeviceInfo: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
      },
    },
  },
};

export default function RollbarProvider({
  children,
  token,
  config,
}: RollbarProviderProps) {
  const rollbarConfig = {
    ...defaultConfig,
    ...config,
    accessToken: token,
  };

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Provider>
  );
}
