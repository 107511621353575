import React, { useState } from 'react';
import { TicketId } from '@cg/common/src/ids/TicketId';
import { useAuth } from '@cg/module-frontend';
import { UserIcon } from '@cg/module-frontend/src/icons';
import { InternalTicket, Step, useCheckout } from '../context/CheckoutContext';
import { TicketTier } from '~/generated/models/TicketTier';
import { TicketForm } from '../components/TicketForm';
import StepWrapper from '../components/StepWrapper';
import { useExperience } from '~/app/context/experience';

type GroupedTicket = {
  tier: TicketTier;
  tickets: InternalTicket[];
};

export function StepTwoAssignUsers() {
  const { self, isLogged } = useAuth();
  const { tickets, tiers, stepValidation } = useCheckout();
  const { purchasedTickets } = useExperience();
  const hasAssignedSelf =
    tickets.find((ticket) => ticket?.user?.email === self?.user?.email) !==
    undefined;
  const hasSelfTicket = purchasedTickets.some(
    (ticket) => ticket.user.email === self?.user?.email,
  );

  const [validated, setValidated] = useState<Record<string, boolean>>({});

  const isValid = (id: TicketId, valid: boolean) => {
    validated[id.getValue()] = valid;
    setValidated(validated);

    const areValid = Object.values(validated).every(Boolean);
    const allSubmitted = Object.values(validated).length === tickets.length;
    const hasSelfTickets =
      purchasedTickets.some(
        (ticket) => ticket.user.email === self?.user?.email,
      ) ||
      tickets.find((ticket) => ticket?.user?.email === self?.user?.email) !==
        undefined ||
      !isLogged;

    stepValidation(Step.Step2, areValid && hasSelfTickets && allSubmitted);
  };

  const grouped = tickets.reduce((acc, ticket) => {
    const index = acc.findIndex((arr) => {
      return arr.tier.id.isEqual(ticket.ticketTierId);
    });
    const tier = tiers.find((t) => t.id.isEqual(ticket.ticketTierId));
    if (!tier) {
      return acc;
    }

    if (index === -1) {
      acc.push({
        tier,
        tickets: [ticket],
      });
    } else {
      acc[index].tickets.push(ticket);
    }
    return acc;
  }, [] as GroupedTicket[]);

  return (
    <StepWrapper
      icon={<UserIcon className="text-primary size-6" />}
      title="Who's Going?"
      step={2}
    >
      {grouped.map((group) => (
        <div
          className="bg-grey rounded p-4 mb-4"
          key={group.tier.id.getValue()}
        >
          <span className="text-xl font-medium px-2">{group.tier.name}</span>
          {group.tickets.map((ticket) => {
            return (
              <TicketForm
                id={ticket.fakeId}
                isValid={(valid) => isValid(ticket.fakeId, valid)}
                name={group.tier.name}
                key={ticket.fakeId.getValue()}
                hasSelfTicket={hasSelfTicket}
                hasAssignedSelf={hasAssignedSelf}
              />
            );
          })}
        </div>
      ))}
    </StepWrapper>
  );
}
