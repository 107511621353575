import React, { useEffect, useMemo, useState } from 'react';
import { TextInput } from '@cg/module-frontend/src/components';
import { TicketId } from '@cg/common/src/ids';
import { useForm } from '@cg/module-frontend/src/hooks';
import { isValidEmail, isValidPhone } from '@cg/common/src/utils';
import { useAuth } from '@cg/module-frontend';
import { TicketIcon } from '@cg/module-frontend/src/icons';
import { InternalTicket, useCheckout } from '../context/CheckoutContext';

type TicketFormProp = {
  id: TicketId;
  name: string;
  hasSelfTicket: boolean;
  hasAssignedSelf: boolean;
  isValid: (valid: boolean) => void;
};

type TicketFields = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

export function TicketForm({
  id,
  name,
  hasSelfTicket,
  hasAssignedSelf,
  isValid,
}: TicketFormProp) {
  const { self, isLogged } = useAuth();
  const { tickets, addUser } = useCheckout();
  const ticket = tickets.find((t) => t.fakeId.isEqual(id)) as InternalTicket;
  const [isSelfTicket, setIsSelfTicket] = useState(
    ticket.user.email === self?.user?.email,
  );

  const getForm = () => ({
    firstName: ticket.user.firstName || '',
    lastName: ticket.user.lastName || '',
    email: ticket.user.email || '',
    phoneNumber: ticket.user.phoneNumber || '',
  });

  const {
    register,
    reset,
    setValue,
    getValues,
    formState: { errors, isValid: valid, isValidating },
    trigger,
  } = useForm<TicketFields>({
    mode: 'onBlur',
    defaultValues: useMemo(() => getForm(), [ticket]),
  });

  useEffect(() => {
    reset(getForm());
  }, [ticket]);
  useEffect(() => {
    isValid(valid);
  }, [valid, isValidating]);

  const onChange = () => {
    addUser(ticket.fakeId, {
      ...getValues(),
    });
  };

  const assignSelf = async () => {
    if (self) {
      setValue('firstName', self.user.firstName || '');
      setValue('lastName', self.user.lastName || '');
      setValue('email', self.user.email || '');
      setValue('phoneNumber', self.user.phoneNumber || '');
      onChange();
      await trigger();
      isValid(!hasSelfTicket);
    }
  };

  const unAssignSelf = async () => {
    if (self) {
      setValue('firstName', '');
      setValue('lastName', '');
      setValue('email', '');
      setValue('phoneNumber', '');

      addUser(ticket.fakeId, {
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
      });
      await trigger();
    }
    setIsSelfTicket(false);
  };

  useEffect(() => {
    if (isSelfTicket) {
      const values = getValues();
      if (
        values.firstName !== self?.user.firstName ||
        values.lastName !== self?.user.lastName ||
        values.email !== self?.user.email ||
        values.phoneNumber !== self?.user.phoneNumber
      ) {
        assignSelf();
      }
    }
  }, [isSelfTicket]);

  return (
    <div className="mt-5 bg-white border border-secondary rounded p-4">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <TicketIcon className="text-primary" />
          <span>{name}</span>
        </div>
        <div>
          {isLogged && !hasSelfTicket && !hasAssignedSelf && (
            <button
              type="button"
              className="underline cursor-pointer text-primary"
              onClick={assignSelf}
            >
              Fill My Details
            </button>
          )}
          {isSelfTicket && (
            <button
              type="button"
              className="underline cursor-pointer text-primary"
              onClick={unAssignSelf}
            >
              Remove Me
            </button>
          )}
        </div>
      </div>
      <div className="pt-4">
        <div className="space-y-4 mt-5">
          <div className="md:flex md:space-x-4 space-y-4 md:space-y-0">
            <div className="grow lg:w-1/2">
              <TextInput
                placeholder="First Name"
                type="text"
                disabled={isSelfTicket}
                {...register('firstName', {
                  onChange,
                  validate: (value: string) => {
                    if (!value || value.length < 2) {
                      return 'Enter a first name.';
                    }

                    return true;
                  },
                })}
              />
              <span className="text-red-500 ml-3 mt-2">
                {errors.firstName?.message}
              </span>
            </div>
            <div className="grow lg:w-1/2">
              <TextInput
                placeholder="Last name"
                type="text"
                disabled={isSelfTicket}
                {...register('lastName', {
                  onChange,
                  validate: (value: string) => {
                    if (!value || value.length < 2) {
                      return "Give us a last name (unless you're secretly Cher or Madonna)";
                    }

                    return true;
                  },
                })}
              />
              <span className="text-red-500 ml-3 mt-2">
                {errors.lastName?.message}
              </span>
            </div>
          </div>
          <div className="grow">
            <TextInput
              placeholder="Your Phone Number"
              disabled={isSelfTicket}
              type="tel"
              {...register('phoneNumber', {
                onChange,
                validate: (value) => {
                  if (!isValidPhone(value)) {
                    return 'New phone, who dis? Use only numbers with a country code';
                  }

                  return true;
                },
              })}
            />
            <span className="text-red-500 ml-3 mt-2">
              {errors.phoneNumber?.message}
            </span>
          </div>

          <div className="grow">
            <TextInput
              placeholder="Email"
              disabled={isSelfTicket}
              type="email"
              {...register('email', {
                onChange,
                onBlur: () => {
                  setIsSelfTicket(getValues().email === self?.user?.email);
                },
                validate: (value) => {
                  if (!isValidEmail(value)) {
                    return 'Hmm... that email looks a little sus.';
                  }

                  const isDuplicate =
                    tickets
                      .map((t) => t.user.email)
                      .filter((e) => e === ticket.user.email).length > 1 &&
                    ticket.user.email.length > 0;
                  if (isDuplicate) {
                    return "You've already assigned a ticket to this email, you silly goose.";
                  }
                  if (
                    hasSelfTicket &&
                    getValues().email === self?.user?.email
                  ) {
                    return "You've already bought a ticket for yourself, you silly goose.";
                  }

                  return true;
                },
              })}
            />
            <span className="text-red-500 ml-3 mt-2">
              {errors.email?.message}
            </span>
          </div>
        </div>
        <div>
          <div />
        </div>
      </div>
    </div>
  );
}
