/* eslint-disable */

import { useHttp } from '~/core/http';


import { createHost, CreateHostArgs, deleteHost, DeleteHostArgs, getDeveloperSetting, GetDeveloperSettingArgs, getHost, GetHostArgs, getPaymentDetail, GetPaymentDetailArgs, listHosts, switchHost, SwitchHostArgs, updateDeveloperSetting, UpdateDeveloperSettingArgs, updateHostSetting, UpdateHostSettingArgs, updatePaymentDetail, UpdatePaymentDetailArgs, uploadPhoto, UploadPhotoArgs } from './PlaygroundHosts.client';

export const createHostHook = (immediateCall: boolean = true, initArgs?: CreateHostArgs) => {
  return useHttp(createHost, { immediateCall, initArgs })
};
export const deleteHostHook = (immediateCall: boolean = true, initArgs?: DeleteHostArgs) => {
  return useHttp(deleteHost, { immediateCall, initArgs })
};
export const getDeveloperSettingHook = (immediateCall: boolean = true, initArgs?: GetDeveloperSettingArgs) => {
  return useHttp(getDeveloperSetting, { immediateCall, initArgs })
};
export const getHostHook = (immediateCall: boolean = true, initArgs?: GetHostArgs) => {
  return useHttp(getHost, { immediateCall, initArgs })
};
export const getPaymentDetailHook = (immediateCall: boolean = true, initArgs?: GetPaymentDetailArgs) => {
  return useHttp(getPaymentDetail, { immediateCall, initArgs })
};
export const listHostsHook = (immediateCall: boolean = true, ) => {
  return useHttp(listHosts, { immediateCall })
};
export const switchHostHook = (immediateCall: boolean = true, initArgs?: SwitchHostArgs) => {
  return useHttp(switchHost, { immediateCall, initArgs })
};
export const updateDeveloperSettingHook = (immediateCall: boolean = true, initArgs?: UpdateDeveloperSettingArgs) => {
  return useHttp(updateDeveloperSetting, { immediateCall, initArgs })
};
export const updateHostSettingHook = (immediateCall: boolean = true, initArgs?: UpdateHostSettingArgs) => {
  return useHttp(updateHostSetting, { immediateCall, initArgs })
};
export const updatePaymentDetailHook = (immediateCall: boolean = true, initArgs?: UpdatePaymentDetailArgs) => {
  return useHttp(updatePaymentDetail, { immediateCall, initArgs })
};
export const uploadPhotoHook = (immediateCall: boolean = true, initArgs?: UploadPhotoArgs) => {
  return useHttp(uploadPhoto, { immediateCall, initArgs })
};
