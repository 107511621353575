/* eslint-disable */


import { http, HttpResult } from '@cg/module-frontend/src/core/http';

import { UserWithSharedExperience } from '../../../models/UserWithSharedExperience';
import { BasicUser } from '../../../models/BasicUser';
import { HostId } from '@cg/common/src/ids/HostId';

export type ListFamiliarFacesByHostArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
}

/**
 * @summary Endpoint for returning list of mutual users that attended experiences organized by given host
 * @param {ListFamiliarFacesByHostArgs} args
 */
export const listFamiliarFacesByHost = (args: ListFamiliarFacesByHostArgs): Promise<HttpResult<UserWithSharedExperience[]>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/private/hosts/{hostId}/users', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
