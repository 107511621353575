import React from 'react';
import classNames from 'classnames';
import { useScreenSize } from '../../hooks';

type CollapseProps = {
  open: boolean;
  mobileOnly?: boolean;
  children: React.ReactNode;
};

function Collapse({ open, children, mobileOnly }: CollapseProps) {
  const { isMedium } = useScreenSize();

  if (mobileOnly && !isMedium) {
    return null;
  }
  /*
  if (!mobileOnly && isMedium) {
    return null;
  }
  */

  return (
    <div
      className={classNames('w-full transition-all delay-150 duration-300', {
        'h-full': open,
        'h-0': !open,
      })}
    >
      {open ? children : null}
    </div>
  );
}

export { Collapse };
