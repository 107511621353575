/* eslint-disable */
import { StrictBuilder } from 'builder-pattern';




/**
 * <p>The status of the user. This is used to denote whether the account is active or in another state</p>
 */
export enum UserStatus {
	Signup = 'Signup',
	Active = 'Active',
	Inactive = 'Inactive',
	Phantom = 'Phantom'
}

