const COLOR_SHADES = [
  '#E6C2D6',
  '#E0B9CF',
  '#DBB0C9',
  '#D6A5C2',
  '#D19BBB',
  '#CC92B4',
  '#C588AE',
  '#BF7EA7',
  '#BA749F',
  '#B56B98',
  '#AF6191',
  '#AA5789',
  '#A44D82',
  '#9F437B',
  '#993972',
  '#93316A',
  '#8E2863',
  '#88245D',
  '#831E56',
  '#7D1A50',
  '#781649',
  '#721243',
  '#6D0D3C',
  '#670937',
  '#620431',
  '#5C002A',
  '#570024',
  '#51001E',
  '#4C0017',
  '#460011',
  '#41000A',
  '#3B0004',
  '#360000',
  '#320000',
  '#2D0000',
  '#290000',
  '#240000',
  '#FBCAD6',
  '#FABACB',
  '#F9AABF',
  '#F89AAF',
  '#F789A4',
  '#F67998',
  '#F5688C',
  '#F45780',
  '#F34774',
  '#F23669',
  '#F1265D',
  '#F01551',
];

/**
 * Converts a string to hex color
 */
export const stringToColor = (str: string): string => {
  let hash = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const index = Math.abs(hash) % COLOR_SHADES.length;

  return COLOR_SHADES[index];
};

/**
 * Converts a hex color to text color
 */
export const colorToText = (hexColor: string) => {
  hexColor = hexColor.replace('#', '');

  // Convert hex to RGB
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return luminance > 0.5 ? '#000000' : '#FFFFFF';
};
