/* eslint-disable */
import { StrictBuilder } from 'builder-pattern';




/**
 * <p>The list of supported content types we allow for uploading images</p>
 */
export enum ImageContentType {
	ImagePng = 'image/png',
	ImageJpeg = 'image/jpeg',
	ImageWebp = 'image/webp'
}

