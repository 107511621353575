import classNames from 'classnames';
import { Banner } from 'flowbite-react';
import React from 'react';

type NotificationBannerProps = {
  children: React.ReactNode | string;
  closeBtnText?: string;
  onClose?: () => void;
  className?: string;
};

export function NotificationBanner({
  children,
  closeBtnText,
  onClose,
  className,
}: NotificationBannerProps) {
  const [show, setShow] = React.useState(true);

  const onClick = () => {
    if (onClose) {
      onClose();
    }
    setShow(false);
  };

  if (!show) {
    return null;
  }

  return (
    <Banner
      className={classNames(
        className,
        'flex w-full items-center justify-center px-20',
      )}
    >
      <div className="flex flex-col justify-between rounded-xl border border-primary bg-white p-4 shadow-sm md:flex-row lg:max-w-7xl">
        <div className="relative mb-3 mr-4 flex flex-col items-start md:mb-0 md:flex-row md:items-center">
          <p className="flex items-center text-sm">{children}</p>
        </div>
        {closeBtnText && (
          <div className="flex shrink-0 items-center">
            <Banner.CollapseButton
              color="secondary"
              className="bg-secondary"
              onClick={onClick}
            >
              {closeBtnText}
            </Banner.CollapseButton>
          </div>
        )}
      </div>
    </Banner>
  );
}
