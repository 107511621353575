import React, { useState } from 'react';
import { Button, UserPill } from '@cg/module-frontend/src/components';
import { useAuth } from '@cg/module-frontend';
import { ChevronRightIcon, ChevronUpIcon } from '@cg/module-frontend/src/icons';
import FamiliarFaceItem from './FamiliarFacesItem';
import { UserWithSharedExperience } from '~/generated/models/UserWithSharedExperience';

type FamiliarFacesProps = {
  users: UserWithSharedExperience[];
  showInterested?: boolean;
};

const minItems = 5;

export default function Faces({ users, showInterested }: FamiliarFacesProps) {
  const { login, isAuthenticated, self } = useAuth();

  const [isOpen, setIsOpen] = useState(true);

  const usersCount = users?.length || 0;
  const itemsToDisplay = isOpen ? minItems : Math.max(20, usersCount);
  const hasMore = Math.max(0, usersCount - minItems);

  return (
    <div className="p-4 space-y-5 w-full">
      <div className="space-y-3 overflow-y-scroll max-h-[60vh]">
        {showInterested && self && (
          <UserPill user={self.user} hideName>
            <span className="text-sm font-light truncate ml-auto">
              You are interested in this Experience
            </span>
          </UserPill>
        )}
        {(users || []).slice(0, itemsToDisplay).map((user) => (
          <FamiliarFaceItem user={user} key={user.id.getValue()} />
        ))}
      </div>
      {isOpen && hasMore > 0 ? (
        <Button
          color="secondary"
          className="w-full items-center justify-center"
          size="sm"
          onClick={() => setIsOpen(false)}
        >
          <span>+{hasMore} more</span>
          <ChevronRightIcon className="text-primary stroke-primary size-6" />
        </Button>
      ) : (
        minItems < usersCount && (
          <Button
            color="secondary"
            className="w-full items-center justify-center hover:bg-white"
            size="sm"
            onClick={() => setIsOpen(true)}
          >
            <span>Collapse</span>
            <ChevronUpIcon className="text-primary stroke-primary size-6" />
          </Button>
        )
      )}
      {/* )} */}
      {usersCount === 0 && (
        <span className="text-sm font-light flex justify-center items-center h-full w-full">
          {isAuthenticated ? (
            <span>
              {showInterested
                ? 'No Familiar Faces interested yet.'
                : 'No Familiar Faces attending yet.'}
            </span>
          ) : (
            <span>
              {"Guests you've met before. "}
              <button
                type="button"
                className="hover:underline text-primary"
                onClick={() => login()}
              >
                Login
              </button>
              {' to view.'}
            </span>
          )}
        </span>
      )}
    </div>
  );
}
