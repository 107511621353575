import {
  DesktopScreen,
  MobileScreen,
} from '@cg/module-frontend/src/components';

export default function BackgroundSvg({ className }: { className: string }) {
  return (
    <>
      <DesktopScreen>
        <svg
          className={className}
          width="721"
          height="491"
          viewBox="0 0 721 491"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.93732 84C-32.5636 442 331.437 610 354.938 390.5"
            stroke="#F1D7EA"
            strokeWidth="3"
            strokeDasharray="14 10"
          />
          <circle
            cx="8.9375"
            cy="77"
            r="6.5"
            fill="white"
            stroke="#931D72"
            strokeWidth="3"
          />
          <path
            d="M356.937 378.594C364.27 310.428 361.685 177.515 297.437 163.594C237.438 150.594 213.937 198.569 213.938 218.094C213.939 244.094 278.937 351.594 458.937 117.594C602.937 -69.6057 670.104 13.3333 686.938 75.5C688.604 113 689.977 187.763 642.938 257C598.438 322.5 667.936 403 719.936 406.5"
            stroke="#F1D7EA"
            strokeWidth="3"
            strokeDasharray="14 10"
          />
          <circle
            cx="354.938"
            cy="384"
            r="6.5"
            fill="white"
            stroke="#931D72"
            strokeWidth="3"
          />
          <circle
            cx="686.938"
            cy="78"
            r="6.5"
            fill="white"
            stroke="#931D72"
            strokeWidth="3"
          />
        </svg>
      </DesktopScreen>
      <MobileScreen>
        <svg
          className={className}
          width="204"
          height="1105"
          viewBox="0 0 204 1105"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M194.351 100.442C196.707 131 212.785 168.208 189.697 194.76C165.113 223.031 117.184 226.41 82.7198 223.948C58.2925 222.203 38.6256 213.721 28.2828 190.162C-4.5346 115.412 128.374 118.442 118.768 228.91C117.91 238.778 111.684 250.653 106.947 259.267C100.117 271.685 90.9624 284.219 82.1361 295.315C74.651 304.725 65.8787 314.109 61.1202 325.379C55.8435 337.877 54.1075 351.098 51.196 364.2C48.1757 377.791 53.7828 386.791 59.1499 398.351C62.8239 406.264 70.3794 417.068 78.1956 421.41C84.8484 425.106 91.7461 429.515 98.7737 432.502C109.251 436.955 120.008 438.118 130.006 444.323C168.883 468.454 188.707 519.898 188.707 567.5"
            stroke="#F1D7EA"
            strokeWidth="3"
            strokeDasharray="14 10"
          />
          <path
            d="M189.605 655.25C189.605 676.436 188.661 695.603 181.092 715.788C168.126 750.364 137.919 769.377 104.474 781.764C71.0331 794.15 23.4346 810.66 6.06046 768.758C0.858031 756.211 0.0944619 740.491 7.18371 728.675C17.7895 710.999 37.0901 703.788 56.9617 703.195C124.076 701.192 158.131 771.784 174.707 828.231C179.214 843.579 181.092 856.443 181.092 872.334C181.092 881.377 179.309 889.972 178.964 898.937C178.835 902.274 178.097 904.769 177.308 907.923C175.824 913.862 176.773 920.407 175.653 926.487C165.283 982.784 143.834 1047.9 190.669 1094.74"
            stroke="#F1D7EA"
            strokeWidth="3"
            strokeDasharray="14 10"
          />
          <circle
            cx="189.207"
            cy="8"
            r="6.5"
            fill="white"
            stroke="#931D72"
            strokeWidth="3"
          />
          <circle
            cx="189.207"
            cy="569"
            r="6.5"
            fill="white"
            stroke="#931D72"
            strokeWidth="3"
          />
          <circle
            cx="189.207"
            cy="1097"
            r="6.5"
            fill="white"
            stroke="#931D72"
            strokeWidth="3"
          />
        </svg>
      </MobileScreen>
    </>
  );
}
