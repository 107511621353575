import { FlowbiteDatepickerTheme } from 'flowbite-react';

const datepicker: Partial<FlowbiteDatepickerTheme> = {
  popup: {
    root: {
      base: 'absolute top-10 z-50 block pt-2',
      inline: 'relative top-0 z-auto',
      inner: 'inline-block rounded-lg bg-white p-4 shadow-lg',
    },
    header: {
      base: '',
      title: 'px-2 py-3 text-center font-semibold text-gray-900',
      selectors: {
        base: 'mb-2 flex justify-between',
        button: {
          base: 'rounded-lg bg-white px-5 py-2.5 text-sm font-semibold text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200',
          prev: '',
          next: '',
          view: '',
        },
      },
    },
    view: {
      base: 'p-1',
    },
    footer: {
      base: 'mt-2 flex space-x-2',
      button: {
        base: 'w-full rounded-lg px-5 py-2 text-center text-sm font-medium focus:ring-4 focus:ring-primary',
        today: 'bg-primary text-white hover:bg-primary-darker',
        clear: 'border border-grey bg-white text-black hover:bg-grey',
      },
    },
  },
  views: {
    days: {
      header: {
        base: 'mb-1 grid grid-cols-7',
        title: 'h-6 text-center text-sm font-medium leading-6 text-grey-darker',
      },
      items: {
        base: 'grid w-64 grid-cols-7',
        item: {
          base: 'block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-black hover:bg-grey',
          selected: 'bg-primary text-white hover:bg-primary-darker',
          disabled: 'text-gray-darker',
        },
      },
    },
    months: {
      items: {
        base: 'grid w-64 grid-cols-4',
        item: {
          base: 'block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100',
          selected: 'bg-primary text-white hover:bg-primary',
          disabled: 'text-grey-darker',
        },
      },
    },
    years: {
      items: {
        base: 'grid w-64 grid-cols-4',
        item: {
          base: 'block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100 ',
          selected: 'bg-primary text-white hover:bg-primary',
          disabled: 'text-grey-darker',
        },
      },
    },
    decades: {
      items: {
        base: 'grid w-64 grid-cols-4',
        item: {
          base: 'block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9  text-gray-900 hover:bg-gray-100',
          selected: 'bg-primary text-white hover:bg-primary',
          disabled: 'text-grey-darker',
        },
      },
    },
  },
};

export default datepicker;
