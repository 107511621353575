import reactHotToast, { Toast, ToastOptions, Toaster } from 'react-hot-toast';

type Options = {
  duration?: number;
  dismissible?: boolean;
};

const getOptions = (options?: Options): ToastOptions => {
  return {
    ...options,
  };
};

const render = (message: string, dismiss = false) =>
  function content(t: Toast) {
    return (
      <div className="flex flex-grow flex-row justify-end space-x-1">
        <div className="grow">{message}</div>
        {dismiss && (
          <button
            onClick={() => reactHotToast.dismiss(t.id)}
            className="flex-none"
            type="button"
          >
            ×
          </button>
        )}
      </div>
    );
  };

/**
 * Shows a success toast
 */
export const success = (message: string, options?: Options) => {
  reactHotToast.success(render(message, options?.dismissible || false), {
    duration: 1500,
    iconTheme: {
      primary: '#1D933E',
      secondary: '#FFF',
    },
    ...getOptions(options),
  });
};

/**
 * Shows a success toast
 */
export const error = (message: string, options?: Options) => {
  reactHotToast.error(render(message, options?.dismissible || true), {
    duration: 100000,
    iconTheme: {
      primary: '#AE2121',
      secondary: '#FFF',
    },
    ...getOptions(options),
  });
};

/**
 * Shows a success toast
 */
export const warning = (message: string, options?: Options) => {
  reactHotToast.error(render(message, options?.dismissible || false), {
    duration: 100000,
    icon: <span className="text-warning">⚠️</span>,
    ...getOptions(options),
  });
};

export const clearAll = () => reactHotToast.remove();

export const toast = {
  clearAll,
  success,
  warning,
  error,
};

export { Toaster };
