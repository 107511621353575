import {
  Button as FBButton,
  ButtonProps as FBButtonProps,
  Spinner,
} from 'flowbite-react';
import React, { type ElementType } from 'react';

type ButtonProps<T extends ElementType = 'button'> = FBButtonProps<T> & {
  loading?: boolean;
  stopPropagation?: boolean;
  icon?: React.ReactNode;
  iconEnd?: React.ReactNode;
};

export default function Button({
  loading,
  children,
  icon,
  iconEnd,
  onClick,
  stopPropagation,
  ...props
}: ButtonProps) {
  if (loading) {
    props.disabled = true;
  }

  if (React.isValidElement(children)) {
    const originalClassName = children.props.className || '';
    children = React.cloneElement(children, {
      ...props,
      // @ts-ignore
      className: `${originalClassName} flex items-center'`,
    });
  }

  const getChild = () => {
    return (
      <>
        {icon}
        {children}
        {iconEnd}
      </>
    );
  };

  return (
    <FBButton
      {...props}
      // @ts-ignore
      onClick={(e) => {
        if (stopPropagation) {
          e?.stopPropagation();
          e?.preventDefault();
        }
        if (onClick) {
          onClick(e);
        }
      }}
    >
      {loading && <Spinner />}
      {!loading && getChild()}
    </FBButton>
  );
}
