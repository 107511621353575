/* eslint-disable */


import { useHttp } from '@cg/module-frontend/src/core/http';

import { checkout, CheckoutArgs, fetchExperience, FetchExperienceArgs, fetchExperienceAddress, FetchExperienceAddressArgs, fetchFailureConfirmation, FetchFailureConfirmationArgs, fetchSuccessConfirmation, FetchSuccessConfirmationArgs, listBatchExperiences, ListBatchExperiencesArgs, listExperienceMedias, ListExperienceMediasArgs, listExperiences, ListExperiencesArgs, listQuestions, ListQuestionsArgs, listSeries, ListSeriesArgs, listTicketTiers, ListTicketTiersArgs } from './PublicExperiences.client';

export const checkoutHook = (immediateCall: boolean = true, initArgs?: CheckoutArgs) => {
  return useHttp(checkout, { immediateCall, initArgs })
};
export const fetchExperienceHook = (immediateCall: boolean = true, initArgs?: FetchExperienceArgs) => {
  return useHttp(fetchExperience, { immediateCall, initArgs })
};
export const fetchExperienceAddressHook = (immediateCall: boolean = true, initArgs?: FetchExperienceAddressArgs) => {
  return useHttp(fetchExperienceAddress, { immediateCall, initArgs })
};
export const fetchFailureConfirmationHook = (immediateCall: boolean = true, initArgs?: FetchFailureConfirmationArgs) => {
  return useHttp(fetchFailureConfirmation, { immediateCall, initArgs })
};
export const fetchSuccessConfirmationHook = (immediateCall: boolean = true, initArgs?: FetchSuccessConfirmationArgs) => {
  return useHttp(fetchSuccessConfirmation, { immediateCall, initArgs })
};
export const listBatchExperiencesHook = (immediateCall: boolean = true, initArgs?: ListBatchExperiencesArgs) => {
  return useHttp(listBatchExperiences, { immediateCall, initArgs })
};
export const listExperienceMediasHook = (immediateCall: boolean = true, initArgs?: ListExperienceMediasArgs) => {
  return useHttp(listExperienceMedias, { immediateCall, initArgs })
};
export const listExperiencesHook = (immediateCall: boolean = true, initArgs?: ListExperiencesArgs) => {
  return useHttp(listExperiences, { immediateCall, initArgs })
};
export const listQuestionsHook = (immediateCall: boolean = true, initArgs?: ListQuestionsArgs) => {
  return useHttp(listQuestions, { immediateCall, initArgs })
};
export const listSeriesHook = (immediateCall: boolean = true, initArgs?: ListSeriesArgs) => {
  return useHttp(listSeries, { immediateCall, initArgs })
};
export const listTicketTiersHook = (immediateCall: boolean = true, initArgs?: ListTicketTiersArgs) => {
  return useHttp(listTicketTiers, { immediateCall, initArgs })
};
