import React, { useEffect, useMemo } from 'react';
import {
  Swipper,
  Loader,
  SwiperSlide,
  Img,
} from '@cg/module-frontend/src/components';
import classNames from 'classnames';
import { LockIcon } from '@cg/module-frontend/src/icons';
import { useScreenSize } from '@cg/module-frontend/src/hooks';
import { ExperienceMediaType } from '~/generated/models/ExperienceMediaType';
import { Experience } from '~/generated/models/Experience';
import { listExperienceMediasHook } from '~/generated/clients/public/experiences/PublicExperiences.hooks';

type Media = {
  key: string;
  type: ExperienceMediaType;
  url: string;
};

type BannerProps = {
  experience?: Experience;
  firstImageOnly?: boolean;
};

const Banner = React.memo(({ experience, firstImageOnly }: BannerProps) => {
  const { isMobile } = useScreenSize();
  const {
    data,
    call: fetchMedias,
    calling: fetchingMedias,
  } = listExperienceMediasHook(false);

  useEffect(() => {
    if (experience) {
      fetchMedias({ ids: { experienceId: experience.id } });
    }
  }, [experience]);

  const medias = useMemo(() => {
    if (!data || !experience) return [];

    const list = data.map((media) => ({
      key: media.id.getValue(),
      type: media.type,
      url: media.url,
    }));

    list.unshift({
      key: 'banner',
      type: ExperienceMediaType.Image,
      url: experience.bannerUrl,
    });

    return list;
  }, [data, experience]);

  return (
    <Loader loading={fetchingMedias || medias?.length === 0} horizontal>
      <Swipper usePagination={medias.length > 1 && !firstImageOnly}>
        {!experience?.isPublic && (
          <div
            className={classNames(
              'absolute left-4 top-4 flex items-center bg-grey opacity-65 p-2 gap-2 rounded text-lg z-10',
            )}
          >
            <LockIcon className="size-6" /> Private
          </div>
        )}
        {medias.map((media: Media) => {
          return (
            <SwiperSlide
              className={classNames('w-full aspect-square overflow-hidden', {
                rounded: !isMobile,
                'rounded-b': isMobile,
              })}
              key={media.key}
            >
              {media.type === ExperienceMediaType.Image && (
                <Img className="object-cover" src={media.url} alt={media.url} />
              )}
            </SwiperSlide>
          );
        })}
      </Swipper>
    </Loader>
  );
});
export default Banner;
