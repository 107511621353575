import React from 'react';
import { Loader } from '../loader';

type Props = {
  children: React.ReactNode;
  fallback?: React.ReactNode;
};

export default function Suspense({ children, fallback }: Props) {
  if (!fallback) {
    fallback = <Loader loading horizontal />;
  }

  return <React.Suspense fallback={fallback}>{children}</React.Suspense>;
}
