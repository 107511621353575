import React, { useMemo } from 'react';
import * as DateUtils from '@cg/common/src/utils/DateUtils';
import { Link } from 'react-router-dom';
import {
  ClockIcon,
  TicketIcon,
  CalendarIcon,
  LocationIcon,
} from '@cg/module-frontend/src/icons';
import { Button, HostLabel, Img } from '@cg/module-frontend/src/components';
import classNames from 'classnames';
import { DetailedExperience } from '~/generated/models/DetailedExperience';
import { ExperienceAvailability } from '~/generated/models/ExperienceAvailability';
import ExperienceSummaryFamiliarFaces from '~/components/experiences/ExperienceSummaryFamiliarFaces';
import { AttendingStatus } from '~/generated/models/AttendingStatus';

type ExperienceSummaryCardProps = {
  experience: DetailedExperience;
  hideHost?: boolean;
};

const ExperienceSummaryCard = React.memo(
  ({ experience, hideHost }: ExperienceSummaryCardProps) => {
    const isPast = DateUtils.isPast(experience.startDate);
    const labels = useMemo(() => {
      const isAttending =
        experience.attendingStatus === AttendingStatus.Attending;
      const isWaitlisted =
        experience.attendingStatus === AttendingStatus.Waitlist;
      const soldOut =
        experience.availability === ExperienceAvailability.SoldOut;

      const labelArray = [];

      if (isAttending && isPast) {
        labelArray.push({ text: 'Attended', position: 'top-2 left-2' });
      }
      if (isAttending && !isPast) {
        labelArray.push({ text: 'Attending', position: 'top-2 left-2' });
      }
      if (isWaitlisted && !isPast) {
        labelArray.push({ text: 'Waitlisted', position: 'top-2 left-2' });
      }
      if (soldOut) {
        labelArray.push({ text: 'Sold Out', position: 'top-2 right-2' });
      }

      return labelArray;
    }, [experience, isPast]);

    return (
      <div className="space-y-2">
        <div
          className={classNames(
            'bg-white rounded p-0 pb-4 w-fit h-[41rem] flex flex-col',
            {
              'h-[41rem]': !hideHost,
              'h-[39rem]': hideHost,
            },
          )}
        >
          <Link
            to={`/e/${experience.host?.uniqueName}--${experience.uniqueName}`}
          >
            <div className="rounded relative overflow-hidden w-full">
              {labels.map((label) => (
                <span
                  key={label.text}
                  className={`bg-secondary rounded text-xs font-semibold p-1 px-2 text-primary absolute ${label.position}`}
                >
                  {label.text}
                </span>
              ))}
              <Img
                alt={experience.title}
                src={experience.bannerUrl}
                className="w-full h-full object-cover"
              />
            </div>
          </Link>
          <div className="px-2 grow flex flex-col">
            <div className="">
              <div className="my-4 line-clamp-2">
                <h4>{experience.title}</h4>
              </div>
              <span className="text-sm line-clamp-5 mb-2">
                {experience.summary}
              </span>
            </div>
            <div className="mt-auto grow flex flex-col justify-end">
              <hr className="h-1" />
              <div className="py-3 flex justify-between space-x-2 text-primary">
                <div className="flex items-center space-x-1">
                  <CalendarIcon className="text-primary size-4" />
                  <span className="text-sm font-normal">
                    {DateUtils.dater(experience.startDate).format(
                      'ddd, MMM DD',
                    )}
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <ClockIcon className="text-primary size-4" />
                  <span className="text-sm font-normal">
                    {DateUtils.dater(experience.startDate).format('h:mm A')}
                  </span>
                </div>
              </div>
              <div className="pb-3 flex justify-between space-x-2 text-primary">
                <div className="flex items-center space-x-1">
                  <LocationIcon className="text-primary size-4" />
                  <span className="text-sm font-normal">
                    {experience?.address?.name ? (
                      experience?.address?.name
                    ) : (
                      <i>Secret Location</i>
                    )}
                  </span>
                </div>
              </div>
              <div className="space-y-3">
                {!hideHost && <HostLabel host={experience.host} />}
                <Link
                  className="flex"
                  to={`/e/${experience.host?.uniqueName}--${experience.uniqueName}`}
                >
                  <Button
                    color="secondary"
                    size="sm"
                    className="w-full"
                    icon={<TicketIcon className="text-primary size-6" />}
                  >
                    <span className="text-base font-medium flex items-center">
                      {isPast ? (
                        <>View</>
                      ) : (
                        <>
                          {experience.availability ===
                            ExperienceAvailability.SoldOut && (
                            <>Info & Waitlist</>
                          )}
                          {experience.availability !==
                            ExperienceAvailability.SoldOut && (
                            <>Tickets & Info</>
                          )}
                        </>
                      )}
                    </span>
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <ExperienceSummaryFamiliarFaces users={experience.connections} />
      </div>
    );
  },
);

export default ExperienceSummaryCard;
