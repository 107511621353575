import { ReactNode } from 'react';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { AuthHook } from './AuthHook';
import { boxConfig } from '../../config/index';

type Prop = {
  children: ReactNode;
};

export function AuthProvider({ children }: Prop) {
  const onRedirectCallback = (appState: AppState | undefined) => {
    const { returnTo, ...rest } = appState || {};
    let redirectTo =
      returnTo && !returnTo.includes('login')
        ? returnTo
        : window.location.origin;
    try {
      const url = new URL(redirectTo);
      if (!url.hostname) {
        redirectTo = window.location.origin + redirectTo;
      }
    } catch (e) {
      redirectTo = window.location.origin + redirectTo;
    }
    const url = new URL(redirectTo);
    const params = new URLSearchParams(rest);
    url.search = params.toString();
    window.location.assign(redirectTo);
  };

  const subdomain = boxConfig.domain.includes('stage') ? '.stage' : '';

  return (
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_LOGIN_CLIENT_ID}
      useRefreshTokens
      cacheLocation="localstorage"
      cookieDomain={`${subdomain}.joincommonground.com`}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        audience: `https://${import.meta.env.VITE_AUTH0_DOMAIN}/api/v2/`,
        redirect_uri: window.location.origin,
        scope: 'openid profile offline_access email read:current_user',
        useRefreshTokens: true,
      }}
    >
      <AuthHook>{children}</AuthHook>
    </Auth0Provider>
  );
}
