import { ClientException } from '@tsed/exceptions';

function ClientExceptionBoundary({ message }: ClientException) {
  return (
    <div className="w-fit h-screen flex flex-col justify-center items-center">
      <div className="text-4xl text-red-500">Error: {message}</div>
    </div>
  );
}

export default ClientExceptionBoundary;
