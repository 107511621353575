/* eslint-disable */


import { useHttp } from '@cg/module-frontend/src/core/http';

import { fetchExperience, FetchExperienceArgs, fetchHost, FetchHostArgs, fetchHostByUniqueName, FetchHostByUniqueNameArgs, listCommunityHost, ListCommunityHostArgs, listExperiences, ListExperiencesArgs, listHosts, listHostUsers, ListHostUsersArgs, listSeries, ListSeriesArgs } from './PublicHosts.client';

export const fetchExperienceHook = (immediateCall: boolean = true, initArgs?: FetchExperienceArgs) => {
  return useHttp(fetchExperience, { immediateCall, initArgs })
};
export const fetchHostHook = (immediateCall: boolean = true, initArgs?: FetchHostArgs) => {
  return useHttp(fetchHost, { immediateCall, initArgs })
};
export const fetchHostByUniqueNameHook = (immediateCall: boolean = true, initArgs?: FetchHostByUniqueNameArgs) => {
  return useHttp(fetchHostByUniqueName, { immediateCall, initArgs })
};
export const listCommunityHostHook = (immediateCall: boolean = true, initArgs?: ListCommunityHostArgs) => {
  return useHttp(listCommunityHost, { immediateCall, initArgs })
};
export const listExperiencesHook = (immediateCall: boolean = true, initArgs?: ListExperiencesArgs) => {
  return useHttp(listExperiences, { immediateCall, initArgs })
};
export const listHostsHook = (immediateCall: boolean = true, ) => {
  return useHttp(listHosts, { immediateCall })
};
export const listHostUsersHook = (immediateCall: boolean = true, initArgs?: ListHostUsersArgs) => {
  return useHttp(listHostUsers, { immediateCall, initArgs })
};
export const listSeriesHook = (immediateCall: boolean = true, initArgs?: ListSeriesArgs) => {
  return useHttp(listSeries, { immediateCall, initArgs })
};
