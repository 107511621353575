import { memo, ReactElement } from 'react';
import { Properties } from 'posthog-js';
import Href from './Href';

type SocialLinkProps = {
  to: string;
  icon: ReactElement;
  className?: string;
  track?: Properties;
};

const SocialLink = memo(({ to, icon, track, className }: SocialLinkProps) => (
  <Href to={to} target="_blank" track={track} className={className} asIcon>
    {icon}
  </Href>
));

export default SocialLink;
