import React, { useRef } from 'react';

import {
  Page,
  Content,
  Section,
  Cover,
  DesktopScreen,
  MobileScreen,
  Polaroid,
} from '@cg/module-frontend/src/components';
import { LocationIcon } from '@cg/module-frontend/src/icons';
import { ExperiencesList } from '~/components/experiences';
import BackgroundSvg from './components/BackgroundSvg';
import { RunClubsList } from '~/components/clubs';

export const HomePage = React.memo(() => {
  const upcomingExperiencesRef = useRef<HTMLDivElement>(null);
  const scrollTo = () => {
    window.scrollTo({
      top: (upcomingExperiencesRef?.current?.offsetTop ?? 0) - 120,
      behavior: 'smooth',
    });
  };

  return (
    <Page>
      <Cover
        title="Find Your People."
        imageUrl="https://assets.joincommonground.com/site/images/banners/home_page_banner.webp"
        subtitle={
          <span className="flex items-center gap-1">
            <LocationIcon className="size-6 text-white" />
            <span>Vancouver, BC</span>
          </span>
        }
        cta="Explore Experiences"
        ctaCallback={scrollTo}
      />
      <DesktopScreen>
        <Section className="relative mt-10 h-[44rem] overflow-hidden">
          <h2 className="text-primary ml-[12vw]">Welcome to Common Ground</h2>
          <div className="flex relative w-full h-full mt-10 items-center justify-center overflow-visible">
            <BackgroundSvg className="absolute top-0 z-[-1000] mr-40" />
            <div className="relative flex flex-col justify-between items-start max-w-[300px] py-4 pl-[24px] -top-[249px] -left-[15px]">
              <h3 className="text-primary text-center md:text-left">
                Join an Experience
              </h3>
              <p>
                Every Experience is specifically designed for meeting new
                people.
              </p>
            </div>

            <div className="relative flex flex-col justify-between items-start max-w-[250px] py-4 left-[55px] top-[81px]">
              <h3 className="text-primary text-center md:text-left">
                Meet New People...
              </h3>
              <p>
                Common Ground remembers everyone you attend an Experience with.
              </p>
            </div>

            <div className="relative flex flex-col justify-between items-start max-w-[275px] py-4 left-[142px] -top-[237px]">
              <h3 className="text-primary text-center md:text-left">
                ...And Meet Them Again
              </h3>
              <p>
                Future events are recommended based on who&apos;s going whom
                you&apos;ve already met.
              </p>
            </div>
          </div>
          <div className="absolute flex flex-row -left-20 w-full h-full top-0 items-center justify-center">
            <Polaroid
              img="https://assets.joincommonground.com/site/images/events/friendship_in_the_fast_lane.webp"
              caption="Friendship in the Fast Lane 🏎️  Feb '24"
              className="relative -rotate-6 top-[100px] -left-[50px]"
            />
            <Polaroid
              img="https://assets.joincommonground.com/site/images/events/boogie_and_the_beach.webp"
              caption="Boogie & the Beach ☀️ Aug '23"
              className="relative -rotate-[8deg] top-[180px] left-[80px]"
            />
            <Polaroid
              img="https://assets.joincommonground.com/site/images/events/mistletoe_and_movies.webp"
              caption="Mistletoe & Movies 🎄 Dec '23"
              className="relative rotate-6 top-[0px] left-[250px] "
            />
            <Polaroid
              img="https://assets.joincommonground.com/site/images/events/the_secret_soiree.webp"
              caption="The Secret Soirée 💃 Oct '23"
              className="relative rotate-[1deg] top-[250px] left-[350px] "
            />
            <Polaroid
              img="https://assets.joincommonground.com/site/images/events/hikes_and_homies.webp"
              caption="Hikes & Homies 🏔️ May '23"
              className="relative rotate-[8deg] top-[80px] left-[450px]"
            />
          </div>
        </Section>
      </DesktopScreen>
      <MobileScreen>
        <Section className="relative flex justify-center flex-col w-full h-full overflow-hidden">
          <h2 className="text-primary ml-8 w-[300px]">
            Welcome to Common Ground
          </h2>
          <div className="relative mt-12 justify-start items-center flex flex-col h-[80rem]">
            <BackgroundSvg className="absolute top-0 mr-[180px]" />
            <div className="relative flex flex-col justify-between text-center items-center top-[20px] w-[18rem]">
              <h3 className="text-primary">Join an Experience</h3>
              <p className="leading-5">
                Every Experience is specifically designed for meeting new
                people.
              </p>
            </div>

            <div className="relative flex flex-col justify-between text-center items-center top-[505px] w-[19rem]">
              <h3 className="text-primary">Meet New People...</h3>
              <p className="leading-5">
                Common Ground remembers everyone you attend an Experience with.
              </p>
            </div>

            <div className="relative flex flex-col justify-between text-center items-center top-[970px] w-[18rem]">
              <h3 className="text-primary">...And Meet Them Again</h3>
              <p className="leading-5">
                Future events are recommended based on who&apos;s going whom
                you&apos;ve already met.
              </p>
            </div>

            <Polaroid
              img="https://assets.joincommonground.com/site/images/events/mistletoe_and_movies.webp"
              caption="Mistletoe & Movies 🎄 Dec '23"
              className="relative -rotate-[8deg] overflow-x-hidden top-[40px] ml-[600px] w-[260px] h-[260px]"
            />

            <Polaroid
              img="https://assets.joincommonground.com/site/images/events/hikes_and_homies.webp"
              caption="Hikes & Homies 🏔️ May '23"
              className="relative rotate-[8deg] overflow-x-hidden -top-[150px] mr-[100px] w-[260px] h-[260px]"
            />

            <Polaroid
              img="https://assets.joincommonground.com/site/images/events/boogie_and_the_beach.webp"
              caption="Boogie & the Beach ☀️ Aug '23"
              className="relative -rotate-[8deg] top-[120px] ml-[280px] w-[360px] h-[260px]"
            />
          </div>
        </Section>
      </MobileScreen>
      <Content>
        <RunClubsList />
        <div ref={upcomingExperiencesRef}>
          <ExperiencesList />
        </div>
        <ExperiencesList previous />
      </Content>
    </Page>
  );
});

export default HomePage;
