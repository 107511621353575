import React, { useEffect } from 'react';
import classNames from 'classnames';
import { toast } from '@cg/module-frontend';
import { TicketIcon } from '@cg/module-frontend/src/icons';
import { Step, useCheckout } from '../context/CheckoutContext';
import StepWrapper from '../components/StepWrapper';

const maxTickets = 5;

export function StepOneSelectTiers() {
  const { tiers, addTicket, removeTicket, tickets, stepValidation } =
    useCheckout();
  const [warnings, setWarnings] = React.useState<Map<string, string>>(
    new Map(),
  );
  const [maxReachedWarned, setMaxReachedWarned] = React.useState(false);
  const countMap = tickets.reduce((acc, ticket) => {
    acc.set(
      ticket.ticketTierId.getValue(),
      (acc.get(ticket.ticketTierId.getValue()) || 0) + 1,
    );
    return acc;
  }, new Map<string, number>());

  useEffect(() => {
    if (tickets.length >= maxTickets && !maxReachedWarned) {
      toast.warning('You can only buy up to 5 tickets at a time.', {
        duration: 3000,
        dismissible: false,
      });
      setMaxReachedWarned(true);
    }

    tiers.forEach((tier) => {
      if ((countMap.get(tier.id.getValue()) || 0) >= tier.available) {
        setWarnings(
          warnings.set(
            tier.id.getValue(),
            `There are no more ${tier.name} left.`,
          ),
        );
      } else {
        setWarnings(warnings.set(tier.id.getValue(), ''));
      }
    });
  }, [tickets, countMap, tiers]);

  useEffect(() => {
    stepValidation(Step.Step1, tickets.length > 0);
  }, [tickets]);

  return (
    <StepWrapper
      icon={<TicketIcon className="text-primary size-6" />}
      title="Pick Your Tickets"
      step={1}
    >
      {tiers.map((tier) => (
        <div key={tier.id.getValue()} className="flex justify-between py-4">
          <div className="grow">
            <span className="text-lg font-medium">{tier.name}</span>
            <p className="text-base font-light whitespace-pre-line">
              {tier.description.replace(/\\n/g, '\n')}
            </p>
            {warnings.get(tier.id.getValue()) && (
              <p className="text-warning">{warnings.get(tier.id.getValue())}</p>
            )}
          </div>
          <div className="shrink-0 space-y-2 md:space-y-0 md:flex md:space-x-2 md:space-x-reverse md:flex-row-reverse">
            <div className="flex justify-end md:bloc items-center">
              <div className="text-black bg-grey/50 border border-md rounded-md px-2 text-md font-medium">
                $ {(Math.round((tier.price / 100) * 100) / 100).toFixed(2)}
              </div>
            </div>
            <div className="flex items-center">
              <button
                type="button"
                disabled={!countMap.get(tier.id.getValue())}
                onClick={() => removeTicket(tier)}
                className={classNames('size-7 bg-secondary rounded text-xl', {
                  '!bg-grey': !countMap.get(tier.id.getValue()),
                })}
              >
                -
              </button>
              <span className="w-8 text-xl font-medium text-center">
                {
                  tickets.filter((ticket) => {
                    return ticket.ticketTierId.isEqual(tier.id);
                  }).length
                }
              </span>
              <button
                type="button"
                onClick={() => addTicket(tier)}
                disabled={
                  (countMap.get(tier.id.getValue()) || 0) >= tier.available ||
                  tickets.length >= maxTickets
                }
                className={classNames(
                  'size-7 bg-secondary rounded text-xl text-black-lighter',
                  {
                    '!bg-grey':
                      (countMap.get(tier.id.getValue()) || 0) >=
                        tier.available || tickets.length >= maxTickets,
                  },
                )}
              >
                +
              </button>
            </div>
          </div>
        </div>
      ))}
    </StepWrapper>
  );
}
