/* eslint-disable */
import { StrictBuilder } from 'builder-pattern';




/**
 * <p>The type of the Experience - used to render different UI</p>
 */
export enum ExperienceType {
	OneTime = 'OneTime',
	RunClub = 'RunClub'
}

