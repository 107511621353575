/* eslint-disable */
import { StrictBuilder } from 'builder-pattern';




/**
 * <p>The type of the Host photo</p>
 */
export enum HostPhotoType {
	Profile = 'Profile',
	Banner = 'Banner'
}

