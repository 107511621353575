/* eslint-disable */


import { http, HttpResult } from '@cg/module-frontend/src/core/http';

import { RSVPSubscribeRequest } from '../../models/RSVPSubscribeRequest';
import { RSVPSubscribeResponse } from '../../models/RSVPSubscribeResponse';
import { Root } from '../../models/Root';

export type RsvpCalendarArgs = {
    body: RSVPSubscribeRequest,
}

/**
 * @summary Endpoint for returning list of experiences for a given date
 * @param {RsvpCalendarArgs} args
 */
export const rsvpCalendar = (args: RsvpCalendarArgs): Promise<HttpResult<RSVPSubscribeResponse>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/public/rsvp-calendar', params);

    return http.post(url, {
        body: args.body,
    });
}
export type WelcomeArgs = {
}

/**
 * @summary Endpoint for the root page index
 */
export const welcome = (args: WelcomeArgs): Promise<HttpResult<Root>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/', params);

    return http.get(url, {
    });
}
