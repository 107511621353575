import React, { useMemo } from 'react';
import { Loader, SwiperSlide } from '@cg/module-frontend/src/components';
import ListSwiper from '~/components/list-swiper';
import { ExperienceType } from '~/generated/models/ExperienceType.ts';
import { listSeriesHook } from '~/generated/clients/public/experiences/PublicExperiences.hooks';
import RunClubSummaryCard from '~/components/clubs/RunClubSummaryCard.tsx';
import { useConsolidateClubs } from '~/hooks/series.hooks';

const RunClubsList = React.memo(() => {
  const {
    data: page,
    calling: isFetchingList,
    nextPage: fetchNextPage,
  } = listSeriesHook(true, {
    filters: {
      experienceType: ExperienceType.RunClub,
    },
  });
  const hasNextPage = page?.metadata?.nextPageUrl !== null;
  const consolidatedClubs = useConsolidateClubs(page?.result ?? []);

  const cards = useMemo(() => {
    const slides = consolidatedClubs.map(
      ({ series, days, paces, distances }) => (
        <SwiperSlide
          className="!overflow-hidden !w-80"
          key={series.id.getValue()}
        >
          <RunClubSummaryCard
            series={series}
            days={days}
            paces={[paces[0], paces[paces.length - 1]]}
            distances={[distances[0], distances[distances.length - 1]]}
          />
        </SwiperSlide>
      ),
    );

    if (isFetchingList) {
      slides.push(
        <SwiperSlide className="!overflow-hidden !w-60 !h-[50vh]" key="loader">
          <Loader loading vertical />
        </SwiperSlide>,
      );
    }

    return slides;
  }, [consolidatedClubs, isFetchingList]);

  const hasMore =
    !isFetchingList && hasNextPage && (page?.result?.length || 0) !== 0;

  return (
    <ListSwiper title="Run Clubs" hasMore={hasMore} fetchMore={fetchNextPage}>
      {cards}
    </ListSwiper>
  );
});

export default RunClubsList;
