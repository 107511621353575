export const HeaderConstants = {
  I_CG_API_CREDENTIALS: 'I-CommonGround-Api-Credentials',
  I_AUTHENTICATED_USER: 'I-Authenticated-User',
  I_AUTH_BYPASS: 'I-Auth-Bypass',
  I_WEB_VERSION: 'I-Web-Version',
  I_WEB_HASH: 'I-Web-Hash',

  STRIPE_SIGNATURE: 'Stripe-Signature',

  AUTHORIZATION: 'Authorization',
  CONTENT_TYPE: 'Content-Type',
};
