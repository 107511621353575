import React, { memo, useMemo } from 'react';
import {
  CalendarIcon,
  DistanceIcon,
  LocationIcon,
  TimerIcon,
} from '@cg/module-frontend/src/icons';
import * as DateUtils from '@cg/common/src/utils/DateUtils';
import { Button, Href, Img } from '@cg/module-frontend/src/components';
import { useAuth } from '@cg/module-frontend';
import { featureFlagConstants } from '@cg/common/src/constants';
import { DetailedExperienceSeries } from '~/generated/models/DetailedExperienceSeries';
import {
  useDistanceCalculator,
  useDistanceRangeDom,
  usePaceCalculator,
  usePaceRangeDom,
} from '~/hooks/series.hooks';
import { DetailedExperience } from '~/generated/models/DetailedExperience.ts';

type Props = {
  experiences: DetailedExperience[];
  series: DetailedExperienceSeries;
  signup: () => void;
};

const UpcomingRunSummaryCard = React.memo(
  ({ series, experiences, signup }: Props) => {
    const { hasFlag } = useAuth();
    const paces = usePaceCalculator([series]);
    const distances = useDistanceCalculator([series]);
    const allowSignup = hasFlag(
      featureFlagConstants.COMMONGROUND_SERIES_SIGNUP,
    );
    const formattedDate = useMemo(() => {
      const startDate = DateUtils.dater(experiences[0].startDate);
      return `${startDate.format('dddd')}s @ ${startDate.format('h:mmA')}`;
    }, [experiences]);

    const paceRange = usePaceRangeDom(paces);
    const distanceRange = useDistanceRangeDom(distances);

    return (
      <div className="bg-white rounded px-2 py-3 w-full h-fit flex flex-col">
        <div className="w-full relative flex flex-row grow justify-between">
          <div className="w-full grow flex flex-col px-2">
            <h4>{series.title}</h4>
            <div className="py-3 flex flex-col space-y-2 text-primary">
              <div className="flex items-center space-x-1">
                <CalendarIcon className="text-primary stroke-primary" />
                <span className="text-sm font-normal">{formattedDate}</span>
              </div>
              <div className="flex items-center space-x-1">
                <LocationIcon className="text-primary stroke-primary" />
                <span className="text-sm">
                  {series.address.neighbourhood ?? series.address.city}
                </span>
              </div>
              <div className="flex items-center space-x-1">
                <TimerIcon className="text-primary stroke-primary" />
                {paceRange}
              </div>
              <div className="flex items-center space-x-1">
                <DistanceIcon className="text-primary stroke-primary" />
                {distanceRange}
              </div>
            </div>
          </div>
          <div className="mt-4 w-[12rem] h-full flex aspect-square rounded relative justify-center items-center">
            <Img
              alt={series.title}
              src={series.bannerUrl}
              className="aspect-square rounded object-cover"
            />
          </div>
        </div>

        <div className="p-2 flex flex-col">{experiences[0].summary}</div>
        {allowSignup && (
          <Button color="primary" onClick={signup}>
            Signup
          </Button>
        )}
        {!allowSignup && series.host.socials?.instagram && (
          <Href
            to={series.host.socials?.instagram}
            target="_blank"
            className="p-2 flex flex-col"
            track={{
              $$type: 'visitHostInstagram',
              $$host: series.host.friendlyName,
              $$hostId: series.host.id.getValue(),
            }}
          >
            <Button color="primary">Run Forrest, Run!</Button>
          </Href>
        )}
      </div>
    );
  },
);

UpcomingRunSummaryCard.displayName = 'UpcomingRunSummaryCard';

export default memo(UpcomingRunSummaryCard);
