import React, { useEffect } from 'react';
import { Content, Href, Page } from '@cg/module-frontend/src/components';
import { ticketPurchaser } from '@cg/module-frontend/src/utils/storage.ts';
import { Heading } from '../components/Heading';
import Result from '../components/Result';
import MessageFromHost from '~/pages/experience/confirmation/components/MessageFromHost';
import { useExperience } from '~/app/context/experience';
import { fetchHostHook } from '~/generated/clients/public/hosts/PublicHosts.hooks';
import { listTicketsHook } from '~/generated/clients/private/experiences/PrivateExperiences.hooks';
import { fetchSuccessConfirmationHook } from '~/generated/clients/public/experiences/PublicExperiences.hooks';
import { TicketStatus } from '~/generated/models/TicketStatus';

function CheckoutSucceeded() {
  const guest = ticketPurchaser.has();
  const userId = ticketPurchaser.get();
  const { experience, fetchingExperience } = useExperience();
  const {
    data: confirmation,
    call: fetchConfirmation,
    calling: fetchingConfirmation,
  } = fetchSuccessConfirmationHook(false);
  const {
    calling: fetchingHost,
    data: host,
    call: fetchHost,
  } = fetchHostHook(false);

  const {
    data: tickets,
    calling: fetchingTickets,
    call: fetchTickets,
  } = listTicketsHook(false);

  useEffect(() => {
    if (experience) {
      fetchTickets({
        ids: { experienceId: experience.id },
        filters: { ticketStatus: [TicketStatus.Confirmed] },
      });
      fetchHost({ ids: { hostId: experience.hostId } });
    }
  }, [experience]);

  useEffect(() => {
    if ((tickets || guest) && experience) {
      fetchConfirmation({
        ids: { experienceId: experience.id },
        filters: {
          userId: userId?.getValue() ?? '',
        },
      });
    }
  }, [tickets, guest, experience]);

  return (
    <Page>
      <Content>
        <Result
          loading={
            fetchingExperience ||
            fetchingHost ||
            fetchingTickets ||
            fetchingConfirmation
          }
        >
          <Heading title="You're in! 🥳">
            <span>
              {'You can find your ticket in your '}
              <Href to="/profile/experiences">profile</Href> & your email.
            </span>
          </Heading>
          <MessageFromHost
            hideCTA={false}
            host={host}
            experience={experience}
            confirmation={confirmation}
          />
        </Result>
      </Content>
    </Page>
  );
}

export default CheckoutSucceeded;
