import { Id } from './Id';

export class ExperienceSeriesId extends Id {
  // This is required for stricter TS check
  // @ts-ignore
  private readonly _brand!: void;

  public static PREFIX = 'SE';

  public getPrefix(): string {
    return ExperienceSeriesId.PREFIX;
  }

  public static isValid(id: string): boolean {
    return id !== null && id.startsWith(ExperienceSeriesId.PREFIX) && Id.looksLikeId(id);
  }
}
