import React from 'react';

type StepProps = {
  icon?: React.ReactNode;
  title?: string;
  step: number;
  children: React.ReactElement | React.ReactElement[];
};

function StepWrapper({ step, children, icon, title }: StepProps) {
  return (
    <div className="py-4">
      <div className="flex justify-between p-4 items-center">
        <div className="flex space-x-2 items-center">
          {icon}
          <span className="text-xl font-semibold">{title}</span>
        </div>
        <span className="text-base font-light">step {step} of 3</span>
      </div>
      <div className="bg-white rounded p-4 mt-2 divide-y">{children}</div>
    </div>
  );
}

export default StepWrapper;
