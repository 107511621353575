import { FlowbiteSpinnerTheme } from 'flowbite-react';

const spinner: Partial<FlowbiteSpinnerTheme> = {
  base: 'inline animate-spin text-gray-200',
  color: {
    failure: 'fill-failure',
    gray: 'fill-grey',
    info: 'fill-primary',
    pink: '',
    purple: '',
    success: 'fill-success',
    warning: 'fill-warning',
  },
  light: {
    off: {
      base: 'dark:text-gray-600',
      color: {
        failure: '',
        gray: 'dark:fill-gray-300',
        info: '',
        pink: '',
        purple: '',
        success: '',
        warning: '',
      },
    },
    on: {
      base: '',
      color: {
        failure: '',
        gray: '',
        info: '',
        pink: '',
        purple: '',
        success: '',
        warning: '',
      },
    },
  },
  size: {
    xs: 'h-3 w-3',
    sm: 'h-4 w-4',
    md: 'h-6 w-6',
    lg: 'h-8 w-8',
    xl: 'h-10 w-10',
  },
};

export default spinner;
