import {
  SwiperSlide,
  Loader,
  Swipper,
} from '@cg/module-frontend/src/components';
import { useMemo } from 'react';
import { ListExperiencesArgs } from '~/generated/clients/public/hosts/PublicHosts.client';
import { listExperiencesHook } from '~/generated/clients/public/hosts/PublicHosts.hooks';
import { Host } from '~/generated/models/Host';
import { ExperienceSummaryCard } from '~/components/experiences';

type HostExperienceListProps = {
  host: Host;
  previous?: boolean;
};

function HostExperienceList({ host, previous }: HostExperienceListProps) {
  const args: ListExperiencesArgs = {
    ids: {
      hostId: host.id,
    },
    filters: {
      pageSize: 10,
    },
  };

  if (previous && args.filters) {
    args.filters.previousOnly = true;
  }

  const {
    data: experiences,
    calling: isFetchingList,
    nextPage: fetchNextPage,
  } = listExperiencesHook(true, args);

  const hasNextPage = experiences?.metadata?.nextPageUrl !== null;

  const renderCards = useMemo(() => {
    const cards =
      experiences?.result.map((experience) => (
        <SwiperSlide
          className="!overflow-hidden !w-64"
          key={experience.id.getValue()}
        >
          <ExperienceSummaryCard experience={experience} hideHost />
        </SwiperSlide>
      )) || [];

    if (isFetchingList) {
      cards.push(
        <SwiperSlide className="!overflow-hidden !w-60 !h-[50vh]" key="loader">
          <Loader loading vertical />
        </SwiperSlide>,
      );
    }
    return cards;
  }, [experiences?.result, host, isFetchingList]);

  return (
    <div className="w-full h-fit bg-grey rounded py-4 px-4">
      <Swipper
        useFreeMode
        useNavigation
        onReachEnd={() => {
          if (
            !isFetchingList &&
            hasNextPage &&
            (experiences?.result?.length || 0) !== 0
          ) {
            fetchNextPage();
          }
        }}
      >
        {renderCards}
      </Swipper>
    </div>
  );
}

export default HostExperienceList;
