import { Button, Checkbox, Modal } from '@cg/module-frontend/src/components';
import * as React from 'react';
import * as DateUtils from '@cg/common/src/utils/DateUtils';
import { CalendarIcon, LocationIcon } from '@cg/module-frontend/src/icons';
import { Experience } from '~/generated/models/Experience';
import { DetailedExperienceSeries } from '~/generated/models/DetailedExperienceSeries.ts';

type Props = {
  experiences: Experience[];
  series: DetailedExperienceSeries | null;
  cancel: () => void;
};

function SignupRunModal({ series, experiences, cancel }: Props) {
  const show = series !== null && experiences.length > 0;

  return (
    <Modal size="xl" show={show} onClose={cancel}>
      <Modal.Header>Pick your desired days</Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">You can pick one or more.</div>
        <div className="flex flex-col">
          {experiences.map((experience) => {
            return (
              <div
                key={experience.id.getValue()}
                className="flex flex-col border border-grey p-2 rounded"
              >
                {/* Header */}
                <div className="flex items-center justify-between mb-3">
                  <h4>
                    {DateUtils.dater(experience.startDate).format(
                      'dddd, MMM Do',
                    )}
                  </h4>
                  <Checkbox id="details.liquorLicense" />
                </div>

                {/* Icon Row */}
                <div className="flex justify-between text-primary">
                  {/* Calendar Section */}
                  <div className="flex items-center space-x-1 w-1/2">
                    <CalendarIcon className="text-primary stroke-primary" />
                    {/* <span className="text-sm font-normal">{formattedDate}</span> */}
                  </div>

                  {/* Location Section */}
                  <div className="flex items-center space-x-1 w-1/2">
                    <LocationIcon className="text-primary stroke-primary" />
                    <span className="text-sm">
                      {/* {series.address.neighbourhood ?? series.address.city} */}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer className="flex w-full space-x-4">
        <Button color="secondary" className="flex-1" onClick={cancel}>
          Cancel
        </Button>

        <Button color="primary" className="flex-1">
          Sign Up
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

SignupRunModal.displayName = 'SignupRunModal';

export default SignupRunModal;
