import phone from 'phone';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';

export const isValidPhone = (
  number: string | null | undefined,
  country?: CountryCode,
): boolean => {
  if (country && number) {
    return isValidPhoneNumber(number, country);
  }

  return !!number && phone(number).isValid && /^[\d+]*$/.test(number);
};

export const normalizePhone = (number: string) => {
  return phone(number).phoneNumber;
};

export const getPhoneObject = (number: string) => {
  return phone(number);
};
