import React, { useMemo } from 'react';
import { Loader, SwiperSlide } from '@cg/module-frontend/src/components';
import ExperienceSummaryCard from '~/components/experiences/ExperienceSummaryCard';
import { listExperiencesHook } from '~/generated/clients/public/experiences/PublicExperiences.hooks';
import { ListExperiencesArgs } from '~/generated/clients/public/experiences/PublicExperiences.client';
import ListSwiper from '~/components/list-swiper';

type ExperiencesListProps = {
  previous?: boolean;
  hideCity?: boolean;
};

const ExperiencesList = React.memo(
  ({ previous, hideCity }: ExperiencesListProps) => {
    const args: ListExperiencesArgs = {
      filters: {
        pageSize: 10,
      },
    };
    if (previous && args.filters) {
      args.filters.previousOnly = true;
    }

    const {
      data: experiences,
      calling: isFetchingList,
      nextPage: fetchNextPage,
    } = listExperiencesHook(true, args);
    const hasNextPage = experiences?.metadata?.nextPageUrl !== null;

    const cards = useMemo(() => {
      const result =
        experiences?.result.map((experience) => (
          <SwiperSlide
            className="!overflow-hidden !w-64"
            key={experience.id.getValue()}
          >
            <ExperienceSummaryCard experience={experience} />
          </SwiperSlide>
        )) || [];
      if (isFetchingList) {
        result.push(
          <SwiperSlide
            className="!overflow-hidden !w-60 !h-[50vh]"
            key="loader"
          >
            <Loader loading vertical />
          </SwiperSlide>,
        );
      }
      return result;
    }, [experiences?.result, isFetchingList]);

    const hasMore =
      !isFetchingList &&
      hasNextPage &&
      (experiences?.result?.length || 0) !== 0;

    return (
      <ListSwiper
        hideCity={hideCity ?? false}
        title={previous ? 'Previous Experiences' : 'Upcoming Experiences'}
        hasMore={hasMore}
        fetchMore={fetchNextPage}
      >
        {cards}
      </ListSwiper>
    );
  },
);

export default ExperiencesList;
