import { Spinner } from 'flowbite-react';
import { CreateTicketTierRequest } from '~/generated/models/CreateTicketTierRequest';

type ExperienceStartingPriceProps = {
  tiers: CreateTicketTierRequest[];
  allowEmpty?: boolean;
};

function ExperienceStartingPrice({
  tiers,
  allowEmpty,
}: ExperienceStartingPriceProps) {
  const lowestPrice = tiers.reduce((lowest, tier) => {
    if (lowest > tier.price) {
      return tier.price;
    }

    return lowest;
  }, 100_000);
  const onlyFree = tiers.every((tier) => tier.price === 0);
  if (onlyFree) {
    return (
      <span className="text-xl font-semibold text-white">Attend for Free</span>
    );
  }

  return (
    <>
      <span className="text-base text-white">Starting at:</span>
      {tiers.length === 0 && !allowEmpty ? (
        <Spinner />
      ) : (
        <span className="text-xl font-semibold text-white">
          $ {(lowestPrice / 100).toFixed(2)}
        </span>
      )}
    </>
  );
}

export default ExperienceStartingPrice;
