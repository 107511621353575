import React from 'react';
import { Link, Outlet, useMatches } from 'react-router-dom';
import {
  Collapse,
  Content,
  DesktopScreen,
  MobileScreen,
  Page,
  Section,
} from '@cg/module-frontend/src/components';
import { TicketIcon, UserIcon } from '@cg/module-frontend/src/icons';
import { useAuth } from '@cg/module-frontend';
import { UserInformation } from './UserInformation';
import ProfileTab from './ProfileTab.tsx';

enum ActiveProfileTab {
  Experiences,
  PersonalDetails,
  Connections,
}
const MAP = {
  experiences: ActiveProfileTab.Experiences,
  details: ActiveProfileTab.PersonalDetails,
  connections: ActiveProfileTab.Connections,
};
type ProfileTabKey = keyof typeof MAP;

function useProfilePathParam(): ActiveProfileTab {
  const matches = useMatches();
  const profileMatch = matches.find((match) =>
    match.pathname.includes('/profile/'),
  );

  if (profileMatch) {
    const regex = /\/profile\/(\w+)/;
    const match = profileMatch.pathname.match(regex);

    if (match?.[1]) {
      const profileKey = match[1] as ProfileTabKey;
      if (MAP[profileKey]) {
        return MAP[profileKey];
      }
    }
  }

  return ActiveProfileTab.Experiences;
}

export default function ProfileLayout() {
  const tab = useProfilePathParam();
  const { self } = useAuth();

  return (
    <>
      <DesktopScreen>
        <Page className="hidden md:block">
          <Content>
            <Section className="pb-9 flex px-2 bg-white rounded space-x-2 flex-col">
              <div className="rounded border w-full px-2 pb-1">
                <div className="w-full px-2 items-center justify-center flex flex-col">
                  <ul className="flex flex-wrap -mb-px text-sm font-medium text-center items-center w-full justify-end p-4">
                    <li className="me-2 mr-auto" role="presentation">
                      {self && <UserInformation self={self} />}
                    </li>
                    <li className="me-2" role="presentation">
                      <Link to="/profile/experiences">
                        <ProfileTab
                          icon={<TicketIcon className="size-6" />}
                          title="My Experiences"
                          isActive={tab === ActiveProfileTab.Experiences}
                        />
                      </Link>
                    </li>
                    <li className="me-2" role="presentation">
                      <Link to="/profile/details">
                        <ProfileTab
                          icon={<UserIcon className="size-6" />}
                          title="Personal Details"
                          isActive={tab === ActiveProfileTab.PersonalDetails}
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
                <div>
                  <div className="w-full bg-grey rounded py-4 px-6">
                    <Outlet />
                  </div>
                </div>
              </div>
            </Section>
          </Content>
        </Page>
      </DesktopScreen>
      <MobileScreen>
        <div className="w-full mx-auto block md:hidden">
          {self && <UserInformation self={self} />}
          <Link to="/profile/experiences">
            <ProfileTab
              title="My Experiences"
              icon={<TicketIcon />}
              isActive={tab === ActiveProfileTab.Experiences}
            />
          </Link>
          <Collapse open={tab === ActiveProfileTab.Experiences} mobileOnly>
            <div className="w-full bg-grey rounded py-4 px-6">
              <Outlet />
            </div>
          </Collapse>

          <Link to="/profile/details">
            <ProfileTab
              title="Personal Details"
              icon={<UserIcon />}
              isActive={tab === ActiveProfileTab.PersonalDetails}
            />
          </Link>

          <Collapse open={tab === ActiveProfileTab.PersonalDetails} mobileOnly>
            <div className="w-full bg-grey rounded py-4 px-6">
              <Outlet />
            </div>
          </Collapse>
        </div>
      </MobileScreen>
    </>
  );
}
