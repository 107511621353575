import { memo, useMemo } from 'react';
import { HostUserProfile } from '~/generated/models/HostUserProfile';
import { SwiperSlide, Swipper } from '../../swipper';
import Img from '../../img';

type HostUserProps = {
  users: HostUserProfile[];
  slideSize?: string;
  className?: string;
};

function HostUsers({ users, slideSize, className }: HostUserProps) {
  const filteredUsers = useMemo(
    () => users.filter((user) => user.imageUrl),
    [users],
  );

  if (!filteredUsers.length) {
    return null;
  }

  return (
    <Swipper useFreeMode useNavigation className={className}>
      {filteredUsers.map((user: HostUserProfile) => {
        return (
          <SwiperSlide
            className={`${slideSize || 'w-1/2 h-1/2'} !overflow-hidden relative`}
            key={user.id.getValue()}
          >
            <Img
              className="object-cover w-full h-full rounded aspect-square"
              src={user.imageUrl ?? ''}
              alt={user.firstName}
            />
            <div className="z-20 rounded absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-primary to-transparent opacity-50" />
            <h3 className="z-30 absolute bottom-6 left-6 text-white">
              {user.firstName}
            </h3>
          </SwiperSlide>
        );
      })}
    </Swipper>
  );
}

export default memo(HostUsers);
