import { useNavigate } from 'react-router-dom';
import { Button, Content, Img, Page } from '../../components';
import { ArrowRightIcon } from '../../icons';

export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <Page className="flex items-center justify-center min-h-screen">
      <Content hideGetStarted>
        <div className="flex flex-col md:flex-row">
          {/* Image Section */}
          <div className="md:w-1/2 order-1 md:order-2">
            <Img
              src="https://assets.joincommonground.com/site/images/404.webp"
              alt="Descriptive Alt Text"
              className="w-full h-full object-cover rounded"
            />
          </div>

          {/* Text Section */}
          <div className="md:w-1/2 flex flex-col justify-center items-start p-8 order-2 md:order-1">
            <h1 className="mb-4">Hmm... it looks like you&apos;re lost.</h1>
            <p className="mb-6 text-primary">error: 404</p>
            <Button
              className="justify-center"
              color="primary"
              iconEnd={<ArrowRightIcon className="size-5" />}
              onClick={() => navigate('/')}
            >
              <span className="flex items-center">Find Your People</span>
            </Button>
          </div>
        </div>
      </Content>
    </Page>
  );
}
