import React from 'react';
import { tw } from '../../utils';

export type SectionProps = {
  className?: string;
  children: React.ReactNode;
};

function Section({ children, className }: SectionProps) {
  return <section className={tw('mt-11', className)}>{children}</section>;
}

export default Section;
