import { FlowbiteAccordionTheme } from 'flowbite-react';

const accordion: FlowbiteAccordionTheme = {
  root: {
    base: 'divide-y divide-grey border-grey',
    flush: {
      off: 'rounded border',
      on: 'border-b',
    },
  },
  content: {
    base: 'p-5 first:rounded-t last:rounded-b',
  },
  title: {
    arrow: {
      base: 'h-6 w-6 shrink-0',
      open: {
        off: '',
        on: 'rotate-180',
      },
    },
    base: 'flex w-full items-center justify-between p-4 text-left text-black-lighter first:rounded-t last:rounded-b !text-sm ',
    flush: {
      off: 'hover:bg-grey-darker focus:ring-1 focus:ring-grey-darker',
      on: 'bg-transparent',
    },
    heading: 'text-lg font-bold',
    open: {
      off: '',
      on: 'bg-grey text-black',
    },
  },
};

export default accordion;
