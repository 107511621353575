import { dater } from '@cg/common/src/utils/DateUtils';
import { Dropdown, Href, Map } from '@cg/module-frontend/src/components';
import { Link } from 'react-router-dom';
import { isAppleOS, useScreenSize } from '@cg/module-frontend/src/hooks';
import { tw } from '@cg/module-frontend';
import {
  CalendarIcon,
  ClockIcon,
  LocationIcon,
} from '@cg/module-frontend/src/icons';
import React from 'react';
import { useAddressString } from '@cg/module-frontend/src/hooks/address.hooks.ts';
import { AddressOrCoordinate } from '~/generated/models/AddressOrCoordinate';
import { Experience } from '~/generated/models/Experience';

type LocationDetailsProps = {
  experience: Experience;
  isSecret: boolean;
  address: AddressOrCoordinate;
  className?: string;
};

const MapAndTime = React.memo(
  ({ experience, address, isSecret, className }: LocationDetailsProps) => {
    if (!address && !isSecret) {
      return '';
    }
    const { isMobile } = useScreenSize();
    const addressStr = useAddressString(isSecret, address);
    const isApple = isAppleOS();
    const appleMapsUrl = `https://maps.apple.com/?q=${encodeURIComponent(addressStr)}`;
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(addressStr)}`;
    const androidUrl = `geo:0,0?q=${encodeURIComponent(addressStr)}`;

    const getMapLink = () => {
      if (isSecret) {
        return <span>{addressStr}</span>;
      }

      if (!isMobile) {
        return <Href to={googleMapsUrl}>{addressStr}</Href>;
      }

      if (!isApple) {
        return <Href to={androidUrl}>{addressStr}</Href>;
      }

      return (
        <Dropdown
          renderTrigger={() => {
            return (
              <span className="cursor-pointer underline text-primary">
                {addressStr}
              </span>
            );
          }}
          label="asd"
          inline
          arrowIcon={false}
        >
          <Dropdown.Item
            as={Link}
            to={googleMapsUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Maps
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            to={appleMapsUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Apple Maps
          </Dropdown.Item>
        </Dropdown>
      );
    };

    return (
      <div className={tw('bg-grey rounded', className)}>
        <Map
          latitude={address.latitude}
          secret={isSecret}
          longitude={address.longitude}
          address={address}
        />
        <div className="mt-4 p-4">
          <div className="flex items-center gap-2">
            <LocationIcon className="text-primary size-6" />
            <span>Address:</span>
          </div>

          <p className="border-b border-secondary ml-8 pb-2">{getMapLink()}</p>

          <div>
            <div className="flex items-center justify-between py-2 border-b border-secondary">
              <div className="flex items-center gap-2">
                <CalendarIcon className="stroke-primary text-primary size-6" />
                <span>Date:</span>
              </div>
              {dater(experience.startDate).format('MMMM DD, YYYY')}
            </div>
            <div className="flex items-center justify-between py-2 border-b border-secondary">
              <div className="flex items-center gap-2">
                <ClockIcon className="stroke-primary text-primary size-6" />
                <span>Start Time:</span>
              </div>
              {dater(experience.startDate).format('hh:mm A')}
            </div>
            <div className="flex items-center justify-between py-2">
              <div className="flex items-center gap-2">
                <ClockIcon className="stroke-primary text-primary size-6" />
                <span>End Time:</span>
              </div>
              {dater(experience.endDate).format('hh:mm A')}
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default MapAndTime;
