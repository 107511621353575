import * as ReactDOM from 'react-dom/client';
import { AuthProvider } from '@cg/module-frontend';
import { PostHogProvider } from '@cg/module-frontend/src/analytics';
import { App } from './app';

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Failed to find the root element.');
}

// @ts-ignore
// eslint-disable-next-line no-underscore-dangle
window.__app = {
  version: import.meta.env.VITE_APP_VERSION,
  hash: import.meta.env.VITE_APP_HASH,
  message: `Psst! wanna work alongside us to build the next evolution of social friendship? If so, send us an email at kousha@joincommonground.com with the subject [I ❤️ CG ${
    import.meta.env.VITE_APP_HASH
  }].`,
};

const renderer = ReactDOM.createRoot(rootElement);
renderer.render(
  <AuthProvider>
    <PostHogProvider>
      <App />
    </PostHogProvider>
  </AuthProvider>,
);
