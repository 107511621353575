import React from 'react';
import { Loader, Section } from '@cg/module-frontend/src/components';
import { ExperiencesList } from '~/components/experiences';

type ResultProps = {
  loading: boolean;
  children: React.ReactElement | React.ReactElement[];
};

function Result({ children, loading }: ResultProps) {
  return (
    <Loader loading={loading} horizontal>
      <>
        <Section className="flex flex-col w-full items-center gap-y-10 mt-20">
          {children}
        </Section>
        <ExperiencesList />
      </>
    </Loader>
  );
}

export default Result;
