import { UIMatch, useMatches as RRMathces } from 'react-router';

export function useMatches<Data = unknown, Handle = unknown>() {
  const matches = RRMathces() as UIMatch<Data, Handle>[];

  return matches.filter((m) => !m.pathname.endsWith('/'));
}

export const useNormalizePathName = (pathname: string) => {
  return pathname.replace(
    /^(\/(?:e|h|u)\/)([^/]+)(\/?.*)$/,
    (match, prefix, _, rest) => {
      if (prefix.startsWith('/e/')) {
        return `${prefix}{eId}${rest}`;
      }
      if (prefix.startsWith('/h/')) {
        return `${prefix}{hId}${rest}`;
      }
      if (prefix.startsWith('/u/')) {
        return `${prefix}{uId}${rest}`;
      }
      return match;
    },
  );
};
