import { Host } from '~/generated/models/Host';
import { tw } from '../../utils';
import Img from '../img';

type HostLabelProps = {
  host: Host;
  className?: string;
};

function HostLabel({ host, className }: HostLabelProps) {
  return (
    <div
      className={tw(
        'flex justify-between rounded bg-grey',
        'pr-1 pl-3 py-1 space-x-4',
        className,
      )}
    >
      <div className="flex space-x-1 items-center">
        <span className="font-medium truncate max-w-full overflow-hidden whitespace-nowrap">
          {host?.friendlyName}
        </span>
      </div>
      {host?.imageUrl && (
        <Img
          className="size-8 rounded-full object-cover"
          src={host?.imageUrl}
          alt="user"
        />
      )}
    </div>
  );
}

export default HostLabel;
