import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useAuth } from '@cg/module-frontend';
import { featureFlagConstants } from '@cg/common/src/constants';
import { useMatches, useScreenSize } from '@cg/module-frontend/src/hooks';
import { Host } from '~/generated/models/Host.ts';
import { useHost } from '~/app/context/host';

type Nav = {
  title: string;
  to: string;
};
const makeNavs = (host: Host | null, communityEnabled: boolean): Nav[] => {
  const navs: Nav[] = [
    {
      title: 'Home',
      to: `/h/${host?.uniqueName}`,
    },
    {
      title: 'Experiences',
      to: `/h/${host?.uniqueName}/experiences`,
    },
  ];

  if (communityEnabled) {
    navs.push(
      {
        title: 'About',
        to: `/h/${host?.uniqueName}/about`,
      },
      {
        title: 'Members',
        to: `/h/${host?.uniqueName}/members`,
      },
    );
  }

  return navs;
};

export default function HostNav() {
  const { isSmall } = useScreenSize();
  const { host } = useHost();
  const { hasFlag } = useAuth();
  const communityEnabled = hasFlag(featureFlagConstants.HOST_COMMUNITY);
  const matches = useMatches();

  const navs = makeNavs(host, communityEnabled).map((route) => {
    const isActive = matches[matches.length - 1].pathname === route.to;
    return (
      <Link to={route.to} key={route.title}>
        <h5
          className={classNames(
            'hover:text-primary hover:md:border-b-primary px-1 py-3',
            {
              'border-2 border-b-primary border-x-transparent border-t-transparent text-primary':
                isActive,
            },
          )}
        >
          {route.title}
        </h5>
      </Link>
    );
  });

  if (isSmall) {
    return (
      <div className="relative mb-16 flex flex-row gap-x-8 w-full shadow px-10 items-center justify-center overflow-hidden">
        {navs}
      </div>
    );
  }

  return (
    <div className="absolute bottom-[-1.5rem] flex flex-row gap-x-8 rounded-full bg-grey px-6 items-center justify-center w-fit">
      {navs}
    </div>
  );
}
