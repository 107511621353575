/* eslint-disable */


import { http, HttpResult } from '@cg/module-frontend/src/core/http';

import { CheckoutRequest } from '../../../models/CheckoutRequest';
import { CheckoutRedirect } from '../../../models/CheckoutRedirect';
import { ExperienceId } from '@cg/common/src/ids/ExperienceId';
import { Experience } from '../../../models/Experience';
import { AddressOrCoordinate } from '../../../models/AddressOrCoordinate';
import { ExperienceConfirmation } from '../../../models/ExperienceConfirmation';
import { GetBatchExperienceRequest } from '../../../models/GetBatchExperienceRequest';
import { DetailedExperience } from '../../../models/DetailedExperience';
import { ExperienceHost } from '../../../models/ExperienceHost';
import { ExperienceMedia } from '../../../models/ExperienceMedia';
import { Page } from '@cg/common/src/paging/Page';
import { ExperienceType } from '../../../models/ExperienceType';
import { Question } from '../../../models/Question';
import { DetailedExperienceSeries } from '../../../models/DetailedExperienceSeries';
import { ExperienceSeries } from '../../../models/ExperienceSeries';
import { ExperienceTicketTiersResponse } from '../../../models/ExperienceTicketTiersResponse';
import { TicketTier } from '../../../models/TicketTier';

export type CheckoutArgs = {
    ids: {
        experienceId: ExperienceId,
    },
    body: CheckoutRequest[],
}

/**
 * @summary Performs the ticket checkout process
 * @param {CheckoutArgs} args
 */
export const checkout = (args: CheckoutArgs): Promise<HttpResult<CheckoutRedirect>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/public/experiences/{experienceId}/checkout', params);

    return http.post(url, {
        body: args.body,
    });
}
export type FetchExperienceArgs = {
    ids: {
        experienceId: string,
    },
}

/**
 * <p>API endpoints for fetching an Experience.</p>
 * @summary Returns the Experience fetched by Id
 * @param {FetchExperienceArgs} args
 */
export const fetchExperience = (args: FetchExperienceArgs): Promise<HttpResult<Experience>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/public/experiences/{experienceId}', params);

    return http.get(url, {
    });
}
export type FetchExperienceAddressArgs = {
    ids: {
        experienceId: ExperienceId,
    },
}

/**
 * <p>API endpoints for getting the Address of an Experience.</p>
 * @summary Returns the Address of the given Experience.
 * @param {FetchExperienceAddressArgs} args
 */
export const fetchExperienceAddress = (args: FetchExperienceAddressArgs): Promise<HttpResult<AddressOrCoordinate>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/public/experiences/{experienceId}/address', params);

    return http.get(url, {
    });
}
export type FetchFailureConfirmationArgs = {
    ids: {
        experienceId: ExperienceId,
    },
    filters?: {
        userId?: string,
    },
}

/**
 * <p>API endpoints for failure of an Experience.</p>
 * @summary Endpoint for returning the failure of an Experience.
 * @param {FetchFailureConfirmationArgs} args
 */
export const fetchFailureConfirmation = (args: FetchFailureConfirmationArgs): Promise<HttpResult<ExperienceConfirmation>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/public/experiences/{experienceId}/confirmations/failure', params);

    return http.get(url, {
    });
}
export type FetchSuccessConfirmationArgs = {
    ids: {
        experienceId: ExperienceId,
    },
    filters?: {
        userId?: string,
    },
}

/**
 * <p>API endpoints for success of an Experience.</p>
 * @summary Endpoint for returning the Confirmation of an Experience.
 * @param {FetchSuccessConfirmationArgs} args
 */
export const fetchSuccessConfirmation = (args: FetchSuccessConfirmationArgs): Promise<HttpResult<ExperienceConfirmation>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/public/experiences/{experienceId}/confirmations/success', params);

    return http.get(url, {
    });
}
export type ListBatchExperiencesArgs = {
    body: GetBatchExperienceRequest,
}

/**
 * <p>The list of requested Experiences</p>
 * @summary Returns a batch list of Experiences
 * @param {ListBatchExperiencesArgs} args
 */
export const listBatchExperiences = (args: ListBatchExperiencesArgs): Promise<HttpResult<DetailedExperience[]>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/public/experiences/batch', params);

    return http.post(url, {
        body: args.body,
    });
}
export type ListExperienceMediasArgs = {
    ids: {
        experienceId: ExperienceId,
    },
}

/**
 * <p>API endpoints for Media of an Experience.</p>
 * @summary Returns the list of Questions.
 * @param {ListExperienceMediasArgs} args
 */
export const listExperienceMedias = (args: ListExperienceMediasArgs): Promise<HttpResult<ExperienceMedia[]>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/public/experiences/{experienceId}/medias', params);

    return http.get(url, {
    });
}
export type ListExperiencesArgs = {
    filters?: {
        previousOnly?: boolean,
        experienceType?: ExperienceType,
        pageSize?: number,
        pageToken?: string,
    },
    cacheCallback?: (args: ListExperiencesArgs) => {
        key?: string,
        
        ttl?: number,
    },
}

/**
 * <p>Returns a paginated list of Experiences that matches the User&#39;s preferences.</p>
 * @summary Endpoint for returning a list of Experiences
 * @param {ListExperiencesArgs} args
 */
export const listExperiences = (args: ListExperiencesArgs): Promise<HttpResult<Page<DetailedExperience>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/public/experiences', params);
    const cache = {
      key: 'listExperiences',
      
      ttl: 10000,
      ...args?.cacheCallback?.(args),
    };

    return http.get(url, {
      cache,
    });
}
export type ListQuestionsArgs = {
    ids: {
        experienceId: ExperienceId,
    },
    filters?: {
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * <p>API endpoints for Questions of an Experience.</p>
 * @summary Returns the list of Questions.
 * @param {ListQuestionsArgs} args
 */
export const listQuestions = (args: ListQuestionsArgs): Promise<HttpResult<Page<Question>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/public/experiences/{experienceId}/questions', params);

    return http.get(url, {
    });
}
export type ListSeriesArgs = {
    filters?: {
        experienceType?: ExperienceType,
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * <p>API endpoints for fetching Series information.</p>
 * @summary Endpoint for returning the list of Series
 * @param {ListSeriesArgs} args
 */
export const listSeries = (args: ListSeriesArgs): Promise<HttpResult<Page<DetailedExperienceSeries>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/public/series', params);

    return http.get(url, {
    });
}
export type ListTicketTiersArgs = {
    ids: {
        experienceId: ExperienceId,
    },
    filters?: {
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * <p>API endpoints for Ticket Tiers of an Experience.</p>
 * @summary Returns the list of Ticket Tiers for the given Experience.
 * @param {ListTicketTiersArgs} args
 */
export const listTicketTiers = (args: ListTicketTiersArgs): Promise<HttpResult<Page<ExperienceTicketTiersResponse>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/public/experiences/{experienceId}/ticket-tiers', params);

    return http.get(url, {
    });
}
