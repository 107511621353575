import HostExperienceList from './components/HostExperienceList';
import { Host } from '~/generated/models/Host';

type Props = {
  host: Host;
};

export default function HostExperiencesPage({ host }: Props) {
  return (
    <div className="rounded-xl bg-grey flex flex-col items-center justify-center p-4 py-8 gap-y-4">
      <h3 className="text-primary">Upcoming Experiences</h3>
      <HostExperienceList host={host} />
      <h3 className="text-lg">Past Experiences</h3>
      <HostExperienceList host={host} previous />
    </div>
  );
}
