/* eslint-disable */
import { StrictBuilder } from 'builder-pattern';




/**
 * <p>The availability of the Experience</p>
 */
export enum ExperienceAvailability {
	Available = 'Available',
	SoldOut = 'SoldOut'
}

