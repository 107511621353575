import React, { useEffect, useMemo, useState } from 'react';
import { TicketId } from '@cg/common/src/ids';
import classNames from 'classnames';
import { useNavigate } from 'react-router';
import { useAuth } from '@cg/module-frontend';
import {
  Content,
  Page,
  Section,
  Loader,
} from '@cg/module-frontend/src/components';
import {
  StepOneSelectTiers,
  StepThreeCheckout,
  StepTwoAssignUsers,
} from './steps';
import { listTicketTiersHook } from '~/generated/clients/public/experiences/PublicExperiences.hooks';
import {
  CheckoutContext,
  CheckoutContextType,
  InternalTicket,
  Step,
} from './context/CheckoutContext';
import { ExperienceTicketTiersResponse } from '~/generated/models/ExperienceTicketTiersResponse';
import { Breadcrumb } from './components/Breadcrumb';
import { UserCheckoutRequest } from '~/generated/models/UserCheckoutRequest';
import { useExperience } from '~/app/context/experience';

function Checkout() {
  const { self, isLogged } = useAuth();
  const [validation, setValidation] = useState<{
    [step in Step]: boolean;
  }>({ 1: false, 2: false, 3: false });

  const [step, setStep] = useState<Step>(Step.Step1);
  const navigate = useNavigate();

  const { experience, isPast, identifier, purchasedTickets } = useExperience();

  const {
    data: tiers,
    call: getTiers,
    calling: gettingTiers,
  } = listTicketTiersHook(false);

  useEffect(() => {
    if (!experience) {
      return;
    }

    if (isPast) {
      navigate({
        pathname: `/e/${identifier}`,
      });
      return;
    }

    getTiers({ ids: { experienceId: experience.id } });
  }, [experience]);

  const [tickets, setTickets] = useState<InternalTicket[]>([]);

  // Adds a ticket
  const addTicket = (tier: ExperienceTicketTiersResponse) => {
    setTickets([
      ...tickets,
      {
        fakeId: new TicketId(),
        ticketTierId: tier.id,
        user: {
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
        },
        answers: [],
      },
    ]);
  };

  // Removes a ticket
  const removeTicket = (tier: ExperienceTicketTiersResponse) => {
    const index = tickets.findIndex((t) => t.ticketTierId.isEqual(tier.id));
    if (index === -1) {
      return;
    }

    const newTickets = [...tickets];
    newTickets.splice(index, 1);
    setTickets(newTickets);
  };

  // Adds a user
  const addUser = (id: TicketId, user: UserCheckoutRequest) => {
    const index = tickets.findIndex((t) => t.fakeId.isEqual(id));
    if (index === -1) {
      return;
    }

    const newTickets = [...tickets];
    newTickets[index].user = user;
    setTickets(newTickets);
  };

  const stepValidation = (s: Step, validated: boolean) => {
    setValidation({ ...validation, [s]: validated });
  };

  // Create context to send over
  const context = useMemo<CheckoutContextType>(
    () => ({
      tiers: tiers?.result || [],
      addUser,
      addTicket,
      removeTicket,
      tickets,
      experience,
      stepValidation,
    }),
    [tiers, tickets],
  );

  const filled =
    tickets.map((t) => t.user.email).filter(Boolean).length === tickets.length;
  const [hasAssignedSelf, setAssignedSelf] = useState(
    tickets.map((t) => t.user.email).includes(self?.user?.email || ''),
  );

  useEffect(() => {
    if (!purchasedTickets) return;
    setAssignedSelf(
      tickets.map((t) => t.user.email).includes(self?.user?.email || '') ||
        purchasedTickets.some(
          (ticket) => ticket.user.email === self?.user?.email,
        ),
    );
  }, [purchasedTickets, tickets]);

  return (
    <CheckoutContext.Provider value={context}>
      <Page>
        <Section>
          <Content>
            <Loader horizontal loading={gettingTiers}>
              <div className="min-h-screen pt-1">
                <Breadcrumb step={step} setStep={setStep} />
                {step === Step.Step1 && <StepOneSelectTiers />}
                {step === Step.Step2 && <StepTwoAssignUsers />}
                {step === Step.Step3 && (
                  <StepThreeCheckout goBack={() => setStep(2)} />
                )}
                {step < Step.Step3 && (
                  <>
                    {filled &&
                      isLogged &&
                      !hasAssignedSelf &&
                      step === Step.Step2 && (
                        <div className="text-failure flex justify-center">
                          You need a ticket too! Assign one to your Common
                          Ground email.
                        </div>
                      )}
                    <button
                      type="button"
                      className={classNames(
                        'w-full bg-primary text-white py-3 text-xl font-semibold rounded mt-6',
                        {
                          'opacity-50': !validation[step],
                        },
                      )}
                      disabled={!validation[step]}
                      onClick={() => setStep(step + 1)}
                    >
                      {step === 1 && 'Next'}
                      {step === 2 && 'Checkout'}
                    </button>
                  </>
                )}
              </div>
            </Loader>
          </Content>
        </Section>
      </Page>
    </CheckoutContext.Provider>
  );
}

export default Checkout;
