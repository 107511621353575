/* eslint-disable */


import { http, HttpResult } from '@cg/module-frontend/src/core/http';

import { Experience } from '../../../models/Experience';
import { HostId } from '@cg/common/src/ids/HostId';
import { ExperienceId } from '@cg/common/src/ids/ExperienceId';
import { Host } from '../../../models/Host';
import { BasicUser } from '../../../models/BasicUser';
import { Page } from '@cg/common/src/paging/Page';
import { DetailedExperience } from '../../../models/DetailedExperience';
import { ExperienceHost } from '../../../models/ExperienceHost';
import { ExperienceType } from '../../../models/ExperienceType';
import { HostUserProfile } from '../../../models/HostUserProfile';
import { DetailedExperienceSeries } from '../../../models/DetailedExperienceSeries';
import { ExperienceSeries } from '../../../models/ExperienceSeries';

export type FetchExperienceArgs = {
    ids: {
        hostId: HostId,
        experienceId: ExperienceId,
    },
}

/**
 * <p>API endpoints for fetching an Experience organized by a Host.</p>
 * @summary Endpoint for fetching an Experience by id organized by the Host.
 * @param {FetchExperienceArgs} args
 */
export const fetchExperience = (args: FetchExperienceArgs): Promise<HttpResult<Experience>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/public/hosts/{hostId}/experiences/{experienceId}', params);

    return http.get(url, {
    });
}
export type FetchHostArgs = {
    ids: {
        hostId: HostId,
    },
}

/**
 * <p>API endpoints for fetching Hosts information that are public facing.</p>
 * @summary Endpoint for returning a Host by Id
 * @param {FetchHostArgs} args
 */
export const fetchHost = (args: FetchHostArgs): Promise<HttpResult<Host>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/public/hosts/{hostId}', params);

    return http.get(url, {
    });
}
export type FetchHostByUniqueNameArgs = {
    ids: {
        hostId: string,
    },
}

/**
 * <p>API endpoints for fetching Hosts information that are public facing using unique name.</p>
 * @summary Endpoint for returning a Host by unique name
 * @param {FetchHostByUniqueNameArgs} args
 */
export const fetchHostByUniqueName = (args: FetchHostByUniqueNameArgs): Promise<HttpResult<Host>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/public/hosts/unique/{hostId}', params);

    return http.get(url, {
    });
}
export type ListCommunityHostArgs = {
    ids: {
        hostId: HostId,
    },
}

/**
 * <p>API endpoints for fetching Hosts community members.</p>
 * @summary Endpoint for returning list of users that are members of the host's community.
 * @param {ListCommunityHostArgs} args
 */
export const listCommunityHost = (args: ListCommunityHostArgs): Promise<HttpResult<BasicUser[]>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/public/hosts/{hostId}/members', params);

    return http.get(url, {
    });
}
export type ListExperiencesArgs = {
    ids: {
        hostId: HostId,
    },
    filters?: {
        previousOnly?: boolean,
        experienceType?: ExperienceType,
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * <p>API endpoints for fetching Hosts organized by a Host.</p>
 * @summary Endpoint for returning list of Experiences organized by the Host.
 * @param {ListExperiencesArgs} args
 */
export const listExperiences = (args: ListExperiencesArgs): Promise<HttpResult<Page<DetailedExperience>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/public/hosts/{hostId}/experiences', params);

    return http.get(url, {
    });
}
export type ListHostsArgs = {
    filters?: {
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * <p>API endpoints for fetching Hosts information that are public facing.</p>
 * @summary Endpoint for returning the list of Hosts
 */
export const listHosts = (args: ListHostsArgs): Promise<HttpResult<Page<Host>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/public/hosts', params);

    return http.get(url, {
    });
}
export type ListHostUsersArgs = {
    ids: {
        hostId: HostId,
    },
    filters?: {
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * <p>API endpoints for fetching Hosts users.</p>
 * @summary Endpoint for returning list of users that belong to the Host.
 * @param {ListHostUsersArgs} args
 */
export const listHostUsers = (args: ListHostUsersArgs): Promise<HttpResult<Page<HostUserProfile>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/public/hosts/{hostId}/users', params);

    return http.get(url, {
    });
}
export type ListSeriesArgs = {
    ids: {
        hostId: HostId,
    },
    filters?: {
        experienceType?: ExperienceType,
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * <p>API endpoints for fetching Hosts organized by a Host.</p>
 * @summary Endpoint for returning list of Experiences Series organized by the Host.
 * @param {ListSeriesArgs} args
 */
export const listSeries = (args: ListSeriesArgs): Promise<HttpResult<Page<DetailedExperienceSeries>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/public/hosts/{hostId}/series', params);

    return http.get(url, {
    });
}
