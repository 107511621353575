/* eslint-disable */


import { useHttp } from '@cg/module-frontend/src/core/http';

import { rsvpCalendar, RsvpCalendarArgs, welcome,  } from './Public.client';

export const rsvpCalendarHook = (immediateCall: boolean = true, initArgs?: RsvpCalendarArgs) => {
  return useHttp(rsvpCalendar, { immediateCall, initArgs })
};
export const welcomeHook = (immediateCall: boolean = true, ) => {
  return useHttp(welcome, { immediateCall })
};
