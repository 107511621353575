/* eslint-disable */

import { useHttp } from '~/core/http';


import { addHostUser, AddHostUserArgs, changeHostOwner, ChangeHostOwnerArgs, createHost, CreateHostArgs, fetchHost, FetchHostArgs, getPaymentDetail, GetPaymentDetailArgs, listHostExperiences, ListHostExperiencesArgs, listHosts, ListHostsArgs, listHostUsers, ListHostUsersArgs, listWaitlistedUsers, removeHostUser, RemoveHostUserArgs, updateHostSetting, UpdateHostSettingArgs, updateHostUser, UpdateHostUserArgs, uploadPhoto, UploadPhotoArgs } from './BackgroundHosts.client';

export const addHostUserHook = (immediateCall: boolean = true, initArgs?: AddHostUserArgs) => {
  return useHttp(addHostUser, { immediateCall, initArgs })
};
export const changeHostOwnerHook = (immediateCall: boolean = true, initArgs?: ChangeHostOwnerArgs) => {
  return useHttp(changeHostOwner, { immediateCall, initArgs })
};
export const createHostHook = (immediateCall: boolean = true, initArgs?: CreateHostArgs) => {
  return useHttp(createHost, { immediateCall, initArgs })
};
export const fetchHostHook = (immediateCall: boolean = true, initArgs?: FetchHostArgs) => {
  return useHttp(fetchHost, { immediateCall, initArgs })
};
export const getPaymentDetailHook = (immediateCall: boolean = true, initArgs?: GetPaymentDetailArgs) => {
  return useHttp(getPaymentDetail, { immediateCall, initArgs })
};
export const listHostExperiencesHook = (immediateCall: boolean = true, initArgs?: ListHostExperiencesArgs) => {
  return useHttp(listHostExperiences, { immediateCall, initArgs })
};
export const listHostsHook = (immediateCall: boolean = true, initArgs?: ListHostsArgs) => {
  return useHttp(listHosts, { immediateCall, initArgs })
};
export const listHostUsersHook = (immediateCall: boolean = true, initArgs?: ListHostUsersArgs) => {
  return useHttp(listHostUsers, { immediateCall, initArgs })
};
export const listWaitlistedUsersHook = (immediateCall: boolean = true, ) => {
  return useHttp(listWaitlistedUsers, { immediateCall })
};
export const removeHostUserHook = (immediateCall: boolean = true, initArgs?: RemoveHostUserArgs) => {
  return useHttp(removeHostUser, { immediateCall, initArgs })
};
export const updateHostSettingHook = (immediateCall: boolean = true, initArgs?: UpdateHostSettingArgs) => {
  return useHttp(updateHostSetting, { immediateCall, initArgs })
};
export const updateHostUserHook = (immediateCall: boolean = true, initArgs?: UpdateHostUserArgs) => {
  return useHttp(updateHostUser, { immediateCall, initArgs })
};
export const uploadPhotoHook = (immediateCall: boolean = true, initArgs?: UploadPhotoArgs) => {
  return useHttp(uploadPhoto, { immediateCall, initArgs })
};
