/* eslint-disable */
import { StrictBuilder } from 'builder-pattern';




/**
 * <p>The type of payment</p>
 */
export enum PaymentDetailMethod {
	Wise = 'Wise',
	Interac = 'Interac'
}

