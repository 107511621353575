import { Outlet } from 'react-router-dom';
import {
  Content,
  DesktopScreen,
  Img,
  MobileScreen,
  Page,
} from '@cg/module-frontend/src/components';
import classNames from 'classnames';
import { useScreenSize } from '@cg/module-frontend/src/hooks';
import React from 'react';
import { DEFAULT_BANNER_URL } from '@cg/module-frontend/src/constant.ts';
import { useHostSocials } from '@cg/module-frontend/src/components/host/hooks.tsx';
import { useHost } from '~/app/context/host';
import HostNav from './HostNav';

export default function HostTop() {
  const { host } = useHost();
  const { isMobile } = useScreenSize();
  const bannerUrl = host?.bannerUrl ?? DEFAULT_BANNER_URL;

  const socials = useHostSocials(
    host,
    host?.bannerUrl ? 'text-white' : 'text-primary',
  );

  return (
    <Page>
      <Content className="relative" hideGetStarted>
        <DesktopScreen>
          <div className="absolute top-4 right-0 flex space-x-2 z-10">
            {socials}
          </div>
        </DesktopScreen>
      </Content>
      <div
        className={classNames(
          'relative items-center justify-center flex flex-col z-0',
          {
            'mb-16': !isMobile,
          },
        )}
      >
        <div
          className={classNames('relative w-full', {
            'aspect-w-4 aspect-h-3': isMobile,
            'aspect-w-5 aspect-h-2': !isMobile,
          })}
        >
          <Img
            className={classNames(
              'absolute inset-0 w-full h-full object-cover',
            )}
            src={bannerUrl}
            alt={host?.friendlyName}
          />
        </div>
        <div
          className={classNames('absolute', {
            'bottom-0 left-0 right-0 h-40 bg-gradient-to-t from-primary to-transparent opacity-50':
              host?.bannerUrl,
            'inset-0 w-full h-full object-cover bg-gradient-to-b from-secondary to-primary-darker opacity-30':
              !host?.bannerUrl,
          })}
        />
        <div
          className={classNames(
            'absolute flex flex-col items-center justify-center gap-y-2',
            {
              'bottom-8': isMobile,
              'bottom-20': !isMobile,
            },
          )}
        >
          <h3 className="text-white">Welcome to</h3>
          <h1 className="text-white text-center">{host?.friendlyName}</h1>
          <MobileScreen>
            <div className="flex space-x-2 z-10">{socials}</div>
          </MobileScreen>
        </div>
        <DesktopScreen>
          <HostNav />
        </DesktopScreen>
      </div>
      <MobileScreen>
        <HostNav />
      </MobileScreen>

      <Content className="relative">
        <Outlet />
      </Content>
    </Page>
  );
}
