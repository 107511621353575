import React from 'react';
import classNames from 'classnames';
import { ChevronRightIcon } from '@cg/module-frontend/src/icons';

type BreadcrumbProps = {
  step: number;
  setStep: (step: number) => void;
};

type CrumbProps = {
  currentStep: number;
  step: number;
  title: string;
  setStep: (step: number) => void;
};

function Crumb({ currentStep, step, title, setStep }: CrumbProps) {
  return (
    <button
      className={classNames('text-center w-1/3', {
        'text-primary': currentStep >= step,
        'font-bold': currentStep === step,
      })}
      disabled={currentStep <= step}
      type="button"
      onClick={() => {
        if (currentStep > step) {
          setStep(step);
        }
      }}
    >
      {title}
    </button>
  );
}

export function Breadcrumb({ step, setStep }: BreadcrumbProps) {
  return (
    <div className="flex bg-white py-3 px-4">
      <Crumb currentStep={step} step={1} title="Select" setStep={setStep} />
      <ChevronRightIcon className="size-4" />
      <Crumb currentStep={step} step={2} title="Assign" setStep={setStep} />
      <ChevronRightIcon className="size-4" />
      <Crumb currentStep={step} step={3} title="Checkout" setStep={setStep} />
    </div>
  );
}
