/* eslint-disable */


import { useHttp } from '@cg/module-frontend/src/core/http';

import { listFamiliarFacesByHost, ListFamiliarFacesByHostArgs } from './PrivateHosts.client';

export const listFamiliarFacesByHostHook = (immediateCall: boolean = true, initArgs?: ListFamiliarFacesByHostArgs) => {
  return useHttp(listFamiliarFacesByHost, { immediateCall, initArgs })
};
