import { PostHog } from 'posthog-js/react';
import { Properties } from 'posthog-js';
import { useNormalizePathName } from '../../hooks/routes.hooks.ts';

export const handleOnClick = (
  posthog: PostHog,
  properties: Properties,
  isMobile: boolean,
) => {
  posthog.capture('$pageClick', {
    ...properties,
    $$pageName: useNormalizePathName(window.location.pathname),
    $$currentUrl: window.location.href,
    $$pathname: window.location.pathname,
    $$isMobile: isMobile,
  });
};
