import React from 'react';
import { UserWithSharedExperience } from '~/generated/models/UserWithSharedExperience';
import Faces from '~/components/FamiliarFaces/Faces';

type FamiliarFacesProps = {
  users: UserWithSharedExperience[];
};

export default function FamiliarFaces({ users }: FamiliarFacesProps) {
  return (
    <div className="bg-grey flex flex-col items-center justify-center rounded">
      <h3 className="text-center p-4">Familiar Faces</h3>
      <div className="flex w-full">
        <Faces users={users} />
      </div>
    </div>
  );
}
