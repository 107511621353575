/* eslint-disable */
import { StrictBuilder } from 'builder-pattern';




/**
 * <p>The type of media</p>
 */
export enum ExperienceMediaType {
	Image = 'Image'
}

