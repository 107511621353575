import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import * as DateUtils from '@cg/common/src/utils/DateUtils';
import { useAuth } from '@cg/module-frontend';
import {
  joinWaitlistHook,
  listTicketsHook,
  selfExperienceDetailsHook,
  toggleExperienceInterestHook,
} from '~/generated/clients/private/experiences/PrivateExperiences.hooks';
import {
  fetchExperienceHook,
  listTicketTiersHook,
} from '~/generated/clients/public/experiences/PublicExperiences.hooks';
import { ExperienceTicketTiersResponse } from '~/generated/models/ExperienceTicketTiersResponse';
import { ExperienceContext, ExperienceContextType } from './ExperienceContext';

type UseParams = {
  identifier: string;
};

type Prop = {
  children: ReactNode;
};

export function ExperienceContextProvider({ children }: Prop) {
  const navigate = useNavigate();
  const { self, login, isLogged } = useAuth();
  const firstUpdate = useRef(true);
  const { identifier } = useParams<UseParams>() as UseParams;
  const fetchExperienceArg = { ids: { experienceId: identifier } };

  const [availableTickets, setAvailable] = useState(0);

  const {
    calling: fetchingExperience,
    data: experience,
    call: fetchExperience,
  } = fetchExperienceHook(true, fetchExperienceArg);
  const {
    data: ticketTiers,
    call: getTicketTiers,
    calling: gettingTiers,
  } = listTicketTiersHook(false);
  const {
    data: selfExperienceDetails,
    call: getSelfExperienceDetails,
    calling: gettingSelfExperienceDetails,
  } = selfExperienceDetailsHook(false);

  const { data: purchasedTickets, call: getPurchasedTickets } =
    listTicketsHook(false);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    fetchExperience(fetchExperienceArg);
  }, [identifier]);

  useEffect(() => {
    if (gettingSelfExperienceDetails || !experience || selfExperienceDetails) {
      return;
    }

    getSelfExperienceDetails({ ids: { experienceId: experience.id } });
    getPurchasedTickets({ ids: { experienceId: experience.id } });
  }, [experience, gettingSelfExperienceDetails, selfExperienceDetails]);

  useEffect(() => {
    if (!experience) {
      return;
    }

    if (!gettingTiers) {
      getTicketTiers({ ids: { experienceId: experience.id } });
    }
  }, [experience]);

  useEffect(() => {
    if (ticketTiers) {
      ticketTiers.result.sort(
        (
          t1: ExperienceTicketTiersResponse,
          t2: ExperienceTicketTiersResponse,
        ) =>
          t1.available > 0 && t2.available > 0 && t1.price < t2.price ? 1 : 0,
      );
      const available = ticketTiers.result.reduce(
        (count: number, ticket: ExperienceTicketTiersResponse) =>
          count + ticket.available,
        0,
      );

      setAvailable(available);
    }
  }, [ticketTiers]);

  const {
    error: waitlistError,
    call: doJoinWaitlist,
    calling: joiningWaitlist,
  } = joinWaitlistHook(false);
  const [waitlistRequested, setWaitlistRequested] = useState(false);
  useEffect(() => {
    if (joiningWaitlist || waitlistError || !waitlistRequested) {
      return;
    }

    window.location.href = `/e/${identifier}/waitlisted`;
  }, [joiningWaitlist, waitlistError]);
  const [showWaitlistModal, setShowWaitlistModal] = useState(false);
  const joinWaitlist = async () => {
    if (!isLogged && !showWaitlistModal) {
      setShowWaitlistModal(true);
      return;
    }

    if (!waitlistRequested) {
      setWaitlistRequested(true);
    }

    if (!self?.user) {
      login();
      return;
    }
    if (!experience) {
      return;
    }

    await doJoinWaitlist({
      ids: { experienceId: experience.id },
      body: {
        userId: self.user.id,
      },
    });
  };

  const { call: toggleExperienceInterest, calling: togglingInterested } =
    toggleExperienceInterestHook(false);
  const [interested, setInterested] = useState(false);
  useEffect(() => {
    if (selfExperienceDetails) {
      setInterested(selfExperienceDetails.isInterested);
    }
  }, [selfExperienceDetails]);
  const toggleInterested = async () => {
    if (!self?.user) {
      login();
      return;
    }
    if (!experience) {
      return;
    }

    await toggleExperienceInterest({
      ids: { experienceId: experience.id },
    });
    setInterested(!interested);
  };

  const saleEndDate = useMemo(() => {
    return experience?.saleEndDate
      ? DateUtils.dater(experience.saleEndDate).toDate()
      : DateUtils.dater(experience?.startDate).toDate();
  }, [experience]);

  const saleEnded = useMemo(
    () => DateUtils.dater().isAfter(saleEndDate),
    [saleEndDate],
  );

  const isPast = useMemo(
    () =>
      experience?.startDate ? DateUtils.isPast(experience.startDate) : false,
    [experience],
  );

  const [showLoginModal, setShowLoginModal] = useState(false);
  const checkout = () => {
    if (isLogged || showLoginModal) {
      setShowLoginModal(false);
      navigate(`/e/${identifier}/checkout`);
      return;
    }
    setShowLoginModal(true);
  };
  const cancelCheckout = () => {
    setShowLoginModal(false);
  };
  const cancelWaitlist = () => {
    setShowWaitlistModal(false);
  };

  // Create context to send over
  const context = useMemo<ExperienceContextType>(
    () => ({
      tiers: ticketTiers?.result || [],
      experience,
      isPast,
      saleEnded,
      saleEndDate,
      identifier,
      joinWaitlist,
      toggleInterested,
      showWaitlistModal,
      cancelWaitlist,
      togglingInterested,
      waitlisted: selfExperienceDetails?.isWaitlisted || false,
      interested,
      availableTickets,
      fetchingExperience,
      purchasedTickets: purchasedTickets?.result || [],
      checkout,
      cancelCheckout,
      showLoginModal,
    }),
    [
      identifier,
      ticketTiers,
      experience,
      selfExperienceDetails,
      availableTickets,
      fetchingExperience,
      purchasedTickets,
      togglingInterested,
      showLoginModal,
      showWaitlistModal,
    ],
  );

  return (
    <ExperienceContext.Provider value={context}>
      {children}
    </ExperienceContext.Provider>
  );
}
