import { FlowbiteTableTheme } from 'flowbite-react';

const table: FlowbiteTableTheme = {
  root: {
    base: 'w-full text-left text-sm text-black border-separate border-spacing-x-0',
    shadow: 'absolute left-0 top-0 -z-10 h-full w-full rounded bg-white',
    wrapper: 'relative',
  },
  body: {
    base: 'group/body',
    cell: {
      base: 'px-6 py-4 border-t border-l last:border-r group-first/row:border-t-0 group-last/row:border-b group-last/row:first:rounded-bl group-last/row:last:rounded-br',
    },
  },
  head: {
    base: 'group/head text-xs uppercase text-black',
    cell: {
      base: 'border-t border-l border-b px-6 py-4 first:rounded-tl last:rounded-tr last:border-r',
    },
  },
  row: {
    base: 'group/row bg-red-500 border-b last:border-b-0',
    hovered: 'hover:bg-gray-50',
    striped: 'odd:bg-white even:bg-gray-50',
  },
};

export default table;
