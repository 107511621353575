import { FlowbiteAlertTheme } from 'flowbite-react';

const alert: FlowbiteAlertTheme = {
  base: 'flex flex-col gap-2 p-4 text-sm',
  borderAccent: 'border-t-4',
  closeButton: {
    base: '-m-1.5 ml-auto inline-flex h-8 w-8 rounded-lg p-1.5 focus:ring-2',
    icon: 'h-5 w-5',
    color: {
      info: 'bg-cyan-100 text-cyan-500 hover:bg-cyan-200 focus:ring-cyan-400',
      gray: 'bg-gray-100 text-gray-500 hover:bg-gray-200 focus:ring-gray-400',
      failure: 'bg-red-100 text-red-500 hover:bg-red-200 focus:ring-red-400',
      success:
        'bg-green-100 text-green-500 hover:bg-green-200 focus:ring-green-400',
      warning:
        'bg-yellow-100 text-yellow-500 hover:bg-yellow-200 focus:ring-yellow-400',
      red: 'bg-red-100 text-red-500 hover:bg-red-200 focus:ring-red-400',
      green:
        'bg-green-100 text-green-500 hover:bg-green-200 focus:ring-green-400',
      yellow:
        'bg-yellow-100 text-yellow-500 hover:bg-yellow-200 focus:ring-yellow-400',
      blue: 'bg-blue-100 text-blue-500 hover:bg-blue-200 focus:ring-blue-400',
      cyan: 'bg-cyan-100 text-cyan-500 hover:bg-cyan-200 focus:ring-cyan-400',
      pink: 'bg-pink-100 text-pink-500 hover:bg-pink-200 focus:ring-pink-400',
      lime: 'bg-lime-100 text-lime-500 hover:bg-lime-200 focus:ring-lime-400',
      dark: 'bg-gray-100 text-gray-500 hover:bg-gray-200 focus:ring-gray-400',
      indigo:
        'bg-indigo-100 text-indigo-500 hover:bg-indigo-200 focus:ring-indigo-400',
      purple:
        'bg-purple-100 text-purple-500 hover:bg-purple-200 focus:ring-purple-400',
      teal: 'bg-teal-100 text-teal-500 hover:bg-teal-200 focus:ring-teal-400',
      light: 'bg-gray-50 text-gray-500 hover:bg-gray-100 focus:ring-gray-200',
    },
  },
  color: {
    info: 'border-cyan-500 bg-cyan-100 text-cyan-700',
    gray: 'border-gray-500 bg-gray-100 text-gray-700',
    failure: 'border-red-500 bg-red-100 text-red-700',
    success: 'border-green-500 bg-green-100 text-green-700',
    warning:
      'border-warning-darker bg-warning-lighter text-white font-semibold',
    red: 'border-red-500 bg-red-100 text-red-700',
    green: 'border-green-500 bg-green-100 text-green-700',
    yellow: 'border-yellow-500 bg-yellow-100 text-yellow-700',
    blue: 'border-blue-500 bg-blue-100 text-blue-700',
    cyan: 'border-cyan-500 bg-cyan-100 text-cyan-700',
    pink: 'border-pink-500 bg-pink-100 text-pink-700',
    lime: 'border-lime-500 bg-lime-100 text-lime-700',
    dark: 'border-gray-600 bg-gray-800 text-gray-200',
    indigo: 'border-indigo-500 bg-indigo-100 text-indigo-700',
    purple: 'border-purple-500 bg-purple-100 text-purple-700',
    teal: 'border-teal-500 bg-teal-100 text-teal-700',
    light: 'border-gray-400 bg-gray-50 text-gray-600',
  },
  icon: 'mr-3 inline h-5 w-5 flex-shrink-0',
  rounded: 'rounded-lg',
  wrapper: 'flex items-center',
};

export default alert;
