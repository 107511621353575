import { ColorRing } from 'react-loader-spinner';
import React from 'react';
import { tw } from '../../utils';

type LoaderProps = {
  loading: boolean;
  vertical?: boolean;
  horizontal?: boolean;
  children?: React.ReactElement | string | null | React.ReactNode;
  className?: string;
};
function Loader({
  children,
  loading,
  vertical,
  horizontal,
  className,
}: LoaderProps) {
  if (loading) {
    return (
      <ColorRing
        visible
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperClass={tw(
          'flex items-center justify-center',
          vertical ? 'h-full' : '',
          horizontal ? 'w-full' : '',
          className,
        )}
        colors={['#931D72', '#D8607A', '#F1D7EA', '#EFE9E4', '#FFFFFF']}
      />
    );
  }

  return children;
}

export { Loader };
