import React from 'react';
import { Button } from 'flowbite-react';
import { tw } from '../../utils';
import Img from '../img';

export type CoverProps = {
  title: string;
  subtitle: string | React.ReactElement;
  imageUrl: string;
  cta?: string;
  ctaCallback?: () => void;
};

/**
 * Creates a cover page icon
 */
function Cover({ title, subtitle, imageUrl, cta, ctaCallback }: CoverProps) {
  return (
    <div
      className={tw(
        'relative w-full h-[50vh] md:h-[75vh]',
        'rounded-bl-2xl rounded-br-2xl',
        'overflow-hidden bg-primary',
      )}
    >
      <Img
        src={imageUrl}
        alt="Descriptive alt text"
        className="absolute inset-0 object-cover w-full h-full z-0"
      />
      <div className="relative z-10 w-mobile lg:w-desktop h-full flex flex-col justify-end pb-8 lg:!pb-20 mx-auto">
        <h1 className="text-white">{title}</h1>
        <h4 className="mt-5 text-white font-normal">{subtitle}</h4>
        {cta && (
          <Button
            type="button"
            color="primary"
            onClick={ctaCallback}
            className="w-56 enabled:h-14 enabled:mt-8"
          >
            <span className="text-lg font-bold">{cta}</span>
          </Button>
        )}
      </div>
    </div>
  );
}

export default Cover;
