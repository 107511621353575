import { Link as RRLink, LinkProps } from 'react-router-dom';
import * as React from 'react';
import { usePostHog } from 'posthog-js/react';
import { Properties } from 'posthog-js';
import { useScreenSize } from '../../hooks';
import { handleOnClick } from './utils.ts';

type Props = LinkProps &
  React.RefAttributes<HTMLAnchorElement> & {
    track?: Properties;
  };

export default function Link({ to, children, track, onClick, ...rest }: Props) {
  const posthog = usePostHog();
  const { isMobile } = useScreenSize();
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (onClick) {
        onClick(event);
      }

      if (posthog && track) {
        handleOnClick(posthog, track, isMobile);
      }
    },
    [posthog, track, isMobile, onClick],
  );

  return (
    <RRLink to={to} {...rest} onClick={handleClick}>
      {children}
    </RRLink>
  );
}
