import { ReactNode, useEffect, useMemo } from 'react';
import { getHostUniqueNameParam } from '@cg/module-frontend/src/hooks/params.hooks.ts';
import { HostContext, HostContextType } from './HostContext';
import {
  fetchHostByUniqueNameHook,
  listExperiencesHook,
  listHostUsersHook,
  listSeriesHook,
} from '~/generated/clients/public/hosts/PublicHosts.hooks';
import { ExperienceType } from '~/generated/models/ExperienceType';

type Prop = {
  children: ReactNode;
};

export function HostContextProvider({ children }: Prop) {
  const hostUniqueName = getHostUniqueNameParam();
  const fetchHostArg = { ids: { hostId: hostUniqueName } };

  const {
    data: host,
    calling: fetchingHost,
    call: fetchHost,
  } = fetchHostByUniqueNameHook(false);

  const {
    data: hostUsers,
    calling: fetchingHostUser,
    call: fetchHostUsers,
  } = listHostUsersHook(false);

  const {
    data: experiences,
    calling: fetchingExperiences,
    call: fetchExperiences,
  } = listExperiencesHook(false);
  const {
    data: seriesExperiences,
    calling: fetchingSeriesExperiences,
    call: fetchSeriesExperiences,
  } = listExperiencesHook(false);
  const {
    data: series,
    calling: fetchingSeries,
    call: fetchSeries,
  } = listSeriesHook(false);

  useEffect(() => {
    if (!hostUniqueName) {
      return;
    }

    fetchHost(fetchHostArg);
  }, [hostUniqueName]);

  useEffect(() => {
    if (!host) {
      return;
    }

    fetchHostUsers({ ids: { hostId: host.id } });
    fetchExperiences({
      ids: { hostId: host.id },
      filters: {
        experienceType: ExperienceType.OneTime,
      },
    });
    fetchSeriesExperiences({
      ids: { hostId: host.id },
      filters: {
        experienceType: ExperienceType.RunClub,
      },
    });
    fetchSeries({ ids: { hostId: host.id } });
  }, [host]);

  // Create context to send over
  const context = useMemo<HostContextType>(
    () => ({
      host,
      hostUsers: hostUsers?.result || [],
      fetchingHost,
      fetchingSeries: fetchingSeriesExperiences || fetchingSeries,
      series: series?.result || [],
      seriesExperiences: seriesExperiences?.result || [],
      fetchingExperiences,
      experiences: experiences?.result || [],
    }),
    [
      fetchingHost,
      fetchingHostUser,
      fetchingExperiences,
      fetchingSeries,
      fetchingSeriesExperiences,
      series,
      seriesExperiences,
      experiences,
    ],
  );

  return (
    <HostContext.Provider value={context}>{children}</HostContext.Provider>
  );
}
