import React, { ReactNode } from 'react';

type TabProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  title: string;
  children: ReactNode;
};
export default function Tab({ children }: TabProps) {
  return <div>{children}</div>;
}
