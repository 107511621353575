/* eslint-disable */
import React from 'react';

type Props = {
  src: string;
  alt?: string;
  className?: string;
};

const Img = React.memo(
  React.forwardRef<HTMLImageElement, Props>(({ src, alt, className }, ref) => {
    return (
      <img
        src={src}
        alt={alt ?? 'image'}
        className={className}
        loading="lazy"
        ref={ref}
      />
    );
  }),
);

export default Img;
