import { Id } from './Id';

export class ExperienceTagId extends Id {
  // This is required for stricter TS check
  // @ts-ignore
  private readonly _brand!: void;

  public static PREFIX = 'ET';

  public getPrefix(): string {
    return ExperienceTagId.PREFIX;
  }

  public static isValid(id: string): boolean {
    return id !== null && id.startsWith(ExperienceTagId.PREFIX) && Id.looksLikeId(id);
  }
}
