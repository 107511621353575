import { useMemo } from 'react';
import { AddressOrCoordinate } from '~/generated/models/AddressOrCoordinate';

export const getAddressString = (address?: AddressOrCoordinate | null) => {
  if (!address) {
    return 'Add address 📍';
  }

  if (!address?.street1) {
    return 'Vancouver, BC';
  }

  const prefix = address.street2 ? `#${address.street2} - ` : '';
  const str: string[] = [];
  if (prefix.trim()) {
    str.push(prefix.trim());
  }
  if (address.street1.trim()) {
    str.push(address.street1.trim());
  }
  if (address.city?.trim()) {
    str.push(address.city.trim());
  }
  if (address.state?.trim() || address.code?.trim()) {
    str.push(`${address.state?.trim()} ${address.code?.trim()}`);
  }
  if (address.country?.trim()) {
    str.push(address.country?.trim());
  }

  return str.join(', ');
};

export const useAddressString = (
  isSecret: boolean,
  address?: AddressOrCoordinate | null,
) => {
  return useMemo(() => {
    if (isSecret) {
      return 'Location is secret for now 🤫. It will be revealed on the day of the event.';
    }

    return getAddressString(address);
  }, [address, isSecret]);
};
