import {
  type CustomFlowbiteTheme,
  Flowbite as FlowbiteProvider,
} from 'flowbite-react';
import React from 'react';
import { theme as defaultTheme } from './theme';

type Prop = {
  children: React.ReactElement | React.ReactElement[];
  theme?: CustomFlowbiteTheme;
};

export function ThemeProvider({ children, theme }: Prop) {
  theme = {
    ...defaultTheme,
    ...theme,
  };

  return <FlowbiteProvider theme={{ theme }}>{children}</FlowbiteProvider>;
}
