import React from 'react';
import { useScreenSize } from '../../hooks';

type DesktopScreenProps = {
  children: React.ReactElement | React.ReactElement[] | null;
};

function DesktopScreen({ children }: DesktopScreenProps) {
  const { isSmall } = useScreenSize();
  if (isSmall) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export default DesktopScreen;
