import React, { useEffect, useState } from 'react';
import {
  Content,
  DesktopScreen,
  MobileScreen,
  Page,
  Loader,
  HostLabel,
  ExperienceContent,
  HostDetail,
  Banner as InfoBanner,
  Href,
  NotificationBanner,
} from '@cg/module-frontend/src/components';
import { ClockIcon, LocationIcon } from '@cg/module-frontend/src/icons';
import { dater } from '@cg/common/src/utils';
import { useAuth } from '@cg/module-frontend';
import { boxConfig } from '@cg/module-frontend/src/config';
import { featureFlagConstants } from '@cg/common/src/constants';
import { DEFAULT_BANNER_URL } from '@cg/module-frontend/src/constant.ts';
import { fetchExperienceAddressHook } from '~/generated/clients/public/experiences/PublicExperiences.hooks';
import {
  fetchHostHook,
  listHostUsersHook,
} from '~/generated/clients/public/hosts/PublicHosts.hooks';
import { AddressOrCoordinate } from '~/generated/models/AddressOrCoordinate';
import { FamiliarFaces } from '~/components/FamiliarFaces';
import { ExperiencesList } from '~/components/experiences';
import { TicketDetails } from './components/TicketDetails';
import { useExperience } from '~/app/context/experience';
import {
  listAttendingFamiliarFacesHook,
  listInterestedFamiliarFacesHook,
} from '~/generated/clients/private/experiences/PrivateExperiences.hooks';
import { HostUserProfile } from '~/generated/models/HostUserProfile';
import Banner from './components/Banner';
import MapAndTime from './components/MapAndTime';

const Experience = React.memo(() => {
  const { experience, fetchingExperience, purchasedTickets, isPast } =
    useExperience();
  const { self, hasFlag } = useAuth();

  const {
    calling: fetchingHost,
    data: host,
    call: getHost,
  } = fetchHostHook(false);
  const {
    calling: fetchingAddress,
    data: address,
    call: getAddress,
  } = fetchExperienceAddressHook(false);
  const { data: hostUsers, call: fetchHostUsers } = listHostUsersHook(false);
  const {
    data: attendingUsers,
    calling: fetchingAttendingUsers,
    call: fetchAttendingUsers,
  } = listAttendingFamiliarFacesHook(false);
  const {
    data: interestedUsers,
    calling: fetchingInterestedUsers,
    call: fetchInterestedUsers,
  } = listInterestedFamiliarFacesHook(false);

  const isSecret =
    (experience?.isSecret && address && address?.id === null) || false;

  useEffect(() => {
    if (!experience) {
      return;
    }

    getHost({ ids: { hostId: experience.hostId } });
    getAddress({ ids: { experienceId: experience.id } });
    fetchHostUsers({ ids: { hostId: experience.hostId } });
    fetchAttendingUsers({
      ids: { experienceId: experience.id },
    });
    fetchInterestedUsers({
      ids: { experienceId: experience.id },
    });
  }, [experience]);

  const [isSelfHost, setIsSelfHost] = useState(false);

  useEffect(() => {
    if (!self || !hostUsers) {
      return;
    }

    setIsSelfHost(
      hostUsers.result?.some((user: HostUserProfile) =>
        self.user.id.isEqual(user.id),
      ),
    );
  }, [hostUsers, self]);

  const getBanner = () => {
    if (!self || !experience) {
      return null;
    }

    if (!experience.meta?.dateChange) {
      return null;
    }

    const rescheduleFlag = hasFlag(featureFlagConstants.EXPERIENCE_RESCHEDULE);
    const userTicket = purchasedTickets.find((ticket) =>
      ticket.userId.isEqual(self.user.id),
    );
    if (
      userTicket &&
      rescheduleFlag &&
      dater(userTicket.dateCreated).isBefore(
        experience.meta?.dateChange[0].prevStartDate,
      ) &&
      !isPast
    ) {
      return (
        <NotificationBanner className="mt-4" closeBtnText="Got it!">
          This experience has been rescheduled to{' '}
          {dater(experience.startDate).format('MMM Do, h:mmA')}.
        </NotificationBanner>
      );
    }
    return null;
  };

  return (
    <Page>
      <Loader horizontal loading={fetchingExperience || fetchingHost}>
        {experience && (
          <>
            <DesktopScreen>
              <div>
                <div
                  className="absolute w-full top-0 left-0 h-[500px] bg-no-repeat bg-cover"
                  style={{
                    backgroundImage: `url(${DEFAULT_BANNER_URL})`,
                  }}
                >
                  <div className="w-full h-full bg-gradient-to-b from-secondary to-primary-darker opacity-30" />
                </div>
                {isSelfHost && (
                  <InfoBanner>
                    Edit your experience on{' '}
                    <Href
                      to={`${boxConfig.baseUrls.playGround}/dashboard/experiences/${experience.id.getValue()}/edit`}
                    >
                      Playground
                    </Href>
                    .
                  </InfoBanner>
                )}
                {experience?.meta?.dateChange?.length && getBanner()}
              </div>
              <Content>
                <div className="w-full mx-auto flex space-x-10 relative mt-16">
                  <div className="w-7/12">
                    <Banner experience={experience} />
                    <div className="flex flex-row mt-4 mb-4 border border-grey-darker w-full rounded font-semibold">
                      <div className="flex items-center w-full p-2 text-left">
                        <ClockIcon className="mr-2 text-primary size-6" />{' '}
                        {dater(experience.startDate).format('Do MMM, h:mmA')}
                      </div>
                      <div className="relative flex items-center justify-center p-2">
                        <div className="w-px h-full bg-grey-darker" />
                      </div>
                      <div className="flex items-center w-full p-2">
                        <LocationIcon className="text-primary size-6 mr-2" />
                        {isSecret ? 'Secret Location' : address?.name}
                      </div>
                    </div>
                    <ExperienceContent
                      className="mt-9"
                      content={experience.content}
                    />
                    <Loader loading={fetchingAddress}>
                      <MapAndTime
                        className="mt-9"
                        isSecret={isSecret}
                        address={address as AddressOrCoordinate}
                        experience={experience}
                      />
                    </Loader>

                    <Loader horizontal loading={fetchingHost}>
                      {host && (
                        <HostDetail
                          host={host}
                          className="mt-9"
                          users={hostUsers?.result || []}
                        />
                      )}
                    </Loader>
                  </div>
                  <div className="w-5/12">
                    <h2 className="line-clamp-3 overflow-hidden">
                      {experience.title}
                    </h2>
                    <div className="flex justify-between items-center my-6">
                      <Loader horizontal loading={fetchingHost}>
                        {host && <HostLabel host={host} />}
                      </Loader>
                    </div>
                    <div className="sticky top-20 ">
                      <TicketDetails experience={experience} />

                      <Loader
                        horizontal
                        loading={
                          fetchingHost ||
                          fetchingExperience ||
                          fetchingAttendingUsers ||
                          fetchingInterestedUsers
                        }
                      >
                        {host && (
                          <FamiliarFaces
                            attendingUsers={attendingUsers ?? []}
                            interestedUsers={interestedUsers ?? []}
                          />
                        )}
                      </Loader>
                    </div>
                  </div>
                </div>
                <ExperiencesList />
              </Content>
            </DesktopScreen>

            <MobileScreen>
              <div className="w-full h-full">
                <Banner experience={experience} />
              </div>
              <div className="p-4 space-y-6">
                <div className="flex flex-row flex-wrap">
                  <h2 className="overflow-hidde w-full">{experience.title}</h2>
                  <Loader horizontal loading={fetchingHost}>
                    {host && <HostLabel host={host} className="my-3" />}
                  </Loader>
                </div>
                <div className="flex flex-row mb-4 border border-grey-darker w-full rounded font-semibold">
                  <div className="flex items-center w-full p-2 text-left">
                    <ClockIcon className="mr-2 text-primary stroke-primary" />{' '}
                    {dater(experience.startDate).format('MMM Do, h:mmA')}
                  </div>
                  <div className="relative flex items-center justify-center p-2">
                    <div className="w-px h-full bg-grey-darker" />
                  </div>
                  <div className="flex items-center w-full p-2">
                    <LocationIcon className="mr-2 text-primary" />
                    {address?.name ?? 'Secret Location'}
                  </div>
                </div>
                <div className="text-base font-light leading-relaxed">
                  <ExperienceContent content={experience.content} />
                </div>
                <Loader
                  horizontal
                  loading={
                    fetchingHost ||
                    fetchingExperience ||
                    fetchingAttendingUsers ||
                    fetchingInterestedUsers
                  }
                >
                  {host && (
                    <FamiliarFaces
                      attendingUsers={attendingUsers ?? []}
                      interestedUsers={interestedUsers ?? []}
                    />
                  )}
                </Loader>
                <Loader loading={fetchingAddress}>
                  <MapAndTime
                    isSecret={isSecret}
                    address={address as AddressOrCoordinate}
                    experience={experience}
                  />
                </Loader>
                <div className="lg:my-0 mx-auto lg:mx-0  flex justify-center items-center lg:block">
                  <Loader horizontal loading={fetchingHost}>
                    {host && (
                      <HostDetail host={host} users={hostUsers?.result || []} />
                    )}
                  </Loader>
                </div>
                <ExperiencesList />
              </div>
            </MobileScreen>
          </>
        )}
      </Loader>
    </Page>
  );
});

export default Experience;
