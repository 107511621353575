import { Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth } from '@cg/module-frontend';
import {
  Href,
  Loader,
  Banner,
  Footer,
  MobileScreen,
} from '@cg/module-frontend/src/components';
import Header from './Header';
import BottomNavigation from './BottomNavigation';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function MainLayout() {
  const { pathname } = useLocation();
  const { self, fetchingSelf, requiresSignup } = useAuth();
  const isSignup = ['/signup', '/login'].includes(pathname);

  return (
    <>
      <Header />
      {self && requiresSignup && !isSignup && (
        <Banner>
          Welcome back! Finish <Href to="/signup">signing up</Href> to access
          all our features.
        </Banner>
      )}

      <main className="min-h-[40vh] pb-20 md:pb-0">
        <Loader horizontal loading={fetchingSelf}>
          <Outlet />
        </Loader>
        <Footer />
      </main>
      <ScrollToTop />
      <MobileScreen>
        <BottomNavigation />
      </MobileScreen>
    </>
  );
}

export default MainLayout;
