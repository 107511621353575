import React, { useEffect } from 'react';
import { Loader } from '@cg/module-frontend/src/components';
import { toast } from '@cg/module-frontend';
import { ticketPurchaser } from '@cg/module-frontend/src/utils/storage.ts';
import { useCheckout } from '../context/CheckoutContext';
import { checkoutHook } from '~/generated/clients/public/experiences/PublicExperiences.hooks';
import StepWrapper from '../components/StepWrapper';

type StepThreeCheckoutProps = {
  goBack: () => void;
};

export function StepThreeCheckout({ goBack }: StepThreeCheckoutProps) {
  const { tickets, experience } = useCheckout();
  const {
    data: redirect,
    call: checkout,
    calling,
    error,
  } = checkoutHook(false);

  useEffect(() => {
    if (!experience || redirect || calling) {
      return;
    }

    const call = async () => {
      const response = await checkout({
        ids: {
          experienceId: experience.id,
        },
        body: tickets,
      });
      if (!response.succeeded && response.status === 400) {
        toast.warning(response.payload.message, {
          duration: 1500,
          dismissible: true,
        });
        goBack();
      }
    };

    call();
  }, [experience, redirect]);

  useEffect(() => {
    if (redirect?.url) {
      ticketPurchaser.set(redirect.userId);
      window.location.href = redirect.url;
    }
  }, [redirect]);

  return (
    <StepWrapper step={3} title="Prepping Your Tickets...">
      <Loader horizontal loading={calling}>
        {error && (
          <div className="font-bold flex justify-center">
            <span className="text-warning mr-2">⚠️</span> Oops, something went
            wrong. Contact us and we can fix it.
            <span className="text-warning ml-2">⚠ </span>
          </div>
        )}
      </Loader>
    </StepWrapper>
  );
}
