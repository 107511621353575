import React from 'react';
import { ExperienceContextProvider } from '~/app/context/experience';
import ExperienceLayout from './ExperienceLayout';

function ExperienceLayoutProvider() {
  return (
    <ExperienceContextProvider>
      <ExperienceLayout />
    </ExperienceContextProvider>
  );
}

export default ExperienceLayoutProvider;
