import React, { useMemo } from 'react';
import { DetailedExperienceSeries } from '~/generated/models/DetailedExperienceSeries.ts';

/**
 * Normalizes a pace string by ensuring it includes a `:00` if seconds are missing.
 *
 * @param {string} pace - The pace string to normalize (e.g., "5" or "5:30").
 * @returns {string} - The normalized pace string (e.g., "5:00" or "5:30").
 */
export const normalizePace = (pace: string): string => {
  if (!pace.includes(':')) {
    return `${pace}:00`;
  }
  return pace;
};

/**
 * Converts a pace string into the total number of seconds.
 *
 * @param {string} pace - The pace string to convert (e.g., "5:30").
 * @returns {number} - The total seconds (e.g., 330).
 */
export const toSeconds = (pace: string): number => {
  const [minutes, seconds] = pace.split(':').map(Number);
  return minutes * 60 + (seconds || 0);
};

/**
 * Calculates sorted unique paces from a list of series.
 *
 * @param {DetailedExperienceSeries[]} list - The list of series to process.
 * @returns {string[]} - A sorted array of unique paces.
 */
export const calculatePace = (list: DetailedExperienceSeries[]): string[] => {
  return list
    .map((s) => s.meta?.experienceMeta?.runs?.map((r) => r?.pace))
    .flat()
    .filter(Boolean)
    .sort((a, b) => {
      return (
        toSeconds(normalizePace(a ?? '0')) - toSeconds(normalizePace(b ?? '0'))
      );
    }) as string[];
};

/**
 * Hook to calculate and memoize paces for a list of series.
 *
 * @param {DetailedExperienceSeries[]} list - The list of series to process.
 * @returns {string[]} - The memoized array of unique paces.
 */
export const usePaceCalculator = (list: DetailedExperienceSeries[]) => {
  return useMemo(() => {
    return calculatePace(list);
  }, [list]);
};

/**
 * Hook to generate a JSX element representing the pace range.
 *
 * @param {string[]} paces - The sorted list of paces.
 * @returns {JSX.Element} - The JSX element displaying the pace range.
 */
export const usePaceRangeDom = (paces: string[]) => {
  return useMemo(() => {
    const minPace = paces[0];
    const maxPace = paces[paces.length - 1] ?? minPace;
    return (
      <span>
        {minPace === maxPace ? (
          <>
            {minPace}
            <span className="text-sm">/km</span>
          </>
        ) : (
          <>
            {minPace}-{maxPace}
            <span className="text-sm">/km</span>
          </>
        )}
      </span>
    );
  }, [paces]);
};

/**
 * Calculates sorted unique distances from a list of series.
 *
 * @param {DetailedExperienceSeries[]} list - The list of series to process.
 * @returns {number[]} - A sorted array of unique distances.
 */
export const calculateDistance = (
  list: DetailedExperienceSeries[],
): number[] => {
  return list
    .map((s) => s.meta?.experienceMeta?.runs?.map((t) => t?.distance))
    .flat()
    .filter(Boolean)
    .filter((v, i, a) => a.indexOf(v) === i) // Ensure uniqueness
    .sort((a, b) => (a as number) - (b as number)) as number[];
};

/**
 * Hook to calculate and memoize distances for a list of series.
 *
 * @param {DetailedExperienceSeries[]} list - The list of series to process.
 * @returns {number[]} - The memoized array of unique distances.
 */
export const useDistanceCalculator = (list: DetailedExperienceSeries[]) => {
  return useMemo(() => {
    return calculateDistance(list);
  }, [list]);
};

/**
 * Hook to generate a JSX element representing the distance range.
 *
 * @param {number[]} distances - The sorted list of distances.
 * @returns {JSX.Element} - The JSX element displaying the distance range.
 */
export const useDistanceRangeDom = (distances: number[]) => {
  return useMemo(() => {
    const minDistance = distances[0];
    const maxDistance = distances[distances.length - 1] ?? minDistance;
    return (
      <span className="text-sm">
        {minDistance === maxDistance ? (
          <>
            {minDistance}
            <span className="text-sm">km</span>
          </>
        ) : (
          <>
            {minDistance}
            <span className="text-sm">km</span>-{maxDistance}
            <span className="text-sm">km</span>
          </>
        )}
      </span>
    );
  }, [distances]);
};

/**
 * Calculates the binary representation of days across a list of series.
 *
 * @param {DetailedExperienceSeries[]} list - The list of series to process.
 * @returns {string} - A binary string representing the days.
 */
export const calculateDays = (list: DetailedExperienceSeries[]): string => {
  return (
    list
      .map((s) => s.schedule?.dayOfWeek)
      .flat()
      .filter(Boolean)
      .filter((v, i, a) => a.indexOf(v) === i)
      .map((binary) => parseInt(binary, 2))
      // eslint-disable-next-line no-bitwise
      .reduce((acc, num) => acc | num, 0)
      .toString(2)
      .padStart(7, '0')
  );
};

/**
 * Type representing a consolidated club.
 */
type ConsolidatedClub = {
  series: DetailedExperienceSeries;
  days: string;
  paces: string[];
  distances: number[];
};

/**
 * Hook to consolidate a list of series into clubs grouped by host.
 *
 * @param {DetailedExperienceSeries[]} list - The list of series to consolidate.
 * @returns {ConsolidatedClub[]} - An array of consolidated clubs.
 */
export const useConsolidateClubs = (
  list: DetailedExperienceSeries[],
): ConsolidatedClub[] => {
  return useMemo(() => {
    const hosts: string[] = [];
    return list
      .sort((a, b) => {
        if (a.host.uniqueName === 'a-very-good-run-club') return -1;
        if (b.host.uniqueName === 'a-very-good-run-club') return 1;
        return 0;
      })
      .map((series) => {
        if (hosts.includes(series.host.id.getValue())) return null;

        const others = list.filter((s) => s.host.id.isEqual(series.host.id));
        hosts.push(series.host.id.getValue());

        return {
          series,
          days: calculateDays(others),
          paces: calculatePace(others),
          distances: calculateDistance(others),
        };
      })
      .filter(Boolean) as ConsolidatedClub[];
  }, [list]);
};
