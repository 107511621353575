/* eslint-disable */


import { useHttp } from '@cg/module-frontend/src/core/http';

import { fetchWaitlistConfirmation, FetchWaitlistConfirmationArgs, isWaitlisted, IsWaitlistedArgs, joinWaitlist, JoinWaitlistArgs, listAttendingExperiences, ListAttendingExperiencesArgs, listAttendingFamiliarFaces, ListAttendingFamiliarFacesArgs, listInterestedFamiliarFaces, ListInterestedFamiliarFacesArgs, listTickets, ListTicketsArgs, refundTicket, RefundTicketArgs, selfExperienceDetails, SelfExperienceDetailsArgs, toggleExperienceInterest, ToggleExperienceInterestArgs, updateTicketStatus, UpdateTicketStatusArgs } from './PrivateExperiences.client';

export const fetchWaitlistConfirmationHook = (immediateCall: boolean = true, initArgs?: FetchWaitlistConfirmationArgs) => {
  return useHttp(fetchWaitlistConfirmation, { immediateCall, initArgs })
};
export const isWaitlistedHook = (immediateCall: boolean = true, initArgs?: IsWaitlistedArgs) => {
  return useHttp(isWaitlisted, { immediateCall, initArgs })
};
export const joinWaitlistHook = (immediateCall: boolean = true, initArgs?: JoinWaitlistArgs) => {
  return useHttp(joinWaitlist, { immediateCall, initArgs })
};
export const listAttendingExperiencesHook = (immediateCall: boolean = true, initArgs?: ListAttendingExperiencesArgs) => {
  return useHttp(listAttendingExperiences, { immediateCall, initArgs })
};
export const listAttendingFamiliarFacesHook = (immediateCall: boolean = true, initArgs?: ListAttendingFamiliarFacesArgs) => {
  return useHttp(listAttendingFamiliarFaces, { immediateCall, initArgs })
};
export const listInterestedFamiliarFacesHook = (immediateCall: boolean = true, initArgs?: ListInterestedFamiliarFacesArgs) => {
  return useHttp(listInterestedFamiliarFaces, { immediateCall, initArgs })
};
export const listTicketsHook = (immediateCall: boolean = true, initArgs?: ListTicketsArgs) => {
  return useHttp(listTickets, { immediateCall, initArgs })
};
export const refundTicketHook = (immediateCall: boolean = true, initArgs?: RefundTicketArgs) => {
  return useHttp(refundTicket, { immediateCall, initArgs })
};
export const selfExperienceDetailsHook = (immediateCall: boolean = true, initArgs?: SelfExperienceDetailsArgs) => {
  return useHttp(selfExperienceDetails, { immediateCall, initArgs })
};
export const toggleExperienceInterestHook = (immediateCall: boolean = true, initArgs?: ToggleExperienceInterestArgs) => {
  return useHttp(toggleExperienceInterest, { immediateCall, initArgs })
};
export const updateTicketStatusHook = (immediateCall: boolean = true, initArgs?: UpdateTicketStatusArgs) => {
  return useHttp(updateTicketStatus, { immediateCall, initArgs })
};
