import React from 'react';
import classNames from 'classnames';
import { ChevronRightIcon } from '@cg/module-frontend/src/icons';
import { sizeToPx } from '@cg/module-frontend/src/constant';
import { UserAvatar } from '@cg/module-frontend/src/components';
import { UserWithSharedExperience } from '~/generated/models/UserWithSharedExperience';

type ExperienceSummaryFamiliarFacesProps = {
  users: UserWithSharedExperience[];
};

const ExperienceSummaryFamiliarFaces = React.memo(
  ({ users }: ExperienceSummaryFamiliarFacesProps) => {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
      <div
        className={classNames('bg-white py-2 px-2 rounded gap-4 min-h-11', {
          'flex items-center': users.length === 0,
        })}
      >
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className="flex justify-between align-middle h-full w-full items-center gap-x-2 text-primary"
        >
          {users.length !== 0 && (
            <>
              <div className="flex justify-end left-0 w-fit h-full ">
                {users.slice(0, 3).map((user, index) => (
                  <div
                    style={{
                      left: `-${(index * sizeToPx.sm) / 2}px`,
                      opacity: `${100 - index * 25}%`,
                      zIndex: 3 - index,
                    }}
                    className="relative"
                    key={user.id.getValue()}
                  >
                    <div className="flex flex-col justify-center items-center h-full">
                      <UserAvatar user={user} imageSize="sm" iconSize="sm" />
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex text-sm items-center space-x-1 ml-auto">
                <b className="font-semibold">{users.length}</b>
                <span className="">Familiar Faces</span>
                <ChevronRightIcon
                  className={classNames('transition duration-300 transform', {
                    'rotate-90': isOpen,
                  })}
                />
              </div>
            </>
          )}
          {!users.length && (
            <span className="text-sm">No familiar faces yet</span>
          )}
        </button>

        {users.length !== 0 && (
          <div
            className={`transition-[max-height] duration-300 ${isOpen ? 'max-h-screen' : 'max-h-0 overflow-hidden'}`}
          >
            <div className="pt-4">
              <div className="grid grid-cols-2 gap-3">
                {users?.map((user) => {
                  return (
                    <div
                      key={user.id.getValue()}
                      className="flex space-x-2 justify-start items-center"
                    >
                      <UserAvatar
                        user={user}
                        imageSize="sm"
                        iconSize="sm"
                        expandable={isOpen}
                      />
                      <span className="text-sm font-semibold">
                        {user.firstName}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
);

export default ExperienceSummaryFamiliarFaces;
