import { Markdown } from '../markdown';

export type ExperienceContentProps = {
  content: string;
  className?: string;
};

function ExperienceContent({ content, className }: ExperienceContentProps) {
  return (
    <div className={className}>
      <Markdown>{content}</Markdown>
    </div>
  );
}

export default ExperienceContent;
