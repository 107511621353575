import classNames from 'classnames';
import { Banner as FlowbiteBanner } from 'flowbite-react';
import React from 'react';

type BannerProps = {
  children: React.ReactNode;
  onTop?: boolean;
  type?: 'info' | 'warning' | 'error';
};

export function Banner({ children, onTop, type }: BannerProps) {
  type = type || 'info';

  return (
    <FlowbiteBanner
      className={classNames('sticky bg-white z-10', {
        'top-20': !onTop,
        'top-0': onTop,
      })}
    >
      <div
        className={classNames('flex sticky w-full justify-between p-4', {
          'bg-secondary': type === 'info',
          'bg-warning': type === 'warning',
          'bg-failure text-white': type === 'error',
        })}
      >
        <div className="mx-auto flex items-center">
          <p className="flex items-center text-sm font-normal">
            <span>{children}</span>
          </p>
        </div>
      </div>
    </FlowbiteBanner>
  );
}
