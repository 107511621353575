import { FlowbiteTabsTheme } from 'flowbite-react';

const tabs: FlowbiteTabsTheme = {
  base: 'flex flex-col gap-2',
  tablist: {
    base: 'flex text-center space-x-4',
    variant: {
      default: 'flex-wrap',
      underline: '-mb-px flex-wrap',
      pills: 'flex-wrap space-x-2 text-sm font-medium text-grey-darker',
      fullWidth:
        'grid w-full grid-flow-col divide-x divide-gray-200 rounded-none text-sm font-medium',
    },
    tabitem: {
      base: 'flex items-center justify-center rounded-t py-4 text-sm font-medium first:ml-0 disabled:cursor-default disabled:text-grey',
      variant: {
        default: {
          base: 'rounded-t lg:text-xl text-2xl',
          active: {
            on: 'flg:text-xl text-2xl font-bold text-black',
            off: 'text-grey-darker hover:text-black-lighter',
          },
        },
        underline: {
          base: 'rounded-t',
          active: {
            on: 'active text-primary',
            off: 'text-grey-darker',
          },
        },
        pills: {
          base: '',
          active: {
            on: 'rounded bg-primary text-white',
            off: 'rounded text-grey-darker hover:text-black-lighter',
          },
        },
        fullWidth: {
          base: 'ml-0 flex w-full rounded-none first:ml-0',
          active: {
            on: 'active rounded-none py-4',
            off: 'rounded-none bg-white text-grey-darker hover:text-black-lighter',
          },
        },
      },
      icon: 'mr-2 h-5 w-5',
    },
  },
  tabitemcontainer: {
    base: '',
    variant: {
      default: '',
      underline: '',
      pills: '',
      fullWidth: '',
    },
  },
  tabpanel: '',
};

export default tabs;
