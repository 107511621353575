import React, { useMemo } from 'react';
import { Host } from '~/generated/models/Host.ts';
import {
  EmailIcon,
  InstagramIcon,
  WebsiteIcon,
  WhatsAppIcon,
} from '../../icons';
import { SocialLink } from '../links';

export const useHostSocials = (host: Host | null, color = 'text-primary') => {
  return useMemo(() => {
    if (!host) return [];

    const classNames = `size-6 ${color} opacity-80`;
    const links: React.ReactNode[] = [];
    const hostId = host.id.getValue();

    const socialConfigs = [
      {
        type: 'email',
        value: host.email && `mailto:${host.email}`,
        icon: <EmailIcon className={classNames} />,
        action: 'emailHost',
      },
      {
        type: 'instagram',
        value: host.socials?.instagram,
        icon: <InstagramIcon className={classNames} />,
        action: 'visitHostInstagram',
      },
      {
        type: 'whatsapp',
        value: host.socials?.whatsapp,
        icon: <WhatsAppIcon className={classNames} />,
        action: 'visitHostWhatsApp',
      },
      {
        type: 'website',
        value: host.socials?.website,
        icon: <WebsiteIcon className={classNames} />,
        action: 'visitHostWebsite',
      },
    ];

    socialConfigs.forEach(({ type, value, icon, action }) => {
      if (value) {
        links.push(
          <SocialLink
            key={type}
            to={value}
            icon={icon}
            track={{
              $$action: action,
              $$host: host.friendlyName,
              $$hostId: hostId,
            }}
          />,
        );
      }
    });

    return links;
  }, [host, color]);
};
