import { ReactElement } from 'react';

export type HeadingProps = {
  title: ReactElement | string;
  children: ReactElement;
};

export function Heading({ title, children }: HeadingProps) {
  return (
    <div className="flex flex-col items-center align-center text-center">
      <b className="lg:text-3xl text-xl">{title}</b>
      <span className="lg:text-base text-sm text-wrap mt-4">{children}</span>
    </div>
  );
}
