const heic2WebP = async (blob: File) => {
  // This library is huge - lazy load it
  const { default: heic2any } = await import('heic2any');

  return heic2any({
    blob,
    toType: 'image/webp',
  });
};

const toWebP = async (blob: File) => {
  const { default: imageCompression } = await import(
    'browser-image-compression'
  );
  const options: imageCompression.Options = {
    maxSizeMB: 1,
    useWebWorker: true,
    fileType: 'image/webp',
  };

  // Compress and convert the image to WebP
  return imageCompression(blob, options);
};

export const image2WebP = async (blob: File, type: string) => {
  if (type === 'image/webp') {
    return blob;
  }

  const converted =
    type === 'image/heic' ? await heic2WebP(blob) : await toWebP(blob);

  if (converted instanceof Blob) {
    return new File([converted], blob.name.replace(/\.heic$/, '.webp'), {
      type: 'image/webp',
    });
  }

  throw new Error(`Expected a Blob but instead got ${typeof converted}`);
};
