import {
  Button,
  Content,
  HelperText,
  Img,
  Page,
  PhoneNumber,
} from '@cg/module-frontend/src/components';
import React from 'react';
import { rsvpCalendarHook } from '~/generated/clients/public/Public.hooks.ts';

export default function RSVPCalendar() {
  const [phone, setPhone] = React.useState('');
  const [isValid, setIsValid] = React.useState(false);
  const { calling, call, error, data } = rsvpCalendarHook(false);

  const submit = async () => {
    call({
      body: { to: phone },
    });
  };

  return (
    <Page className="flex items-center justify-center h-screen -mt-24">
      <Content hideGetStarted className="space-y-6">
        <Img
          className="w-1/2 justify-self-center"
          src="https://assets.joincommonground.com/site/images/rsvp-logo.webp"
          alt="RSVP Vancouver"
        />
        {/* <h3>The RSVP Vancouver Calendar</h3> */}
        {data?.success && (
          <HelperText className="ml-2 mb-2">
            <strong className="mr-1">One last step!</strong>
            You&apos;ll receive a text in a moment with a link to the calendar.
          </HelperText>
        )}
        {!data?.success && (
          <>
            <p className="text-justify">
              Subscribe to the <strong>RSVP Calendar</strong> for expertly
              curated events happening in Vancouver, every single day.
            </p>
            <form className="space-y-6">
              <div>
                <HelperText className="ml-2 mb-2">
                  Add your phone number and we&apos;ll text you a link to
                  subscribe to the RSVP calendar.
                </HelperText>
                <PhoneNumber
                  fullWidth
                  isValid={setIsValid}
                  onChange={setPhone}
                />
                {error?.message && (
                  <HelperText className="ml-2 mb-2">
                    <span className="text-failure ml-3">
                      Oops, something&apos;s broken. Try again later.”main
                    </span>
                  </HelperText>
                )}
              </div>
              <div>
                <Button
                  onClick={submit}
                  color="primary"
                  className="w-full"
                  disabled={!isValid || calling}
                >
                  Subscribe
                </Button>
              </div>
            </form>
          </>
        )}
      </Content>
    </Page>
  );
}
