/* eslint-disable */
import { StrictBuilder } from 'builder-pattern';




/**
 * <p>The dietary restriction of a person</p>
 */
export enum DietaryRestriction {
	None = 'None',
	Vegetarian = 'Vegetarian',
	Vegan = 'Vegan',
	GlutenFree = 'GlutenFree',
	DairyFree = 'DairyFree',
	NutFree = 'NutFree',
	Kosher = 'Kosher',
	Halal = 'Halal',
	Pescatarian = 'Pescatarian',
	Other = 'Other'
}

