import React from 'react';
import { Outlet } from 'react-router-dom';
import {
  Button,
  MobileScreen,
  Modal,
} from '@cg/module-frontend/src/components';
import { useAuth } from '@cg/module-frontend';
import { ExperienceTicketNav } from './ExperienceTicketNav';
import { useExperience } from '~/app/context/experience';

export default function ExperienceLayout() {
  const { login } = useAuth();
  const {
    showLoginModal,
    checkout,
    cancelCheckout,
    identifier,
    showWaitlistModal,
    cancelWaitlist,
  } = useExperience();

  return (
    <>
      <Modal
        show={showWaitlistModal}
        position="center"
        onClose={cancelWaitlist}
        size="xl"
      >
        <Modal.Header>Members Only.</Modal.Header>
        <Modal.Body>
          <p className="flex mb-6">
            To sign up to the Waitlist, you first need an account. Creating one
            takes 30 seconds.
          </p>
          <div className="flex flex-col items-center space-y-4">
            <Button
              className="w-4/5"
              color="primary"
              onClick={() => login({ returnTo: `/e/${identifier}` })}
            >
              Login
            </Button>
            <Button
              className="w-4/5"
              color="secondary"
              onClick={() => login({ returnTo: `/e/${identifier}` })}
            >
              Create Account
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showLoginModal}
        position="center"
        onClose={cancelCheckout}
        size="xl"
      >
        <Modal.Header>Already have an Account?</Modal.Header>
        <Modal.Body>
          <p className="flex mb-6">
            Sign-in to your Common Ground account, or continue as a Guest and
            create an account later.
          </p>
          <div className="flex flex-col items-center space-y-4">
            <Button
              className="w-4/5"
              color="primary"
              onClick={() => login({ returnTo: `/e/${identifier}/checkout` })}
            >
              Login
            </Button>
            <Button className="w-4/5" color="secondary" onClick={checkout}>
              Continue as Guest
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Outlet />
      <MobileScreen>
        <ExperienceTicketNav />
      </MobileScreen>
    </>
  );
}
