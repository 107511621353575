import { Badge, UserPill, Button } from '@cg/module-frontend/src/components';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ArrowRightIcon,
  ClipboardCheckIcon,
  TicketIcon,
} from '@cg/module-frontend/src/icons';
import { sizeToPx } from '@cg/module-frontend/src/constant';
import { ExperienceStartingPrice } from '@cg/module-frontend/src/components/experience';
import { Experience } from '~/generated/models/Experience';
import { useExperience } from '~/app/context/experience';
import { TicketStatus } from '~/generated/models/TicketStatus';

type TicketDetailsProps = {
  experience: Experience;
};

export const TicketDetails = React.memo(
  ({ experience }: TicketDetailsProps) => {
    const [open, setOpen] = useState(false);

    const {
      tiers,
      waitlisted,
      availableTickets,
      isPast,
      joinWaitlist,
      saleEnded,
      purchasedTickets,
      checkout,
    } = useExperience();

    const TicketInPosession = [
      TicketStatus.Confirmed,
      TicketStatus.Reselling,
      TicketStatus.NoShow,
    ];

    const ticketPurchased =
      purchasedTickets.filter((t) => TicketInPosession.includes(t.status))
        .length || 0;

    const available = availableTickets > 0;
    const waitlistAvailable = !available && experience.waitlist === true;
    const soldout = !available && !waitlistAvailable;

    return (
      <div className="relative">
        {/* <SaleEndDate /> */}
        <div className="bg-gradient-to-br bg-primary rounded-2xl p-3 flex justify-between items-center relative z-10">
          {(isPast || saleEnded) && (
            <>
              <div className="flex gap-x-2 items-center text-white w-min whitespace-nowrap font-semibold">
                Sales Ended
              </div>
              <Link to="/">
                <Button color="secondary" className="hover:border-white">
                  <span className="flex items-center">Explore Experiences</span>
                </Button>
              </Link>
            </>
          )}
          {!isPast && !saleEnded && (
            <>
              <div className="flex gap-x-2 items-center text-white">
                <ExperienceStartingPrice tiers={tiers} />
              </div>
              {available && (
                <Button
                  color="secondary"
                  icon={
                    <TicketIcon className="stroke-primary" size={sizeToPx.sm} />
                  }
                  disabled={saleEnded}
                  onClick={checkout}
                >
                  <span className="flex items-center">Grab a Ticket</span>
                </Button>
              )}
              {waitlistAvailable && (
                <Button
                  color="secondary"
                  className="hover:border-white flex items-center justify-center"
                  disabled={waitlisted || saleEnded || false}
                  icon={
                    <ClipboardCheckIcon className="size-6 stroke-primary mr-2" />
                  }
                  onClick={joinWaitlist}
                >
                  Join Waitlist
                </Button>
              )}
              {soldout && (
                <Button
                  color="secondary"
                  className="hover:border-white"
                  disabled
                  icon={
                    <TicketIcon
                      className="stroke-primary hover:stroke-white"
                      size={sizeToPx.sm}
                    />
                  }
                >
                  <span className="flex items-center">Sold Out</span>
                </Button>
              )}
            </>
          )}
        </div>
        {ticketPurchased > 0 && (
          <div className="flex flex-col bg-grey rounded-2xl border border-tertiary/20 w-full relative -top-10 pb-2 pt-12 px-2">
            <button
              type="button"
              className="flex justify-between space-x-2 space-x-reverse hover:underline"
              onClick={() => setOpen(!open)}
            >
              <span className="text-sm font-light">
                You have {ticketPurchased} ticket
                {ticketPurchased === 1 ? '' : 's'}
              </span>
              <div className="flex items-center justify-center space-x-1 text-tertiary text-sm font-medium">
                <span>view your tickets</span>
                <ArrowRightIcon
                  className={`${open && 'rotate-90'} size-4 text-tertiary stroke-tertiary`}
                />
              </div>
            </button>
            {open && (
              <div className="space-y-3 my-4 overflow-y-scroll">
                {purchasedTickets.map((ticket) => (
                  <UserPill
                    user={ticket.user}
                    collapsable={false}
                    key={ticket.id.getValue()}
                  >
                    <Badge className="bg-primary text-white">
                      {
                        tiers?.find(
                          (t) =>
                            ticket.ticketTierId.getValue() === t.id.getValue(),
                        )?.name
                      }
                    </Badge>
                  </UserPill>
                ))}
              </div>
            )}
          </div>
        )}
        {waitlisted && (
          <div className="flex flex-col bg-white rounded-2xl border border-tertiary/20 w-full relative -top-10 pb-2 pt-12 px-2">
            <span className="text-sm font-light">You are on the waitlist!</span>
          </div>
        )}
      </div>
    );
  },
);
