import { Outlet, useLocation } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { UIMatch, useMatches, useNavigate } from 'react-router';
import { RBACConstants } from '@cg/common/src/constants';
import { Toaster } from '../../utils/toast';
import { ErrorBoundary, NotFound } from '../../errors';
import { PostHogAnalytics } from '../../analytics';
import { useAuth, withAuthentication } from '../auth';
import { Loader } from '../../components/loader';
import { Handle } from '../../router';
import { boxConfig } from '../../config';

type Props = {
  employeeOnly?: boolean;
};
type RouteGuardProps = {
  onRequiresAuthChange: (requiresAuth: boolean) => void;
  employeeOnly: boolean;
};

const RouteGuard = React.memo(function RouteGuard({
  onRequiresAuthChange,
  employeeOnly,
}: RouteGuardProps) {
  const { search, pathname } = useLocation();
  const matches = useMatches() as UIMatch<unknown, Handle>[];
  const navigate = useNavigate();
  const { self, fetchingSelf, requiresSignup, login, hasFlag, hasRole } =
    useAuth();

  const featureFlags = useMemo(
    () => matches.filter((match) => match.handle?.featureFlags?.length),
    [matches],
  );

  const requiresAuth = useMemo(
    () =>
      matches.filter((match) => Boolean(match.handle?.requiresAuth)).length > 0,
    [matches],
  );

  const isSignup = ['/signup', '/login'].includes(pathname);

  useEffect(() => {
    if (requiresAuth && requiresSignup && !isSignup) {
      navigate({
        pathname: '/signup',
        search,
      });
    }

    if (requiresAuth && !self && !fetchingSelf) {
      login();
    }

    if (employeeOnly && !hasRole(RBACConstants.Employee)) {
      window.location.href = boxConfig.baseUrls.commonGround;
    }

    if (featureFlags.length !== 0) {
      const flagMissing = featureFlags.some((match) => {
        return match.handle.featureFlags?.some((flag) => {
          return !hasFlag(flag);
        });
      });

      if (flagMissing) {
        throw new NotFound('Page not found');
      }
    }
  }, [self, fetchingSelf, pathname]);

  useEffect(() => {
    onRequiresAuthChange(requiresAuth);
  }, [requiresAuth, onRequiresAuthChange]);

  return null;
});

const AppLayout = React.memo(function AppLayout({
  employeeOnly = false,
}: Props) {
  const [requiresAuth, setRequiresAuth] = useState(false);
  const Component = requiresAuth ? withAuthentication(Outlet) : Outlet;

  return (
    <>
      <PostHogAnalytics />
      <Toaster
        toastOptions={{
          className: 'border lg:min-w-96 min-w-80',
        }}
      />
      <ErrorBoundary>
        <Loader horizontal loading={false}>
          <RouteGuard
            employeeOnly={employeeOnly}
            onRequiresAuthChange={setRequiresAuth}
          />
          <Component />
        </Loader>
      </ErrorBoundary>
    </>
  );
});

export default AppLayout;
