import { useScreenSize } from '@cg/module-frontend/src/hooks';
import {
  ChevronRightIcon,
  ChevronDownIcon,
} from '@cg/module-frontend/src/icons';
import classNames from 'classnames';
import React from 'react';

type ProfileTabProps = {
  icon: React.ReactElement;
  title: string;
  isActive: boolean;
};

function ProfileTab({ icon, title, isActive }: ProfileTabProps) {
  const { isSmall } = useScreenSize();
  return (
    <button
      type="button"
      className={classNames(
        'px-4 py-3 flex justify-between items-center w-full border-b-2',
        {
          'md:border-b-primary': isActive,
          'bg-grey md:border-primary md:border': isActive && isSmall,
          'md:rounded rounded-t px-2': isSmall,
        },
      )}
    >
      <div
        className={classNames('transition-all flex items-center space-x-2', {
          'text-primary': isActive,
        })}
      >
        {icon}
        <span>{title}</span>
      </div>
      {isSmall && (
        <>
          <div
            className={classNames('hidden md:block transition-all opacity-0', {
              'opacity-100': isActive,
            })}
          >
            <ChevronRightIcon className="text-primary size-6" />
          </div>
          <div
            className={classNames(
              'md:hidden transition duration-300 transform',
              {
                'rotate-180': !isActive,
              },
            )}
          >
            <ChevronDownIcon className="text-primary size-6" />
          </div>
        </>
      )}
    </button>
  );
}

export default ProfileTab;
