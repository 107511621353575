import React from 'react';
import { CoverProps } from './Cover';
import { ContentProps } from './Content';

type PageProps = {
  className?: string;
  children:
    | React.ReactElement<CoverProps>
    | Array<React.ReactElement<CoverProps>>
    | React.ReactElement<ContentProps>
    | Array<React.ReactElement<ContentProps>>
    | React.ReactNode
    | null;
};

function Page({ className, children }: PageProps) {
  return <div className={className}>{children}</div>;
}

export default Page;
