/* eslint-disable */

import { http, HttpResult } from '~/core/http';


import { ChangeOwnerRequest } from '../../../models/ChangeOwnerRequest';
import { HostId } from '@cg/common/src/ids/HostId';
import { CreateHostRequest } from '../../../models/CreateHostRequest';
import { Host } from '../../../models/Host';
import { HostDeveloperSetting } from '../../../models/HostDeveloperSetting';
import { PaymentDetail } from '../../../models/PaymentDetail';
import { Page } from '@cg/common/src/paging/Page';
import { UpdateHostDeveloperSettingRequest } from '../../../models/UpdateHostDeveloperSettingRequest';
import { UpdateHostRequest } from '../../../models/UpdateHostRequest';
import { UpdatePaymentDetailRequest } from '../../../models/UpdatePaymentDetailRequest';
import { CreateHostPhotoPreSignedUrlRequest } from '../../../models/CreateHostPhotoPreSignedUrlRequest';
import { PreSignedUrlResponse } from '../../../models/PreSignedUrlResponse';

export type CreateHostArgs = {
    verifiedUser?: boolean,
    body: CreateHostRequest,
}

/**
 * @summary Creates a new Host for the logged in User
 * @param {CreateHostArgs} args
 */
export const createHost = (args: CreateHostArgs): Promise<HttpResult<Host>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type DeleteHostArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
}

/**
 * @summary Endpoint for deleting a Host.
 * @param {DeleteHostArgs} args
 */
export const deleteHost = (args: DeleteHostArgs): Promise<HttpResult<void>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}', params);

    return http.delete(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type GetDeveloperSettingArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
}

/**
 * @summary Returns the developer setting
 * @param {GetDeveloperSettingArgs} args
 */
export const getDeveloperSetting = (args: GetDeveloperSettingArgs): Promise<HttpResult<HostDeveloperSetting>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/developer', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type GetHostArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
}

/**
 * @summary Endpoint for fetching the Host.
 * @param {GetHostArgs} args
 */
export const getHost = (args: GetHostArgs): Promise<HttpResult<Host>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type GetPaymentDetailArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
}

/**
 * @summary Returns the payment details for this Host
 * @param {GetPaymentDetailArgs} args
 */
export const getPaymentDetail = (args: GetPaymentDetailArgs): Promise<HttpResult<PaymentDetail>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/payments', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListHostsArgs = {
    verifiedUser?: boolean,
    filters?: {
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * @summary Returns a list of Hosts that logged in User belongs to
 */
export const listHosts = (args: ListHostsArgs): Promise<HttpResult<Page<Host>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type SwitchHostArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
}

/**
 * @summary Switches the active Host.
 * @param {SwitchHostArgs} args
 */
export const switchHost = (args: SwitchHostArgs): Promise<HttpResult<void>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}', params);

    return http.post(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type UpdateDeveloperSettingArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
    body: UpdateHostDeveloperSettingRequest,
}

/**
 * @summary Creates or updates the payment details for this host
 * @param {UpdateDeveloperSettingArgs} args
 */
export const updateDeveloperSetting = (args: UpdateDeveloperSettingArgs): Promise<HttpResult<HostDeveloperSetting>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/developer', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type UpdateHostSettingArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
    body: UpdateHostRequest,
}

/**
 * @summary Updates the Host settings.
 * @param {UpdateHostSettingArgs} args
 */
export const updateHostSetting = (args: UpdateHostSettingArgs): Promise<HttpResult<Host>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/settings', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type UpdatePaymentDetailArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
    body: UpdatePaymentDetailRequest,
}

/**
 * @summary Creates or updates the payment details for this host
 * @param {UpdatePaymentDetailArgs} args
 */
export const updatePaymentDetail = (args: UpdatePaymentDetailArgs): Promise<HttpResult<PaymentDetail>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/payments', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type UploadPhotoArgs = {
    verifiedUser?: boolean,
    ids: {
        hostId: HostId,
    },
    body: CreateHostPhotoPreSignedUrlRequest,
}

/**
 * <p>API endpoints for creating a Pre Signed URL for uploading files after to S3</p>
 * @summary Generates a Pre Signed URL for uploading a photo for the Host
 * @param {UploadPhotoArgs} args
 */
export const uploadPhoto = (args: UploadPhotoArgs): Promise<HttpResult<PreSignedUrlResponse>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/playground/hosts/{hostId}/photo', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
