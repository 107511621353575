import React from 'react';
import PhoneInput from 'react-phone-input-2';
import * as PhoneUtils from '@cg/common/src/utils/PhoneUtils';
import 'react-phone-input-2/lib/style.css';

type Props = {
  onChange: (number: string) => void;
  isValid?: (value: boolean) => void;
  placeholder?: string;
  fullWidth?: boolean;
};

export default function PhoneNumber({
  onChange,
  fullWidth,
  isValid,
  placeholder,
}: Props) {
  const [number, setNumber] = React.useState('');

  return (
    <PhoneInput
      placeholder={placeholder}
      country="ca"
      containerClass={`${fullWidth ? 'w-full' : ''} rounded`}
      inputClass={`${fullWidth ? 'w-full' : ''} rounded`}
      containerStyle={fullWidth ? { width: '100%' } : {}}
      inputStyle={fullWidth ? { width: '100%' } : {}}
      value={number}
      onChange={setNumber}
      isValid={(value, country) => {
        const valid = PhoneUtils.isValidPhone(
          value,
          // @ts-ignore
          country.iso2.toUpperCase(),
        );

        if (isValid) {
          isValid(valid);
        }

        if (valid) {
          onChange(value);
        } else {
          onChange('');
        }

        return valid;
      }}
    />
  );
}
