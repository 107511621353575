export type Size = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xlg' | '2xlg';

export const sizeToRem: { [size in Size]: number } = {
  xxs: 0.75,
  xs: 1,
  sm: 1.75,
  md: 2.5,
  lg: 5,
  xlg: 8,
  '2xlg': 15,
};

export const sizeToPx: { [size in Size]: number } = {
  xxs: 12,
  xs: 16,
  sm: 28,
  md: 40,
  lg: 80,
  xlg: 128,
  '2xlg': 240,
};

export const remToPx: { [size: number]: number } = {
  0.75: 12,
  1: 16,
  1.75: 28,
  2.5: 40,
  5: 80,
  8: 128,
  15: 240,
};

export const DEFAULT_BANNER_URL =
  'https://assets.joincommonground.com/site/images/experienceBg.svg';
