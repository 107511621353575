import React, { useState } from 'react';
import classNames from 'classnames';
import { useAuth } from '@cg/module-frontend';
import { EditIcon } from '@cg/module-frontend/src/icons';
import { FileUpload, UserAvatar } from '@cg/module-frontend/src/components';
import { useScreenSize } from '@cg/module-frontend/src/hooks';
import { updateSelfHook } from '~/generated/clients/private/self/PrivateSelf.hooks';
import { Self } from '~/generated/models/Self.ts';
import { PreSignedUrlResponse } from '~/generated/models/PreSignedUrlResponse';

type UserInformationProps = {
  self: Self;
};

export function UserInformation({ self }: UserInformationProps) {
  const { call: updateSelf } = updateSelfHook(false);
  const { fetchSelf } = useAuth();
  const { isSmall } = useScreenSize();

  const [isUploading, setIsUploading] = useState(false);
  const onUploaded = async (preSignedUrl: PreSignedUrlResponse) => {
    if (!self) {
      return;
    }

    const body = { ...self.user };
    if (!body.gender) {
      delete body.gender;
    }
    if (!body.dob) {
      delete body.dob;
    }

    await updateSelf({
      // @ts-ignore
      body: {
        ...body,
        imageUrl: preSignedUrl.imageUrl,
      },
    });
    await fetchSelf();
    setIsUploading(false);
  };

  return (
    <div
      className={
        isSmall
          ? 'md:flex md:items-center md:justify-start md:space-x-4 md:mt-2 mt-20'
          : 'flex items-center justify-center space-x-4'
      }
    >
      <div
        className={
          isSmall
            ? 'size-32 rounded-full bg-white mx-auto md:mx-0 -mt-12 md:mt-0 relative shrink-0'
            : 'rounded-full mx-auto relative shrink-0'
        }
      >
        <UserAvatar
          user={self?.user}
          iconSize={isSmall ? 'xlg' : 'lg'}
          imageSize={isSmall ? 'xlg' : 'lg'}
          className={classNames({
            'animate-pulse animate-duration-[1200ms] animate-ease-in-out':
              isUploading,
          })}
        />
        <FileUpload
          circularCrop
          minWidth={150}
          minHeight={150}
          maxSize={3}
          type="User"
          onUploading={() => setIsUploading(true)}
          onUploaded={onUploaded}
          onUploadFailed={() => setIsUploading(false)}
          className="absolute right-0 bottom-0 md:right-0 md:bottom-0 p-1.5 bg-secondary rounded-full border-2 border-white"
        >
          <EditIcon className="text-primary size-3" />
        </FileUpload>
      </div>
      <div className={`${isSmall && 'my-4'} truncate`}>
        <h3 className="font-bold text-center md:text-left md:text-base text-primary">
          {self?.user.firstName} {self?.user.lastName}
        </h3>
        <p className="text-base text-center md:text-left md:text-xs truncate text-black opacity-65">
          {self?.user.email}
        </p>
      </div>
    </div>
  );
}
