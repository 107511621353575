import { Button, Img, Link } from '@cg/module-frontend/src/components';

import React from 'react';
import {
  CalendarIcon,
  DistanceIcon,
  LocationIcon,
  TimerIcon,
} from '@cg/module-frontend/src/icons';
import { DEFAULT_BANNER_URL } from '@cg/module-frontend/src/constant.ts';
import { DetailedExperienceSeries } from '~/generated/models/DetailedExperienceSeries.ts';
import { useDistanceRangeDom, usePaceRangeDom } from '~/hooks/series.hooks';

type Props = {
  series: DetailedExperienceSeries;
  paces: string[];
  distances: number[];
  days: string;
};
const weekDays = [
  { short: 'S', long: 'Sunday' },
  { short: 'M', long: 'Monday' },
  { short: 'T', long: 'Tuesday' },
  { short: 'W', long: 'Wednesday' },
  { short: 'T', long: 'Thursday' },
  { short: 'F', long: 'Friday' },
  { short: 'S', long: 'Saturday' },
];

export default function RunClubSummaryCard({
  series,
  paces,
  distances,
  days,
}: Props) {
  return (
    <div className="space-y-2">
      <div className="bg-white rounded p-0 pb-4 w-full flex flex-col text-sm z-20">
        <Link
          to={`/h/${series.host.uniqueName}`}
          className="relative"
          track={{
            $$action: 'visitHostInstance',
            $$host: series.host.friendlyName,
            $$hostId: series.host.id.getValue(),
          }}
        >
          <div className="rounded relative overflow-hidden w-full aspect-w-5 aspect-h-2">
            <Img
              alt={series.host.friendlyName}
              src={series.host.bannerUrl ?? DEFAULT_BANNER_URL}
              className="w-full h-full object-cover"
            />
          </div>
          <Img
            alt="User Profile"
            src={series.host.imageUrl as string}
            className="absolute w-16 h-16 rounded-full border-4 border-white -bottom-6 left-4 z-20"
            // style={{ clipPath: 'none' }}
          />
        </Link>

        <div className="relative px-2 grow flex flex-col z-0 mt-4">
          <div className="h-36">
            <div className="my-4 line-clamp-2">
              <h4>{series.host.friendlyName}</h4>
            </div>
            <span className="line-clamp-5 mb-2">{series.host.summary}</span>
          </div>
        </div>

        <div className="bg-grey mx-2 p-4 rounded mt-2 grid grid-cols-[2fr_1fr] gap-2">
          <div className="flex items-center">
            <LocationIcon className="w-4 h-4 text-primary mr-2" />
            <span>{series.address.neighbourhood ?? series.address.city}</span>
          </div>
          <div className="flex items-center">
            <TimerIcon className="w-4 h-4 text-primary mr-2" />
            {usePaceRangeDom(paces)}
          </div>
          <div className="flex items-center">
            <CalendarIcon className="w-4 h-4 text-primary mr-2" />
            <div className="flex space-x-1 text-grey-darker">
              {weekDays.map((day, index) => (
                <span
                  key={day.long}
                  className={`text-center ${days[index] === '1' ? 'font-bold text-primary' : ''}`}
                >
                  {day.short}
                </span>
              ))}
            </div>
          </div>
          <div className="flex items-center">
            <DistanceIcon className="w-4 h-4 text-primary mr-2" />
            {useDistanceRangeDom(distances)}
          </div>
        </div>

        <div className="px-2 mt-2">
          <Link
            className="flex"
            to={`/h/${series.host.uniqueName}`}
            track={{
              $$action: 'visitHostInstance',
              $$host: series.host.friendlyName,
              $$hostId: series.host.id.getValue(),
            }}
          >
            <Button color="secondary" size="sm" className="w-full">
              <span className="text-base font-medium flex items-center">
                Explore Club
              </span>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
