import { HeaderConstants } from '@cg/common/src/constants';
import { Id, UserId } from '@cg/common/src/ids';
import { idUtils } from '@cg/common/src/utils/IdUtils';
import { ValidationErrorDetail } from '@cg/common/src/validation';
import { boxConfig } from '../../config';
import { accessToken } from '../../utils/storage';
import cache from './cache';
import { Logger } from '../../utils';

type RequestMethod = 'GET' | 'POST' | 'DELETE';
type RequestHeaders = Record<string, string>;
type RequestBody = unknown;

const logger = Logger('HttpClient');

export type ValidationErrorHttpResult = {
  message: string;
  status: 422;
  name: string;
  errors: ValidationErrorDetail[];
  messages: string[];
};

export type ErrorHttpResult = {
  message: string;
  status: number;
  name: string;
  errors: unknown[];
  messages: string[];
};

type HttpResultSuccess<T> = {
  status: number;
  succeeded: true;
  payload: T;
};

type GenericHttpResultFailed = {
  status: number;
  succeeded: false;
  payload: ErrorHttpResult;
};

type ValidationHttpResultFailed = {
  status: 422;
  succeeded: false;
  payload: ValidationErrorHttpResult;
};

export type HttpResult<T> =
  | HttpResultSuccess<T>
  | GenericHttpResultFailed
  | ValidationHttpResultFailed;

type Request = RequestInit & {
  headers: Headers;
};

type RequestCache = {
  expireKey?: string;
  key?: string;
  ttl?: number;
};

type MakeRequestOption = {
  auth?: {
    required: boolean;
    verifiedUser?: boolean;
  };
  body?: RequestBody;
  headers?: RequestHeaders;
  cache?: RequestCache;
};

type GetRequestOption = {
  auth?: {
    required: boolean;
    verifiedUser?: boolean;
  };
  cache?: RequestCache;
  headers?: RequestHeaders;
};

type PostRequestOption = {
  auth?: {
    required: boolean;
    verifiedUser?: boolean;
  };
  cache?: RequestCache;
  body?: RequestBody;
  headers?: RequestHeaders;
};

type DeleteRequestOption = {
  auth?: {
    required: boolean;
    verifiedUser?: boolean;
  };
  body?: RequestCache;
  headers?: RequestHeaders;
};

const deserialize = <T>(str: string): T => {
  if (str === undefined || str === null || str === '') {
    return {} as T;
  }

  return JSON.parse(str, (_, value) => {
    if (Id.looksLikeId(value)) {
      return idUtils.fromHex(value);
    }

    return value;
  }) as T;
};

const serialize = (obj: unknown): string => {
  return JSON.stringify(obj, (_, value) => {
    if (value instanceof Id) {
      return value.getValue();
    }

    return value;
  });
};

/**
 * Makes an HTTP request
 * @param uri the request uri
 * @param method the request method
 * @param requestOption the {@link MakeRequestOption} to make the request
 * @returns
 */
const make = async <T>(
  uri: string,
  method: RequestMethod,
  requestOption: MakeRequestOption,
): Promise<HttpResult<T>> => {
  if (method === 'GET' && requestOption?.cache?.key) {
    const cached = cache.get(requestOption.cache.key, uri);
    if (cached) {
      return cached as HttpResult<T>;
    }
  }

  const options: Request = {
    method,
    mode: 'cors',
    credentials: 'include',
    headers: requestOption.headers
      ? new Headers(requestOption.headers)
      : new Headers(),
  };
  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  options.headers.set(HeaderConstants.I_WEB_VERSION, window?.__app?.version);
  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  options.headers.set(HeaderConstants.I_WEB_HASH, window?.__app?.hash);

  const token = accessToken.get();
  if (requestOption.auth?.required) {
    if (!token) {
      return {
        status: 401,
        succeeded: false,
        payload: {
          message: 'Unauthorized',
          status: 401,
          name: 'Unauthorized',
          errors: [],
          messages: ['Unauthorized'],
        },
      };
    }

    if (
      typeof requestOption.auth.verifiedUser !== 'undefined' &&
      !requestOption?.auth?.verifiedUser &&
      uri !== '/private/self'
    ) {
      return {
        status: 403,
        succeeded: false,
        payload: {
          message: 'Forbidden',
          status: 403,
          name: 'Forbidden',
          errors: [],
          messages: ['Forbidden - account has not been verified'],
        },
      };
    }
  }

  if (token) {
    options.headers.set(HeaderConstants.AUTHORIZATION, `Bearer ${token}`);
  }

  if (requestOption.body) {
    options.headers.set(HeaderConstants.CONTENT_TYPE, 'application/json');
    options.body = serialize(requestOption.body);
  }

  if (import.meta.env.VITE_API_BYPASS) {
    options.headers.set(
      HeaderConstants.I_AUTH_BYPASS,
      import.meta.env.VITE_API_BYPASS,
    );
  }
  if (
    import.meta.env.VITE_API_AUTHENCIATED_USER &&
    UserId.isValid(import.meta.env.VITE_API_AUTHENCIATED_USER)
  ) {
    options.headers.set(
      HeaderConstants.I_AUTHENTICATED_USER,
      import.meta.env.VITE_API_AUTHENCIATED_USER,
    );
  }

  const url = `${boxConfig.apiHost}/${uri.replace(/^\/+/g, '')}`;
  try {
    const response = await fetch(url, options);

    // No matter what, expire the cache
    if (requestOption.cache?.expireKey) {
      cache.expire(requestOption.cache.expireKey);
    }

    if (response.status >= 200 && response.status < 300) {
      const str = await response.text();
      const result = {
        status: response.status,
        succeeded: true,
        payload: deserialize(str || '{}'),
      };

      // Set the cache
      if (requestOption.cache?.key && method === 'GET') {
        cache.set(
          requestOption.cache.key,
          uri,
          result,
          requestOption.cache.ttl,
        );
      }

      return result as HttpResult<T>;
    }

    const payload = (await response.json()) as ErrorHttpResult;
    if (!payload.messages) {
      payload.messages = [payload.message];
    }

    if (response.status === 422) {
      const validationPayload = payload as ValidationErrorHttpResult;
      validationPayload.messages = validationPayload.errors.map(
        (e) => e.message,
      );
      return {
        status: response.status,
        succeeded: false,
        payload: validationPayload,
      };
    }

    if (response.status === 400) {
      // @ts-ignore
      if (payload.errors?.[0]?.dataPath) {
        const validationPayload = payload as ValidationErrorHttpResult;
        validationPayload.messages = validationPayload.errors.map(
          (e) => e.message,
        );
        return {
          status: response.status,
          succeeded: false,
          payload: validationPayload,
        };
      }
    }

    return {
      status: response.status,
      succeeded: false,
      payload,
    };
  } catch (e) {
    logger.error(`Failed to make ${method} request to ${url}`, e);

    return {
      status: 500,
      succeeded: false,
      payload: e as ErrorHttpResult,
    };
  }
};

type InterpolatePathOptions = {
  keys?: Record<string, unknown>;
  queries?: Record<string, unknown>;
};

export const http = {
  /**
   * Makes a GET call
   * @param uri  the uri to make a request to
   * @param options the {@link GetRequestOption}
   * @returns
   */
  get: <T>(uri: string, options?: GetRequestOption): Promise<HttpResult<T>> =>
    make(uri, 'GET', { ...options }),

  /**
   * Makes a POST call
   * @param uri  the uri to make a call to
   * @param options  the {@link PostRequestOption
   * @returns
   */
  post: <T>(uri: string, options: PostRequestOption): Promise<HttpResult<T>> =>
    make(uri, 'POST', { ...options }),

  /**
   * Makes a DELETE call
   * @param uri  the uri to make a request to
   * @param options the {@link DeleteRequestOption}
   * @returns
   */
  delete: <T>(
    uri: string,
    options?: DeleteRequestOption,
  ): Promise<HttpResult<T>> => make(uri, 'DELETE', { ...options }),

  open: (uri: string) => {
    // eslint-disable-next-line no-restricted-globals
    location.href = `${boxConfig.apiHost}/${uri.replace(/^\/+/g, '')}`;
  },

  /**
   * interpolates the given url with the given keys
   */
  interpolatePath: (str: string, options: InterpolatePathOptions) => {
    const { keys, queries } = options;

    if (keys) {
      Object.keys(keys).forEach((key) => {
        str = str.replace(`{${key}}`, String(keys[key]));
      });
    }

    if (queries) {
      Object.entries(queries).forEach(([query, value], index) => {
        const char = index === 0 ? '?' : '&';
        str += `${char}${query}=${value}`;
      });
    }

    return str;
  },
};
