/* eslint-disable */


import { useHttp } from '@cg/module-frontend/src/core/http';

import { fetchSelf, FetchSelfArgs, listUserConnections, markLifecycleEvent, MarkLifecycleEventArgs, updateSelf, UpdateSelfArgs, uploadPhoto, UploadPhotoArgs } from './PrivateSelf.client';

export const fetchSelfHook = (immediateCall: boolean = true, initArgs?: FetchSelfArgs) => {
  return useHttp(fetchSelf, { immediateCall, initArgs })
};
export const listUserConnectionsHook = (immediateCall: boolean = true, ) => {
  return useHttp(listUserConnections, { immediateCall })
};
export const markLifecycleEventHook = (immediateCall: boolean = true, initArgs?: MarkLifecycleEventArgs) => {
  return useHttp(markLifecycleEvent, { immediateCall, initArgs })
};
export const updateSelfHook = (immediateCall: boolean = true, initArgs?: UpdateSelfArgs) => {
  return useHttp(updateSelf, { immediateCall, initArgs })
};
export const uploadPhotoHook = (immediateCall: boolean = true, initArgs?: UploadPhotoArgs) => {
  return useHttp(uploadPhoto, { immediateCall, initArgs })
};
