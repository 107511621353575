import { useParams } from 'react-router';
import { NotFound } from '@tsed/exceptions';
import {
  ExperienceId,
  HostId,
  TicketId,
  UserId,
  VenueId,
} from '@cg/common/src/ids';

export const getExperienceParam = () => {
  const { experienceId } = useParams();
  if (!experienceId) {
    throw new NotFound('Experience not found');
  }

  return new ExperienceId(experienceId);
};

export const getUserIdParam = () => {
  const { userId } = useParams();
  if (!userId) {
    throw new NotFound('User not found');
  }

  return new UserId(userId);
};

export const getVenueIdParam = () => {
  const { venueId } = useParams();
  if (!venueId) {
    throw new NotFound('Venue not found');
  }

  return new VenueId(venueId);
};

export const getHostIdParam = () => {
  const { hostId } = useParams();
  if (!hostId) {
    throw new NotFound('Host not found');
  }

  return new HostId(hostId);
};

export const getHostUniqueNameParam = () => {
  const { hostUniqueName } = useParams();
  if (!hostUniqueName) {
    throw new NotFound('Host not found');
  }

  return hostUniqueName;
};

export const getTicketIdParam = () => {
  const { ticketId } = useParams();
  if (!ticketId) {
    throw new NotFound('Ticket not found');
  }

  return new TicketId(ticketId);
};
