import React, { useEffect, useState } from 'react';
import { Button, Tab, Tabs } from '@cg/module-frontend/src/components';
import classNames from 'classnames';
import { HeartIcon, SocialIcon, UserIcon } from '@cg/module-frontend/src/icons';
import { toast } from '@cg/module-frontend';
import Faces from './Faces.tsx';
import { UserWithSharedExperience } from '~/generated/models/UserWithSharedExperience';
import { useExperience } from '~/app/context/experience';

type FamiliarFacesProps = {
  attendingUsers: UserWithSharedExperience[];
  interestedUsers: UserWithSharedExperience[];
};

export default function FamiliarFaces({
  attendingUsers,
  interestedUsers,
}: FamiliarFacesProps) {
  const [activeIndex, setActiveIndex] = useState<number | undefined>();
  const { purchasedTickets, interested, togglingInterested, toggleInterested } =
    useExperience();
  const [wasTogglingInterest, setPrevToggling] = useState<boolean | null>(null);
  // Use useEffect to update the previous state whenever toggling changes
  useEffect(() => {
    setPrevToggling(togglingInterested);
  }, [togglingInterested]);

  useEffect(() => {
    if (wasTogglingInterest && interested) {
      setActiveIndex(1);
    }
  }, [togglingInterested, interested]);
  const hasTickets = purchasedTickets.length > 0;

  const attendingTitle = ['Attending'];
  if (attendingUsers.length) {
    attendingTitle.push(`(${attendingUsers.length})`);
  }

  const interestedTitle = ['Interested'];
  if (interestedUsers.length) {
    interestedTitle.push(`(${interestedUsers.length})`);
  }

  return (
    <>
      <div
        className={classNames('flex space-x-2 mb-4', {
          'mt-12': !hasTickets,
        })}
      >
        <Button
          color="secondary"
          className={classNames('w-full group', {
            'cursor-not-allowed': hasTickets,
          })}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (!hasTickets) {
              toggleInterested();
            }
          }}
        >
          <span className="flex items-center space-x-2">
            <HeartIcon
              className={classNames(
                'size-6 text-primary stroke-primary transition-all duration-1000 ease-in-out',
                {
                  'fill-primary animate-delay-0 animate-ping animate-once animate-duration-500 animate-ease-in-out animate-reverse':
                    interested,
                  'fill-none': !interested,
                },
              )}
            />
            <span className="md:hidden group-hover:block text-sm">
              {hasTickets ? "You're Going" : "I'm Interested"}
            </span>
          </span>
        </Button>
        <Button
          color="secondary"
          className="w-full"
          onClick={() => {
            navigator.clipboard.writeText(window.location.href);
            toast.success('Copied to clipboard');
          }}
        >
          <span className="flex items-center space-x-2">
            <SocialIcon className="size-6 text-primary stroke-primary" />
            <span className="md:hidden group-hover:block text-sm">
              Share Link
            </span>
          </span>
        </Button>
      </div>
      <Tabs
        bgColor="grey"
        onTabChanged={() => setActiveIndex(undefined)}
        forcedIndex={activeIndex}
      >
        <Tab title={attendingTitle.join(' ')} key="attending">
          <div className="flex justify-center pt-4">
            <UserIcon className="text-primary stroke-primary fill-primary size-6" />
            <span className="text-primary font-semibold ml-2">
              Your Familiar Faces
            </span>
          </div>
          <Faces users={attendingUsers} />
        </Tab>
        <Tab title={interestedTitle.join(' ')} key="interested">
          <div className="flex justify-center pt-4">
            <UserIcon className="text-primary stroke-primary fill-primary size-6" />
            <span className="text-primary font-semibold ml-2">
              Your Familiar Faces
            </span>
          </div>
          <Faces users={interestedUsers} showInterested={interested} />
        </Tab>
      </Tabs>
    </>
  );
}
