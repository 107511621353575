/* eslint-disable */
import { StrictBuilder } from 'builder-pattern';




/**
 * <p>The gender of a person</p>
 */
export enum Gender {
	Man = 'Man',
	Woman = 'Woman',
	NonBinary = 'NonBinary',
	TransgenderMan = 'TransgenderMan',
	TransgenderWoman = 'TransgenderWoman',
	GenderFluid = 'GenderFluid',
	TwoSpirit = 'TwoSpirit',
	Agender = 'Agender',
	Other = 'Other',
	OptOut = 'OptOut'
}

