import { FlowbitePopoverTheme } from 'flowbite-react';

const popover: FlowbitePopoverTheme = {
  base: 'absolute z-20 inline-block w-max max-w-[100vw] bg-white outline-none border border-black-lighter rounded shadow-sm ',
  content: 'z-10 overflow-hidden rounded-lg',
  arrow: {
    base: 'absolute h-2 w-2 z-0 rotate-45 mix-blend-lighten bg-white border border-black-lighter',
    placement: '-4px',
  },
};

export default popover;
