import classNames from 'classnames';
import Img from '../img';

export default function Polaroid({
  img,
  caption,
  className,
}: {
  img: string;
  caption?: string;
  className?: string;
}) {
  return (
    <div
      className={classNames(
        'bg-white z-1 gap-y-4 shadow-xl drop-shadow-xl w-[50vw] lg:w-[15vw] max-w-[260px] -translate-y-1/2 -translate-x-1/2',
        className,
      )}
    >
      <div className="flex flex-col pt-4 pb-2 gap-y-2 z-[20] px-4 items-center justify-center">
        <Img
          src={img}
          alt={caption}
          className="object-center object-cover aspect-square max-h-[200px] w-full"
        />
        <p className="text-xs leading-3 md:leading-5 md:text-md  text-left w-full">
          {caption}
        </p>
      </div>
    </div>
  );
}
