import { createBrowserRouter } from 'react-router-dom';
import React, { lazy } from 'react';

import { CGRouteObject } from '@cg/module-frontend/src/router';
import { featureFlagConstants } from '@cg/common/src/constants';
import { Suspense } from '@cg/module-frontend/src/components';
import { PageNotFound } from '@cg/module-frontend/src/errors';
import { Navigate } from 'react-router';
import { AppLayout } from '@cg/module-frontend/src/core';
import RSVPCalendar from '~/pages/rsvp';
import {
  MainLayout,
  HostLayout,
  ProfileLayout,
  ExperienceLayout,
} from '~/app/layouts';

// Critical paths should be eager loaded
import HomePage from '~/pages/home';
import {
  CheckoutSucceededPage,
  CheckoutFailedPage,
  WaitlistedPage,
} from '~/pages/experience/confirmation';

// Experience Pages
import ExperiencePage from '~/pages/experience';
import CheckoutPage from '~/pages/experience/checkout';

// Hosts pages
import HostPage from '~/pages/hosts/host';
import HostExperiencesPage from '~/pages/hosts/host/experiences';

// Generic Pages
const BecomeAHostPage = lazy(() => import('~/pages/become-a-host'));
const VibeCheckPage = lazy(() => import('~/pages/vibe-check'));
const AboutUsPage = lazy(() => import('~/pages/about'));
const ContactUsPage = lazy(() => import('~/pages/contact-us'));
const PrivacyPolicyPage = lazy(() => import('~/pages/legal/PrivacyPolicy'));
const ToSPage = lazy(() => import('~/pages/legal/ToS'));
const CookiePolicyPage = lazy(() => import('~/pages/legal/CookiePolicy'));

// Signup/Login
const SignupPage = lazy(() => import('~/pages/signup'));
const LoginPage = lazy(() => import('~/pages/login'));

// Profile pages
const ProfileDetailsPage = lazy(() => import('~/pages/profile/details'));
const ProfileExperiencesPage = lazy(
  () => import('~/pages/profile/experiences'),
);
const ProfileExperiencesReceiptPage = lazy(
  () => import('~/pages/profile/experiences/receipt'),
);

export const routes: CGRouteObject[] = [
  {
    path: '/',
    id: 'root',
    element: <AppLayout />,
    children: [
      {
        path: '/',
        element: <MainLayout />,
        children: [
          {
            path: '/',
            element: <HomePage />,
            handle: {
              requiresAuth: false,
            },
          },
          {
            path: 'rsvp-calendar',
            element: <RSVPCalendar />,
            handle: {
              requiresAuth: false,
            },
          },
          {
            path: 'become-a-host',
            element: (
              <Suspense>
                <BecomeAHostPage />
              </Suspense>
            ),
            handle: {
              requiresAuth: false,
            },
          },
          {
            path: '/login',
            element: (
              <Suspense>
                <LoginPage />
              </Suspense>
            ),
            handle: {
              requiresAuth: true,
            },
          },
          {
            path: '/e',
            element: <ExperienceLayout />,
            handle: {
              requiresAuth: false,
            },
            children: [
              {
                index: true,
                handle: {
                  requiresAuth: false,
                },
              },
              {
                path: '/e/:identifier',
                children: [
                  {
                    index: true,
                    element: <ExperiencePage />,
                    handle: {
                      requiresAuth: false,
                    },
                  },
                  {
                    path: '/e/:identifier/checkout',
                    element: <CheckoutPage />,
                    handle: {
                      requiresAuth: false,
                    },
                  },
                  {
                    path: '/e/:identifier/success',
                    element: <CheckoutSucceededPage />,
                    handle: {
                      requiresAuth: false,
                    },
                  },
                  {
                    path: '/e/:identifier/failed',
                    element: <CheckoutFailedPage />,
                    handle: {
                      requiresAuth: false,
                    },
                  },
                  {
                    path: '/e/:identifier/waitlisted',
                    element: <WaitlistedPage />,
                    handle: {
                      requiresAuth: true,
                    },
                  },
                ],
              },
            ],
          },
          {
            path: '/profile',
            handle: {
              requiresAuth: true,
            },
            element: <ProfileLayout />,
            children: [
              {
                path: '/profile/experiences',
                element: (
                  <Suspense>
                    <ProfileExperiencesPage />
                  </Suspense>
                ),
              },
              {
                path: '/profile/details',
                element: (
                  <Suspense>
                    <ProfileDetailsPage />
                  </Suspense>
                ),
              },
              {
                path: '/profile/experiences/:identifier',
                element: (
                  <Suspense>
                    <ProfileExperiencesReceiptPage />
                  </Suspense>
                ),
                handle: {
                  featureFlags: [featureFlagConstants.WAITLIST_ACCESS],
                },
              },
            ],
          },
          {
            path: '/h',
            handle: {
              requiresAuth: false,
            },
            children: [
              {
                index: true,
                element: <Navigate to="/" />,
              },
              {
                path: '/h/:hostUniqueName',
                element: <HostLayout />,
                children: [
                  {
                    index: true,
                    element: <HostPage />,
                  },
                  {
                    path: '/h/:hostUniqueName/experiences',
                    element: <HostExperiencesPage />,
                  },
                ],
              },
            ],
          },
          {
            path: 'vibe-check',
            element: (
              <Suspense>
                <VibeCheckPage />
              </Suspense>
            ),
            handle: {
              requiresAuth: false,
            },
          },
          {
            path: 'about-us',
            element: (
              <Suspense>
                <AboutUsPage />
              </Suspense>
            ),
            handle: {
              requiresAuth: false,
            },
          },
          {
            path: 'privacy-policy',
            element: (
              <Suspense>
                <PrivacyPolicyPage />
              </Suspense>
            ),
            handle: {
              requiresAuth: false,
            },
          },
          {
            path: 'cookie-policy',
            element: (
              <Suspense>
                <CookiePolicyPage />
              </Suspense>
            ),
            handle: {
              requiresAuth: false,
            },
          },
          {
            path: 'ToS',
            element: (
              <Suspense>
                <ToSPage />
              </Suspense>
            ),
            handle: {
              requiresAuth: false,
            },
          },
          {
            path: 'contact-us',
            element: (
              <Suspense>
                <ContactUsPage />
              </Suspense>
            ),
          },
          {
            path: 'signup',
            element: (
              <Suspense>
                <SignupPage />,
              </Suspense>
            ),
            handle: {
              requiresAuth: true,
            },
          },
          {
            path: '*',
            element: <PageNotFound />,
          },
        ],
      },
    ],
  },
];

const router = createBrowserRouter(routes);

export default router;
