import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import './index.css';

const theme = {
  primary: '#931D72',
  secondary: '#F1D7EA',
  tertiary: '#D8607A',
  white: '#FFFFFF',
  black: '#342D32',
  warning: '#E48E0E',
  success: '#1D933E',
  failure: '#AE2121',
};

const Container = styled.div`
  a {
    color: ${(props) => props.theme.primary};
    text-decoration: underline;
  }
  white-space: pre-line;
  br {
    display: block;
    margin: 0;
    padding: 0;
    line-height: 0;
    height: 0;
  }
  p {
    margin: 0;
    padding: 0;
  }
`;

export type Props = {
  children: React.ReactNode;
};

export default function MarkdownContainer({ children }: Props) {
  return (
    <ThemeProvider theme={theme}>
      <Container className="w-full whitespace-pre-line markdown-container relative prose">
        {children}
      </Container>
    </ThemeProvider>
  );
}
